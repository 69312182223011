import Saasupport from 'saasupport'
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useWorkspace } from '../../../context/WorkspaceContext';
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
const SAASUPPORT_API = process.env.REACT_APP_SAASUPPORT_API;
const SAASUPPORT_CLIENT_ID = process.env.REACT_APP_SAASUPPORT_CLIENT_ID;

const Support = () => {
    const [userInfo, setUserInfo] = useState(null)
    const hasRunOnce = useRef(false);
    const { idToken } = useWorkspace();
    useEffect(() => {
        if (!hasRunOnce.current && idToken) {
            getUser();
            hasRunOnce.current = true;
        }
    }, [idToken]);

    const getUser = async () => {
        try {
            const response = await axios.get(BACKEND_API_URL + '/api/auth/user/getUser', {
                headers: { Authorization: `Bearer ${idToken}` }
            });

            setUserInfo(response.data);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div className='w-full h-full bg-background'>
            {userInfo && (
                <Saasupport
                    userId={userInfo._id}
                    apiConfig={{
                        apiKey: SAASUPPORT_API,
                        clientId: SAASUPPORT_CLIENT_ID,
                    }}
                    showSuggestFeature={true}
                    showSuggestions={true}
                    showGuide={{
                        show: true,
                        title: 'Help Center',
                        description: 'Check out our help center for',
                        link: 'https://www.socialflick.io',
                        imageUrl: `${process.env.PUBLIC_URL}/random/help-center.png`
                    }}
                    styles={{
                        textColor: '#000000',
                        fontFamily: 'Roboto, sans-serif',
                    }}
                />
            )}
        </div>
    )
}

export default Support