import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './VerticalNavbar.css';
import { FaMoon, FaPlus, FaSun } from 'react-icons/fa';
import { BiSolidDashboard, BiHistory, BiSolidHelpCircle } from 'react-icons/bi';
import { IoShareSocialSharp } from 'react-icons/io5';
import {
  MdArrowBackIosNew,
  MdArrowForwardIos,
  MdOutlineDriveFolderUpload,
  MdOutlineCalendarMonth,
} from 'react-icons/md';
import { TbTemplate } from 'react-icons/tb';
import { motion } from 'framer-motion';
import useLocalStorage from 'use-local-storage';
import { useWorkspace } from '../../../context/WorkspaceContext';
import { LuRefreshCcw } from 'react-icons/lu';
import { FaTrash } from 'react-icons/fa';
import DropdownButton from '../../Tools/DropdownButton';

const VerticalNavbar = () => {
  const [isExpanded, setIsExpanded] = useLocalStorage('navbarIsExpanded', true);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const [theme, setTheme] = useLocalStorage('theme', 'light');

  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(null);
  const hasRunOnceRef = useRef(false);
  const { firestoreUser, signOut, auth } = useWorkspace();

  useEffect(() => {
    if (!hasRunOnceRef.current && location.pathname !== '') {
      setCurrentPath(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    localStorage.setItem('navbarIsExpanded', JSON.stringify(isExpanded));
  }, [isExpanded]);

  const toggleNavbar = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    const storedIsExpanded = localStorage.getItem('navbarIsExpanded');
    if (storedIsExpanded === null) {
      const handleResize = () => {
        const screenWidth = window.innerWidth;
        setIsExpanded(screenWidth > 768);
      };

      handleResize();
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const isActive = (currentPath, path) => {
    return currentPath === path ? 'active' : '';
  };

  // Close the menu if the user clicks outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const sidebarVariants = {
    expanded: {
      width: 230,
      transition: {
        type: 'spring',
        stiffness: 200,
        damping: 20,
      },
    },
    collapsed: {
      width: 80,
      transition: {
        type: 'spring',
        stiffness: 200,
        damping: 20,
      },
    },
  };

  const handleUserMenuToggle = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => navigate("/login"))
      .catch((error) => console.log(error));
  };

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <motion.div
      className={`vertical-menu shadow-lg shadow-blue-800/70 ${isExpanded ? 'min-w-[230px] w-[230px] p-[20px]' : 'w-[80px]'
        } bg-gray-200 max-h-screen`}
      variants={sidebarVariants}
      animate={isExpanded ? 'expanded' : 'collapsed'}
      initial={false}
    >
      {/* LOGO */}
      <motion.div layout className={`relative py-5 ${isExpanded ? '' : 'pt-[100px]'}`}>
        <Link to="/dashboard" className="flex">
          {isExpanded ? (
            <img src={`${process.env.PUBLIC_URL}/logo/logo-horizontal.png`} alt="Logo" />
          ) : (
            <img src={`${process.env.PUBLIC_URL}/logo/favicon-nobg.png`} alt="Logo" />
          )}
        </Link>
      </motion.div>

      {/* TOGGLE BUTTON */}
      <motion.div
        layout
        className={`px-2 py-1 text-white absolute right-0 top-10`}
        onClick={toggleNavbar}
      >
        {isExpanded ? (
          <MdArrowBackIosNew
            size={30}
            color="white"
            className="opacity-50 hover:border-gray-300 border-2 border-transparent rounded-xl p-1 cursor-pointer"
          />
        ) : (
          <MdArrowForwardIos
            size={30}
            color="white"
            className="opacity-50 hover:border-gray-300 border-2 border-transparent rounded-xl p-1 cursor-pointer"
          />
        )}
      </motion.div>

      {/* UPLOAD BUTTON */}
      <motion.div layout className={`${isExpanded ? 'pb-10' : 'mx-auto'}`}>
        <div
          className={`${isExpanded ? 'py-3 px-1' : 'p-3'
            } text-sm text-white bg-gradient-to-r hover:from-blue-600 hover:to-blue-800 mx-auto bg-blue-600 font-bold rounded-full cursor-pointer flex items-center w-[80%] justify-center transition-all duration-300`}
        >
          <Link to="/dashboard/upload" className="flex">
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Upload
              </motion.span>
            )}
            <FaPlus size={20} color="white" className={`${isExpanded ? 'ml-3' : ''}`} />
          </Link>
        </div>
      </motion.div>

      {/* OVERVIEW MENU */}
      <div className="flex flex-col justify-center">
        <p className={`text-sm font-semibold mb-1 ${isExpanded ? '' : 'invisible w-0'}`}>
          OVERVIEW
        </p>
        <div
          className={`flex items-center ${isExpanded ? '' : 'justify-center'
            } group relative hover:bg-gray-700 p-1 rounded-md ${isActive(
              currentPath,
              '/dashboard'
            )}`}
        >
          <Link
            to="/dashboard"
            className={`nav-link flex items-center gap-2  ${!isExpanded ? 'group' : ''}`}
          >
            <BiSolidDashboard
              size={`${isExpanded ? '24' : '35'}`}
              color="white"
              className="navbar-icon"
            />
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Dashboard
              </motion.span>
            )}
          </Link>
        </div>
      </div>

      {/* CONTENT MENU */}
      <div className={`flex flex-col justify-center ${isExpanded ? 'mt-10' : 'mt-1'}`}>
        <p className={`text-sm font-semibold mb-1 ${isExpanded ? '' : 'hidden w-0'}`}>
          CONTENT
        </p>

        <div
          className={`flex items-center ${isExpanded ? '' : 'justify-center'
            } group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md mb-2 ${isActive(
              currentPath,
              '/dashboard/upload'
            )}`}
        >
          <Link
            to="/dashboard/upload"
            className={`nav-link flex items-center gap-2 ${!isExpanded ? 'group' : ''}`}
          >
            <MdOutlineDriveFolderUpload
              size={`${isExpanded ? '24' : '35'}`}
              color="white"
              className="navbar-icon"
            />
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Upload
              </motion.span>
            )}
          </Link>
        </div>

        <div
          className={`flex items-center ${isExpanded ? '' : 'justify-center'
            } group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md mb-2 ${isActive(
              currentPath,
              '/dashboard/schedule'
            )}`}
        >
          <Link
            to="/dashboard/schedule"
            className={`nav-link flex items-center gap-2 ${!isExpanded ? 'group' : ''}`}
          >
            <MdOutlineCalendarMonth
              size={`${isExpanded ? '24' : '35'}`}
              color="white"
              className="navbar-icon"
            />
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Calendar
              </motion.span>
            )}
          </Link>
        </div>

        <div
          className={`flex items-center ${isExpanded ? '' : 'justify-center'
            } group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md mb-2 ${isActive(
              currentPath,
              '/dashboard/templates'
            )}`}
        >
          <Link
            to="/dashboard/templates"
            className={`nav-link flex items-center gap-2 ${!isExpanded ? 'group' : ''}`}
          >
            <TbTemplate
              size={`${isExpanded ? '24' : '35'}`}
              color="white"
              className="navbar-icon"
            />
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Templates
              </motion.span>
            )}
          </Link>
        </div>

        <div
          className={`flex items-center ${isExpanded ? '' : 'justify-center'
            } group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md mb-2 ${isActive(
              currentPath,
              '/dashboard/posts'
            )}`}
        >
          <Link
            to="/dashboard/posts"
            className={`nav-link flex items-center gap-2 ${!isExpanded ? 'group' : ''}`}
          >
            <BiHistory
              size={`${isExpanded ? '24' : '35'}`}
              color="white"
              className="navbar-icon"
            />
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Posts
              </motion.span>
            )}
          </Link>
        </div>
      </div>

      {/* CONFIG MENU */}
      <div className="flex flex-col justify-end mb-[50px] flex-1">
        <p className={`text-sm font-semibold mb-1 ${isExpanded ? '' : 'invisible w-0'}`}>
          CONFIG
        </p>

        <div
          className={`flex items-center ${isExpanded ? '' : 'justify-center'
            } group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md mb-2 ${isActive(
              currentPath,
              '/dashboard/socials'
            )}`}
        >
          <Link
            to="/dashboard/socials"
            className={`nav-link flex items-center gap-2 ${!isExpanded ? 'group' : ''}`}
          >
            <IoShareSocialSharp
              size={`${isExpanded ? '24' : '35'}`}
              color="white"
              className="navbar-icon"
            />
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Socials
              </motion.span>
            )}
          </Link>
        </div>


        <div
          className={`flex items-center ${isExpanded ? '' : 'justify-center'
            } group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md mb-2`}
        >
          <Link
            to="/dashboard/support"
            className={`nav-link flex items-center gap-2 ${!isExpanded ? 'group' : ''}`}
          >
            <BiSolidHelpCircle
              size={`${isExpanded ? '24' : '35'}`}
              color="white"
              className="navbar-icon"
            />
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Support
              </motion.span>
            )}
          </Link>
        </div>
      </div>

      {/* USER PROFILE WITH MENU */}
      <div ref={menuRef} className="border-t border-gray-500 mt-4 relative flex justify-center p-2">
        <DropdownButton
          left={100}
          label={
            <div
              className="w-full flex justify-between items-center bg-transparent border-none cursor-pointer hover:bg-gray-700 p-2 rounded-md"
              onClick={handleUserMenuToggle}
            >
              <div className="flex items-center gap-2 w-full justify-between">
                {/* User avatar */}
                <div
                  className={`bg-green-100 text-green-600 flex items-center justify-center rounded-full ${!isExpanded ? 'h-10 w-10' : 'h-8 w-8 '} ${isActive(currentPath, '/dashboard/settings')
                    ? 'bg-blue-100 text-blue-600'
                    : ''
                    }`}
                >
                  {firestoreUser.username.charAt(0)}
                </div>
                {/* Username and Email */}
                {isExpanded && (
                  <div className="text-left">
                    <div>{firestoreUser.username}</div>
                    <div className="text-xs text-gray-500 whitespace-nowrap overflow-hidden text-ellipsis max-w-[120px]">
                      {firestoreUser.email}
                    </div>
                  </div>
                )}
              </div>
              {isExpanded && (
                <MdArrowForwardIos className="h-4 w-4 text-gray-400 group-hover:text-gray-200" />
              )}
            </div>
          }
          options={[
            {
              heading: <div className="flex justify-between items-center gap-2">
                <div className="text-sm font-semibold bg-green-100 text-green-600 flex items-center justify-center rounded-md px-3 py-2">
                  {firestoreUser.username.charAt(0)}
                </div>
                <div className="text-xs text-gray-500 flex flex-col">
                  <div className="text-sm font-semibold">
                    {firestoreUser.username}
                  </div>
                  <div className="text-xs text-gray-500">
                    {firestoreUser.email}
                  </div>
                </div>
              </div>
            },
            {
              label: theme === 'light' ? 'Light mode' : 'Dark mode',
              onClick: () => toggleTheme(),
              icon: theme === 'light' ? <FaSun className="text-blue-600 font-bold" /> : <FaMoon className="text-blue-600 font-bold" />,
            },
            {
              label: 'Settings',
              onClick: () => navigate('/dashboard/settings'),
              icon: <LuRefreshCcw className="text-blue-600 font-bold" />,
            },
            {
              label: 'Logout',
              onClick: () => handleSignOut(),
              color: 'red',
              icon: <FaTrash />,
            },
          ]}
        />
      </div>
    </motion.div >
  );
};

export default VerticalNavbar;
