import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { useWorkspace } from '../../../context/WorkspaceContext';

//buttons
import { Modal } from '../../../components/Modal/Modal';
import Breadcrumbs from "../../../components/Tools/Breadcrumbs";
import DropdownButton from "../../../components/Tools/DropdownButton";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { LuRefreshCcw } from "react-icons/lu";
import LoadingIndicator from "../../../components/Tools/LoadingIndicator";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Socials = ({ value }) => {
  const [userSocials, setUserSocials] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const hasRunOnceRef = useRef(false);
  const { activeWorkspace, idToken } = useWorkspace();

  const closeModal = () => {
    setOpenModal(false);
  };
  const openModalHandler = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    if (!hasRunOnceRef.current && idToken) {
      hasRunOnceRef.current = true;
      getUsersSocials();
    }
  }, [idToken]);

  useEffect(() => {
    if (activeWorkspace && idToken) {
      getUsersSocials();
    }
  }, [activeWorkspace, idToken]);

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel('socialsChannel');

    broadcastChannel.onmessage = event => {
      console.log(event);
      if (event.data === 'callbackLogin') {
        getUsersSocials();
      }
    };
  }, []);

  async function getUsersSocials() {
    if (!activeWorkspace || !idToken) return;
    setLoading(true);
    try {
      const response = await axios.get(BACKEND_API_URL + '/api/auth/user/social_media_tokens', {
        params: { workspaceId: activeWorkspace.id },
        headers: { Authorization: `Bearer ${idToken}` },
      });
      setUserSocials(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={`${value ?? 'dashboard-container'}`}>
      {!value && (
        <div className="mb-3">
          <Breadcrumbs />
          <p className="text-sm italic">Connect to your social medias to start uploading videos...</p>
        </div>
      )}

      {loading && !value ? (
        <div className="flex justify-center items-center min-h-[calc(100vh-100px)]">
          <LoadingIndicator showLogo={true} />
        </div>
      ) : (
        <ShowLoggedInSocials userSocials={userSocials} openModalHandler={openModalHandler} setUserSocials={setUserSocials} />
      )}

      {openModal && (
        <div className='flex-col items-center border-t-2 pt-3 absolute'>
          <Modal open={openModal} closeModal={closeModal} type={'ConnectSocialsModal'} otherInfo={'hashtag'} />
        </div>
      )}
    </div>
  );
}

const ShowLoggedInSocials = ({ userSocials, openModalHandler, setUserSocials }) => {
  const { activeWorkspace, idToken } = useWorkspace();

  const onDelete = async (id) => {
    if (!idToken) return;
    try {
      const response = await axios.delete(BACKEND_API_URL + '/api/auth/user/social/logout/' + id, {
        params: { workspaceId: activeWorkspace.id },
        headers: { Authorization: `Bearer ${idToken}` },
      });
      const newUserSocials = userSocials.filter((social) => social.platform_id !== id);
      setUserSocials(newUserSocials);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  const handleImageError = (event, platform) => {
    event.target.src = `../socials/${platform}-logo.svg`;
  };

  const handleReauthorize = async (platform) => {
    if (!idToken) return;
    if (platform === 'youtube') platform = 'google';

    try {
      const response = await axios.post(`${BACKEND_API_URL}/api/${platform}/oauth`, {}, {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      window.open(response.data, '_blank');
    } catch (error) {
      console.error('Reauthorization error:', error);
    }
  };

  return (
    <div className="flex flex-wrap w-full">
      <div className='md:hidden w-full sm:w-1/4 md:w-1/4 lg:w-1/6 xl:1/6 min-w-[100px] min-h-[200px] rounded-lg p-5 m-4 text-center relative --theme-colors border-dashed border-4 border-gray-300 flex items-center justify-center transition-all duration-300 hover:border-solid cursor-pointer' onClick={openModalHandler}>
        Connect social
        <br />
        +
      </div>
      {userSocials.map((social, index) => (
        <div key={index} className='w-full sm:w-1/4 md:w-1/4 lg:w-1/6 xl:1/6 min-w-[100px] rounded-lg p-3 m-3 text-center relative --theme-colors --social-cards-shadow'>
          <div className="flex w-full justify-between items-center">
            <img src={`../socials/${social.platform}-logo.svg`} alt={social.platform} className="w-8"></img>

            <DropdownButton label={<BsThreeDotsVertical size={20} />} options={[
              { label: 'Refresh account', onClick: () => handleReauthorize(social.platform), icon: <LuRefreshCcw className="text-blue-600 font-bold" /> },
              { label: 'Disconnect account', onClick: () => onDelete(social.platform_id), color: 'red', icon: <FaTrash /> },
              { extra: 'Expires: ' + new Date(social.expiresAt).toLocaleString().replace(/:\d{2}$/, '') }
            ]} />
          </div>

          <div className={`avatar-container max-w-[100px] w-[80%] mx-auto ${social.platform === 'tiktok' && 'rounded-full p-4 bg-black'}`}>
            <img className="avatar-image w-full h-auto rounded-full mx-auto mb-2" src={social.logo} alt={social.username} onError={(e) => handleImageError(e, social.platform)} />
          </div>
          <p className="text-lg font-semibold">{social.username}</p>
        </div>
      ))}
      <div className='hidden md:flex w-full sm:w-1/4 md:w-1/4 lg:w-1/6 xl:1/6 min-w-[100px] min-h-[200px] rounded-lg p-5 m-4 text-center relative --theme-colors border-dashed border-4 border-gray-300 flex items-center justify-center transition-all duration-300 hover:border-solid cursor-pointer' onClick={openModalHandler}>
        Connect social
        <br />
        +
      </div>
    </div>
  );
};

export default Socials;