import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { RiPencilLine, RiDeleteBinLine, RiErrorWarningLine, RiCalendar2Fill, RiHistoryFill } from 'react-icons/ri';
import Pagination from "../Tools/Pagination";
import { Modal } from "../Modal/Modal";
import { BsInstagram, BsYoutube, BsTiktok, BsFacebook, BsThreeDotsVertical, BsX, BsTwitterX } from 'react-icons/bs';
import { isMobile as checkIsMobile, handleResize, debounce } from '../../utils/utils';
import { getFileType } from "../../utils/getFileType";
import Tabs from "../Navigation/MenuTabs/Tabs";
import DropdownButton from "../Tools/DropdownButton";
import DisplayError from '../../components/Tools/DisplayError';
import { motion } from 'framer-motion';
import { useWorkspace } from '../../context/WorkspaceContext';
import { formatCustomDate, formatCustomDateTime } from "../../utils/date";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const DraftsBig = ({ onEditDraft, itemsPerPage, showFilter = false }) => {
    const [drafts, setDrafts] = useState([]);
    const [activeMainTab, setActiveMainTab] = useState('draft');
    const [activeFilterTab, setActiveFilterTab] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [tabs, setTabs] = useState([
        { id: 'draft', label: 'Drafts', count: { length: 0, color: 'bg-blue-500' } },
        { id: 'scheduled', label: 'Scheduled', count: { length: 0, color: 'bg-yellow-500' } },
        { id: 'uploaded', label: 'History', count: { length: 0, color: 'bg-green-500' } },
    ]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [modalGroup, setModalGroup] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const hasRunOnceRef = useRef(false);
    const [isMobile, setIsMobile] = useState(checkIsMobile());
    const { activeWorkspace, idToken } = useWorkspace();
    const filterTabs = [
        { id: 'all', label: 'All', icon: '' },
        { id: 'youtube', label: '', icon: <BsYoutube size={35} color='red' /> },
        { id: 'instagram', label: '', icon: <BsInstagram size={27} color='#cd486b' /> },
        { id: 'tiktok', label: '', icon: <BsTiktok size={27} color='black' /> },
        { id: 'facebook', label: '', icon: <BsFacebook size={27} color='blue' /> }
    ];

    useEffect(() => {
        const debouncedHandleResize = debounce(() => handleResize(setIsMobile), 100);
        window.addEventListener("resize", debouncedHandleResize);
        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, [isMobile]);

    useEffect(() => {
        const getDrafts = async () => {
            if (!activeWorkspace || !idToken) return;
            try {
                const response = await axios.get(BACKEND_API_URL + '/api/auth/user/drafts', {
                    params: { workspaceId: activeWorkspace.id },
                    headers: { Authorization: `Bearer ${idToken}` },
                });
                const arr = response.data;
                setDrafts(arr);
                await updateTabCount(arr);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        if (!hasRunOnceRef.current && isDeleteModalOpen === false) {
            hasRunOnceRef.current = true;
            getDrafts();
        }

        if (isDeleteModalOpen === true) {
            hasRunOnceRef.current = false;
        }
    }, [isDeleteModalOpen, activeWorkspace, idToken]);

    const updateTabCount = async (mergedData) => {
        if (mergedData.length <= 0) return;

        const tabCounts = {
            draft: 0,
            scheduled: 0,
            failed: 0,
            uploaded: 0,
        };
        mergedData.forEach(group => {
            if (group.status === 'draft') {
                tabCounts.draft++;
            } else if (group.status === 'failed') {
                tabCounts.failed++;
            }
            else if (group.status === 'scheduled') {
                tabCounts.scheduled++;
            }
            else if (group.status === 'uploaded') {
                tabCounts.uploaded++;
            }
        });

        const hasFailedDrafts = tabCounts.failed > 0;

        setTabs(prevTabs => {
            let updatedTabs = [...prevTabs];

            if (hasFailedDrafts && !updatedTabs.find(tab => tab.id === 'failed')) {
                updatedTabs.unshift({ id: 'failed', label: 'Failed', count: { length: 0, color: 'bg-red-500' } });
                setActiveMainTab('failed');
            }

            return updatedTabs.map(tab => {
                if (tab.id in tabCounts) {
                    return { ...tab, count: { ...tab.count, length: tabCounts[tab.id] } };
                }
                return tab;
            });
        });

        if (!hasFailedDrafts && activeMainTab === 'failed') {
            setActiveMainTab('draft');
        }
    };

    const handleMainTabChange = (newTab) => {
        setActiveMainTab(newTab);
        setCurrentPage(1);
    };

    const handleFilterTabChange = (newTab) => {
        setActiveFilterTab(newTab);
        setCurrentPage(1);
    };

    const paginate = () => {
        if (!drafts || drafts.length <= 0) return { currentItems: [], maxPage: 1 };
        const perPage = itemsPerPage;
        const indexOfLastItem = currentPage * perPage;
        const indexOfFirstItem = indexOfLastItem - perPage;

        let sortedDrafts = drafts
            .map(group => {
                const latestPost = group.posts.reduce((latest, post) => {
                    const latestUpdatedAt = new Date(latest.updatedAt._seconds * 1000);
                    const postUpdatedAt = new Date(post.updatedAt._seconds * 1000);
                    return postUpdatedAt > latestUpdatedAt ? post : latest;
                }, group.posts[0]);
                return { ...group, latestUpdatedAt: new Date(latestPost.updatedAt._seconds * 1000) };
            })
            .sort((a, b) => b.latestUpdatedAt - a.latestUpdatedAt);

        let filteredDrafts = sortedDrafts.map(group => ({
            ...group,
            posts: group.posts.filter(post =>
                post.status === activeMainTab &&
                (activeFilterTab === 'all' || post.uploadTo === activeFilterTab)
            )
        })).filter(group => group.posts.length > 0);

        const maxPage = Math.ceil(filteredDrafts.length / perPage);

        const currentItems = filteredDrafts.slice(indexOfFirstItem, indexOfLastItem);

        return { currentItems, maxPage };
    };

    const [currentItems, setCurrentItems] = useState([]);
    const [maxPage, setMaxPage] = useState(1);
    useEffect(() => {
        if (drafts && drafts.length > 0) {
            const { currentItems, maxPage } = paginate();
            setCurrentItems(currentItems);
            setMaxPage(maxPage);
        } else {
            setCurrentItems([]);
            setMaxPage(1);
        }
    }, [drafts, activeMainTab, activeFilterTab, currentPage]);

    const handleDeleteButtonClick = (group) => {
        setModalGroup(group);
        setIsDeleteModalOpen(true);
    };

    const closeModal = () => {
        setModalGroup(null);
        setIsDeleteModalOpen(false);
    };

    const getIconForTab = (tabId) => {
        switch (tabId) {
            case 'failed':
                return <RiErrorWarningLine className="w-4 h-4" />;
            case 'draft':
                return <RiPencilLine className="w-4 h-4" />;
            case 'scheduled':
                return <RiCalendar2Fill className="w-4 h-4" />;
            case 'uploaded':
                return <RiHistoryFill className="w-4 h-4" />;
            default:
                return null;
        }
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= maxPage) {
            setCurrentPage(newPage);
        }
    };

    return (
        <div className="flex flex-col relative h-[350px]">
            {/* Top Pagination */}
            {maxPage > 1 && (
                <div className="w-max right-0 top-[-35px] absolute">
                    <Pagination currentPage={currentPage} maxPage={maxPage} onPageChange={handlePageChange} />
                </div>
            )}

            {/* Main Tabs */}
            <div className="grid w-full grid-cols-4 mb-4">
                {
                    tabs.map((tab) => (
                        <button
                            key={tab.id}
                            onClick={() => handleMainTabChange(tab.id)}
                            className={`flex items-center justify-center gap-2 py-2 px-4 text-sm font-medium rounded-md 
                ${activeMainTab === tab.id ? 'bg-primary text-white' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
                        >
                            {getIconForTab(tab.id)}
                            <span>{tab.label}</span>
                            {tab.count && (
                                <span
                                    className={`px-2 rounded-full text-xs ${tab.count.color} text-white`}
                                >
                                    {tab.count.length}
                                </span>
                            )}
                        </button>
                    ))
                }
            </div >

            <div className="flex relative items-start gap-2">
                {/* Filter Tabs */}
                {showFilter && activeMainTab === 'uploaded' && (
                    <div className='dashboard-section !m-0 !w-max !px-0 !py-3'>
                        <Tabs tabs={filterTabs} onTabChange={handleFilterTabChange} activeTab={activeFilterTab} main={false} useResponsiveDropdown={false} />
                    </div>
                )}
                {/* Display Draft Cards */}
                {activeMainTab && (
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full">
                        {isLoading ? (
                            // Display loaders
                            Array(4).fill(0).map((_, index) => <Loader key={index} />)
                        ) : currentItems && currentItems.length > 0 ? (
                            currentItems.map((group) => (
                                <DraftCard
                                    key={group.post_id}
                                    group={group}
                                    onEditDraft={onEditDraft}
                                    handleDeleteButtonClick={handleDeleteButtonClick}
                                />
                            ))
                        ) : (
                            <>
                                <span></span>
                                <div className="w-[500px]">
                                    <DisplayError />
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>

            {/* Bottom Pagination */}
            {maxPage > 1 && (
                <div className="self-end">
                    <Pagination currentPage={currentPage} maxPage={maxPage} onPageChange={handlePageChange} />
                </div>
            )}

            {/* Delete Modal */}
            {isDeleteModalOpen && (
                <Modal open={isDeleteModalOpen} closeModal={closeModal} type={'VerifyDelete'} otherInfo={modalGroup} />
            )}
        </div>
    );
};

const DraftCard = ({ group, onEditDraft, handleDeleteButtonClick }) => {
    const handleEditButtonClick = () => {
        onEditDraft(group);
    };

    const getStatusBadgeColor = (status) => {
        switch (status) {
            case 'draft':
                return 'bg-blue-100 text-blue-800';
            case 'scheduled':
                return 'bg-yellow-100 text-yellow-800';
            case 'failed':
                return 'bg-red-100 text-red-800';
            case 'uploaded':
                return 'bg-green-100 text-green-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };

    const post = group.posts[0];

    return (
        <motion.div
            className="bg-white shadow rounded-lg"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2 }}
        >
            {/* Header */}
            <div className="p-2 border-b">
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                        {group.posts.map((groupPost, index) => (
                            <IconForSocialMedia key={index} socialMedia={groupPost.uploadTo} />
                        ))}
                    </div>
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeColor(post.status)}`}>
                        {post.status.toUpperCase()}
                    </span>
                    <div className="flex space-x-2 ">
                        <DropdownButton label={<BsThreeDotsVertical size={18} color="black" />} classes="!p-0 hover:bg-none relative" options={[
                            { label: `Edit`, onClick: () => handleEditButtonClick(), icon: <RiPencilLine size={20} className="ml-5 text-blue-600 font-bold" /> },
                            ...(post.status !== 'uploaded'
                                ? [
                                    {
                                        label: `Delete`,
                                        onClick: () => handleDeleteButtonClick(group),
                                        icon: <RiDeleteBinLine size={20} className="ml-5 text-blue-600 font-bold" />,
                                    },
                                ]
                                : []),
                        ]} />

                    </div>
                </div>
            </div>

            {/* Media */}
            <div className="relative aspect-video bg-gray-200">
                <img
                    src={
                        getFileType(post.uploadType) === 'video'
                            ? post.thumbnail || `${process.env.PUBLIC_URL}/no-thumbnail.png`
                            : post.firebase_path || `${process.env.PUBLIC_URL}/no-thumbnail.png`
                    }
                    alt={post.title}
                    className="w-full h-full object-cover"
                />
            </div>

            {/* Content */}
            <div className="p-2">
                <h3 className="text-sm font-medium truncate text-gray-800">{post.title}</h3>
            </div>
            <div className="p-2">
                <h3 className="text-sm font-medium truncate text-gray-700">{post.description}</h3>
            </div>

            {/* Footer */}
            <div className="p-2 pt-0">
                <p className="text-xs text-gray-500">
                    {post.status === 'scheduled'
                        ? formatCustomDateTime(new Date(post.schedule))
                        : formatCustomDate(post.updatedAt._seconds * 1000)}
                </p>
            </div>
        </motion.div>
    );
};

const Loader = () => (
    <motion.div
        className="shadow-md rounded-lg w-full py-2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2, repeat: Infinity, repeatType: "reverse" }}
    >
        <div className="animate-pulse space-y-2">
            <div className="h-6 flex gap-5 justify-between items-center w-full p-2">
                <span className="h-4 bg-gray-200 rounded w-[50px]"></span>
                <span className="h-4 bg-gray-200 rounded w-[100px]"></span>
                <span className="h-4 bg-gray-200 rounded w-[50px]"></span>
            </div>
            <div className="aspect-video bg-gray-200 rounded"></div>
            <div className="h-6 bg-gray-200 rounded w-2/3 px-2"></div>
            <div className="h-5 bg-gray-200 rounded w-1/2 px-2 pb-4"></div>
        </div>
    </motion.div>
);

const IconForSocialMedia = ({ socialMedia }) => {
    let social = socialMedia;
    if (typeof socialMedia === 'object' && socialMedia.socialMedia) {
        social = socialMedia.socialMedia;
    }
    switch (social) {
        case 'youtube':
            return <BsYoutube className="h-5 w-5 text-red-600" />;
        case 'instagram':
            return <BsInstagram className="h-5 w-5 text-pink-500" />;
        case 'tiktok':
            return <BsTiktok className="h-5 w-5 text-black" />;
        case 'facebook':
            return <BsFacebook className="h-5 w-5 text-blue-600" />;
        case 'x':
            return <BsTwitterX className="h-5 w-5 text-black" />;
        default:
            return null;
    }
};

export default DraftsBig;
