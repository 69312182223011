// App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import useLocalStorage from "use-local-storage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WorkspaceProvider } from './context/WorkspaceContext';
import './firebase';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './firebase';
import axios from "axios";

// pages
import LoginPage from "./pages/Auth/Login";
import SignupPage from "./pages/Auth/Signup";

// components
import LoadingIndicator from "./components/Tools/LoadingIndicator";

// utils
import WorkspaceRoutes from "./WorkspaceRoutes";

const App = () => {
  const [theme] = useLocalStorage("theme", 'light');
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (!firebaseUser) {
        setUserLoggedIn(false);
        setIsLoading(false);
      } else {
        try {
          const token = await firebaseUser.getIdToken();
          await axios.get(
            `${process.env.REACT_APP_BACKEND_API_URL}/api/auth/user/getUser`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setUserLoggedIn(true);
        } catch (err) {
          setUserLoggedIn(false);
        } finally {
          setIsLoading(false);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return <LoadingIndicator showLogo={true} className="h-screen w-screen" />;
  }

  return (
    <div className="App min-h-screen" data-theme={`${theme}`}>
      <BrowserRouter>
        <ToastContainer
          position="bottom-right"
          autoClose={7000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme={theme}
        />

        <div className={`pages`}>
          {userLoggedIn ? (
            <WorkspaceProvider>
              <WorkspaceRoutes />
            </WorkspaceProvider>
          ) : (
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignupPage />} />
              {/* If not logged in, redirect everything else to /login */}
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          )}
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
