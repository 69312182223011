import React, { useEffect, useState, useRef, useCallback } from 'react';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import ProgressWheel from '../../Tools/ProgressWheel';

const ThumbnailSelector = ({ videoName, onThumbnailSelect, draftThumbnail, isVisible }) => {
  const [thumbnails, setThumbnails] = useState(draftThumbnail ? [{ id: 0, imageUrl: draftThumbnail, selected: true }] : [
    { id: 1, imageUrl: '../no-thumbnail.png', selected: true },
    { id: 2, imageUrl: '../no-thumbnail.png', selected: false },
    { id: 3, imageUrl: '../no-thumbnail.png', selected: false },
  ]);

  const hasRunOnceRef = useRef(false);
  const [uploadedThumbnail, setUploadedThumbnail] = useState(null);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [videoUploadFinished, setVideoUploadFinished] = useState(false);
  const videoNameRef = useRef(videoName); // Store videoName in a ref to keep its value across renders



  const getDownloadURLFromFirebase = useCallback(async () => {
    let currentVideoName = videoNameRef.current; // Use ref value
    // Get videoName from firebase video path since its a draft
    if (currentVideoName.includes("firebase")) {
      // Decode the path and extract the filename
      const url = new URL(currentVideoName);
      const decodedPath = decodeURIComponent(url.pathname);
      const filename = decodedPath.split('/').pop();
      currentVideoName = filename;
    }
    try {
      const fileName = currentVideoName.replace(/^videos\//, '').replace(/\.mp4$/, '');
      const folder = '/thumbnails/';

      const storageRef = getStorage();

      const updatedThumbnails = [];

      for (const thumbnail of thumbnails) {
        const i = thumbnail.id;
        const finalPath = folder + `thumb_${fileName}_${i}.jpg`;
        const fileRef = ref(storageRef, finalPath);

        let url;
        let attempts = 0;
        const maxAttempts = 5;
        while (!url && attempts < maxAttempts) {
          try {
            url = await getDownloadURL(fileRef);
          } catch (error) {
            // Ignore errors and retry
          }

          if (!url) {
            attempts += 1;
            await wait(2000); // Wait for 2 seconds before the next attempt
          }
        }

        // If there's a draftThumbnail, add it as the first thumbnail (once)
        if (draftThumbnail && !updatedThumbnails.some(thumb => thumb.id === 1)) {
          updatedThumbnails.push({ ...thumbnail, id: 0, imageUrl: draftThumbnail, selected: false });

          // Set selected to false for all other thumbnails
          updatedThumbnails.slice(1).forEach(thumb => {
            thumb.id = thumb.id + 1;
            thumb.selected = false;
          });
        }

        if (url) {
          if (draftThumbnail) updatedThumbnails.push({ ...thumbnail, imageUrl: url, selected: false });
          else updatedThumbnails.push({ ...thumbnail, imageUrl: url });

        } else {
          console.warn(`Thumbnail ${i} not found after ${maxAttempts} attempts.`);
        }
      }

      if (updatedThumbnails.length > 0) {
        onThumbnailSelect(updatedThumbnails[0]);
        updatedThumbnails[0].selected = true;
        setThumbnails(updatedThumbnails);
      } else {
        onThumbnailSelect('');
      }

      setIsLoading(false);
    } catch (error) {
      console.error('Error getting download URLs:', error);
    }
  }, [draftThumbnail, onThumbnailSelect, thumbnails]);

  useEffect(() => {
    if (!hasRunOnceRef.current && !draftThumbnail && videoName && videoName !== '') {
      videoNameRef.current = videoName; // Update the ref value whenever videoName changes
      setVideoUploadFinished(true);
      hasRunOnceRef.current = true;
      getDownloadURLFromFirebase();
    } else if (draftThumbnail) {
      setIsLoading(false);
      hasRunOnceRef.current = true;
      onThumbnailSelect({ id: 0, imageUrl: draftThumbnail });
    }
  }, [videoName, draftThumbnail, getDownloadURLFromFirebase, onThumbnailSelect]);

  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleThumbnailSelection = (thumbnailId) => {
    const selectedThumbnail = thumbnails.find((thumbnail) => thumbnail.id === thumbnailId);

    if (selectedThumbnail) {
      setThumbnails((prevThumbnails) =>
        prevThumbnails.map((thumbnail) => ({
          ...thumbnail,
          selected: thumbnail.id === thumbnailId,
        }))
      );
      onThumbnailSelect(selectedThumbnail); // Invoke the callback with the selected thumbnail
    }
  };

  const handleThumbnailUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Handle the uploaded thumbnail file as needed
      // Update state to reflect the new uploadedThumbnail
      setUploadedThumbnail(URL.createObjectURL(file));
      const newThumbnail = { id: 1, imageUrl: file, renderImg: URL.createObjectURL(file), uploadedFile: file, selected: true };

      // Add the uploaded thumbnail at the beginning without removing the original 1
      setThumbnails((prevThumbnails) => [
        newThumbnail,
        ...prevThumbnails.map((thumbnail, index) => ({
          ...thumbnail,
          id: index + 2, // Increase the id for others
          selected: false,
        })),
      ]);
      onThumbnailSelect(newThumbnail);
    }
  };


  if (!isVisible) {
    return null;
  }

  return (
    <div className="thumbnail-selector-container p-4 bg-white rounded-lg">
      <div className='flex justify-between items-center mb-4'>
        <div>
          <p className="text-lg font-semibold text-gray-800">Choose Thumbnail</p>
        </div>
        <button
          className="text-sm font-semibold cursor-pointer px-4 py-2 border-2 border-dashed border-blue-500 rounded-md text-white hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-300"
          onClick={() => fileInputRef.current.click()}
        >
          Upload Thumbnail
        </button>

        {/* Upload Thumbnail Button */}
        <input
          type="file"
          accept="image/*"
          onChange={handleThumbnailUpload}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
      </div>

      {isLoading && !uploadedThumbnail ? (
        <div className='flex flex-col justify-center items-center py-5 min-h-[150px] text-center'>
          <ProgressWheel />
          <div className="mt-4 text-sm text-gray-600">
            {!videoUploadFinished ? "Wait for video upload to complete to fetch thumbnails..." : "Fetching thumbnails, please wait..."}
          </div>
        </div>
      ) : (
        <div className="thumbnail-section flex items-center space-x-4 overflow-x-auto p-2 rounded-lg">
          {thumbnails.map((thumbnail) => (
            <div
              key={thumbnail.id}
              className={`thumbnail-container shadow-md relative cursor-pointer flex-shrink-0 max-w-[150px] max-h-[150px] rounded-md overflow-hidden transition-transform transform hover:scale-105 ${thumbnail.selected ? 'border-4 border-blue-500' : 'border p-1 border-gray-300'}`}
              onClick={() => handleThumbnailSelection(thumbnail.id)}
            >
              {thumbnail.renderImg ? (
                <img src={thumbnail.renderImg} alt={`Thumbnail ${thumbnail.id}`} className="w-full h-full object-cover" />
              ) : (
                <img src={thumbnail.imageUrl} alt={`Thumbnail ${thumbnail.id}`} className="w-full h-full object-cover" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ThumbnailSelector;
