import React, { useState } from 'react';
import AuthForm from '../../components/Auth/AuthForm';
import { Link } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';
import ResetPasswordUnauth from '../../components/Auth/ResetPasswordUnauth';

const LoginPage = ({ checkLoginStatus }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [recoverEmail, setRecoverEmail] = useState(null);
    const [theme] = useLocalStorage("theme", "light");

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = (email) => {
        setIsModalOpen(true);
        setRecoverEmail(email);
    }

    const logoSrc = theme === 'light' ? './logo/logo-vertical-black.png' : './logo/logo-vertical.png';

    return (
        <div className={`flex flex-col min-h-screen --theme-colors ${theme === 'light' ? 'background-gradient' : 'dark-background-gradient'}`}>
            <div className="w-full px-5 py-3">
                <Link to="https://socialflick.io">
                    <img src={logoSrc} className="w-[200px]" alt="logo" />
                </Link>
            </div>

            <div className="flex flex-1 items-center justify-center">
                <AuthForm isLogin={true} checkLoginStatus={checkLoginStatus} sendOpenModal={openModal} />
            </div>

            {
                isModalOpen && (
                    <ResetPasswordUnauth
                        email={recoverEmail}
                        closeModal={closeModal}
                    />
                )
            }
        </div >
    );
}

export default LoginPage;
