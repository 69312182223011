// Schedule.js

import React, { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import CalendarHeader from '../../../components/Calendar/CalendarHeader';
import Month from '../../../components/Calendar/Month';
import LoadingIndicator from '../../../components/Tools/LoadingIndicator';
import Week from '../../../components/Calendar/Week';
import { useWorkspace } from '../../../context/WorkspaceContext';
import DraftsBig from '../../../components/Drafts/DraftsBig';
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Schedule = () => {
    const { activeWorkspace, idToken } = useWorkspace();
    const initialSettings = JSON.parse(localStorage.getItem('calendarSettings')) || {
        viewMode: 'month',
        showHistory: true,
        showScheduled: true,
        showTodo: true
    };
    const [settings, setSettings] = useState(initialSettings);
    const [showLoading, setShowLoading] = useState(true);
    const [uploads, setUploads] = useState([]);
    const [todos, setTodos] = useState([]);

    // Month View States
    const [currentMonth, setCurrentMonth] = useState(getMonth());
    const [monthIndex, setMonthIndex] = useState(new Date().getMonth());

    // Week View States
    const [currentWeek, setCurrentWeek] = useState(getWeek());
    const [weekStartDate, setWeekStartDate] = useState(dayjs().startOf('week'));

    const getUploads = useCallback(async () => {
        if (!idToken) return;
        try {
            const response = await axios.get(`${BACKEND_API_URL}/api/auth/user/drafts`, {
                params: { workspaceId: activeWorkspace.id },
                headers: { Authorization: `Bearer ${idToken}` },
            });
            const allUploaded = response.data.filter(
                (item) => item.status === 'uploaded' || item.status === 'scheduled'
            );
            setUploads(allUploaded);
            setShowLoading(false);
        } catch (error) {
            console.log(error);
        }
    }, [activeWorkspace, idToken]);

    const getTodos = useCallback(async () => {
        if (!idToken) return;
        try {
            const response = await axios.get(`${BACKEND_API_URL}/api/auth/user/todo/all`, {
                params: { workspaceId: activeWorkspace.id },
                headers: { Authorization: `Bearer ${idToken}` },
            });
            if (response.status === 200 && response.data && response.data.userTodo) {
                setTodos(response.data.userTodo.list);
            }
        } catch (error) {
            console.log(error);
        }
    }, [activeWorkspace, idToken]);

    useEffect(() => {
        getUploads();
        getTodos();
    }, [activeWorkspace, getUploads, getTodos]);

    useEffect(() => {
        if (settings.viewMode === 'month') {
            setCurrentMonth(getMonth(monthIndex));
        }
    }, [monthIndex, settings.viewMode]);

    useEffect(() => {
        if (settings.viewMode === 'week') {
            setCurrentWeek(getWeek(weekStartDate));
        }
    }, [weekStartDate, settings.viewMode]);

    useEffect(() => {
        localStorage.setItem('calendarSettings', JSON.stringify(settings));
    }, [settings]);

    const refreshUserUploads = () => {
        getUploads();
    };

    const handlePrevMonth = () => {
        setMonthIndex((prev) => prev - 1);
    };

    const handleNextMonth = () => {
        setMonthIndex((prev) => prev + 1);
    };

    const handlePrevWeek = () => {
        setWeekStartDate((prev) => prev.subtract(1, 'week'));
    };

    const handleNextWeek = () => {
        setWeekStartDate((prev) => prev.add(1, 'week'));
    };

    const handleReset = () => {
        if (settings.viewMode === 'month') {
            setMonthIndex(dayjs().month());
        } else {
            setWeekStartDate(dayjs().startOf('week'));
        }
    };

    const toggleShowHistory = () => setSettings(prev => ({ ...prev, showHistory: !prev.showHistory }));
    const toggleShowScheduled = () => setSettings(prev => ({ ...prev, showScheduled: !prev.showScheduled }));
    const toggleShowTodo = () => setSettings(prev => ({ ...prev, showTodo: !prev.showTodo }));
    const toggleViewMode = (viewMode) => setSettings(prev => ({ ...prev, viewMode: viewMode }));

    return (
        <>
            {!showLoading ? (
                <div className="flex flex-col h-full w-full overflow-x-hidden p-2">
                    <CalendarHeader
                        viewMode={settings.viewMode}
                        setViewMode={toggleViewMode}
                        monthIndex={monthIndex}
                        handlePrevMonth={handlePrevMonth}
                        handleNextMonth={handleNextMonth}
                        handleReset={handleReset}
                        weekStartDate={weekStartDate}
                        handlePrevWeek={handlePrevWeek}
                        handleNextWeek={handleNextWeek}
                        showHistory={settings.showHistory}
                        showScheduled={settings.showScheduled}
                        setShowHistory={toggleShowHistory}
                        setShowScheduled={toggleShowScheduled}
                        showTodo={settings.showTodo}
                        setShowTodo={toggleShowTodo}
                    />

                    <div className="flex flex-1 px-2">
                        {settings.viewMode === 'month' ? (
                            <Month
                                month={currentMonth}
                                userUploads={uploads}
                                todos={todos}
                                showHistory={settings.showHistory}
                                showScheduled={settings.showScheduled}
                                showTodo={settings.showTodo}
                                refreshUserUploads={refreshUserUploads}
                            />
                        ) : settings.viewMode === 'week' ? (
                            <Week
                                week={currentWeek}
                                userUploads={uploads}
                                todos={todos}
                                showHistory={settings.showHistory}
                                showScheduled={settings.showScheduled}
                                showTodo={settings.showTodo}
                                refreshUserUploads={refreshUserUploads}
                            />
                        ) : (
                            <div className='py-3'>
                                <DraftsBig itemsPerPage={6} />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <LoadingIndicator />
            )}
        </>
    );
};

export default Schedule;

// Helper functions
const getMonth = (month = dayjs().month()) => {
    month = Math.floor(month);
    const year = dayjs().year();
    const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
    let currentMonthCount = 0 - firstDayOfTheMonth;
    const daysMatrix = new Array(5).fill([]).map(() => {
        return new Array(7).fill(null).map(() => {
            currentMonthCount++;
            return dayjs(new Date(year, month, currentMonthCount));
        });
    });
    return daysMatrix;
};

function getWeek(weekStart = dayjs().startOf('week')) {
    return Array.from({ length: 7 }, (_, i) => weekStart.add(i, 'day'));
}