import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs'
import { MdCloudUpload } from 'react-icons/md';
import { BsInstagram, BsYoutube, BsTiktok, BsFacebook, BsThreeDotsVertical, BsTwitterX, BsClock } from 'react-icons/bs';
import { RiDeleteBinLine, RiPencilLine } from 'react-icons/ri';
import Modal from '../Modal/Modal';
import DropdownButton from '../Tools/DropdownButton';
import { formatCustomDate, formatCustomDateTime } from '../../utils/date';
import { FaCopy } from 'react-icons/fa';
import axios from 'axios';
import { useWorkspace } from '../../context/WorkspaceContext';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Month = ({ month, userUploads, showHistory, showScheduled, refreshUserUploads, todos, showTodo }) => {
    return (
        <div className="flex-1 grid grid-cols-4 md:grid-cols-7">
            {month.map((row, i) => (
                <React.Fragment key={i}>
                    {row.map((day, idx) => (
                        <Day day={day} key={idx} rowIdx={i} todos={todos} userUploads={userUploads} showHistory={showHistory} showScheduled={showScheduled} refreshUserUploads={refreshUserUploads} showTodo={showTodo} />
                    ))}
                </React.Fragment>
            ))}
        </div>
    );
}

export default Month;


const Day = ({ day, rowIdx, userUploads, todos, showHistory, showScheduled, refreshUserUploads, showTodo }) => {
    const { idToken, activeWorkspace } = useWorkspace();
    const [showModal, setShowModal] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [matchingUploads, setMatchingUploads] = useState([]);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [clickedData, setClickedData] = useState({
        date: null,
        draft: null
    });
    const [deletedPost, setDeletedPost] = useState(null);
    const [matchingTodos, setMatchingTodos] = useState([]);
    const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);

    const getCurrentDayClass = () => {
        return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
            ? "text-white font-semibold rounded-full px-2 py-1 bg-blue-500"
            : "";
    };

    const isPastDay = () => {
        return day.isBefore(dayjs(), 'day');
    };

    const closeModal = () => {
        setShowModal(false);

        if (isDeleteModalOpen === true) {
            setDeleteModalOpen(false);
            refreshUserUploads();
        }

        if (showModal === true) {
            setShowModal(false);
            refreshUserUploads();
        }
    };

    useEffect(() => {
        if (userUploads && userUploads.length > 0) {
            const matchingUploadsArray = [];
            userUploads.forEach((upload) => {
                const post = upload.posts[0];
                const uploadDate = post.schedule ? new Date(post.schedule) : new Date(post.updatedAt._seconds * 1000);
                const dayDate = new Date(day.$d);
                if (
                    uploadDate.getFullYear() === dayDate.getFullYear() &&
                    uploadDate.getMonth() === dayDate.getMonth() &&
                    uploadDate.getDate() === dayDate.getDate()
                ) {
                    matchingUploadsArray.push(upload);
                }
            });
            setMatchingUploads(matchingUploadsArray);
        }
    }, [userUploads, day]);

    useEffect(() => {
        if (todos) {
            const matchingTodosArray = todos.filter(todo => {
                if (todo.date) {
                    const todoDate = new Date(todo.date);
                    return todoDate.getDate() === day.date();
                }
                return false;
            });
            setMatchingTodos(matchingTodosArray);
        }
    }, [todos, day]);

    const IconForSocialMedia = ({ socialMedia }) => {
        switch (socialMedia) {
            case 'youtube':
                return <BsYoutube color="red" size={13} />;
            case 'instagram':
                return <BsInstagram color="#C13584" size={13} />;
            case 'tiktok':
                return <BsTiktok color="black" size={13} />;
            case 'facebook':
                return <BsFacebook color="blue" size={13} />;
            case 'x':
                return <BsTwitterX color="black" size={13} />;
            default:
                return null;
        }
    };

    const handleUploadButtonClick = () => {
        setShowModal(true);
        setClickedData({ date: day.$d });
    };

    const handleEditButtonClick = (draft) => {
        setClickedData({ draft });
        setShowModal(true);
    };

    const handleDeleteButtonClick = (upload) => {
        setDeleteModalOpen(true);
        setDeletedPost(upload);
    };

    const handleDuplicateButtonClick = (upload) => {
        const duplicateData = { ...upload };
        delete duplicateData.post_id;
        duplicateData.posts = duplicateData.posts.map(post => {
            const newPost = { ...post };
            delete newPost.post_id;
            delete newPost._id;
            return newPost;
        });
        setClickedData({ draft: duplicateData });
        setShowModal(true);
    };

    const handleTodoToggle = async (id, completed) => {
        if (!idToken) return;
        setIsCheckboxDisabled(true);
        const updatedTodos = [...todos];
        const index = updatedTodos.findIndex(item => item.id === id);
        if (index !== -1) {
            updatedTodos[index].completed = !completed;
            try {
                await axios.post(
                    BACKEND_API_URL + '/api/auth/user/todo/update',
                    { todoId: updatedTodos[index].id, completed: updatedTodos[index].completed, workspaceId: activeWorkspace.id },
                    { headers: { Authorization: `Bearer ${idToken}` } }
                );
                const updatedMatchingUploads = matchingUploads.map(upload => {
                    if (upload.id === id) {
                        return { ...upload, checked: updatedTodos[index].completed };
                    }
                    return upload;
                });
                setMatchingUploads(updatedMatchingUploads);
            } catch (error) {
                console.log(error);
            }
            setIsCheckboxDisabled(false);
        } else {
            console.error(`Todo with id ${id} not found.`);
        }
    };

    return (
        <div className={`relative border border-gray-200 flex flex-col h-full --theme-colors ${isPastDay() && !showModal && !isDeleteModalOpen ? 'opacity-30 border-gray-300 shadow-inner' : 'opacity-100  shadow-sm lg:hover:border-gray-300'}`} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <header className="flex flex-col items-center my-1 py-3">
                {rowIdx === 0 && (
                    <p className="text-sm mt-1">
                        {day.format("ddd").toUpperCase()}
                    </p>
                )}
                <p className={`text-sm text-center ${getCurrentDayClass()}`}>
                    {day.format("DD")}
                </p>
            </header>

            <div className={`flex-1 flex flex-col overflow-y-auto max-h-[195px] overflow-x-hidden pb-5`}>
                {matchingUploads && matchingUploads.map((upload, index) => (
                    <div key={`${index}`} className={`flex border border-gray-200 flex-col w-[95%] min-h-[80px] max-h-[80px] hover:border-gray-500 mx-auto rounded-lg mb-2 cursor-pointer --theme-colors ${upload.status === 'scheduled' && !showScheduled && 'hidden'} ${upload.status === 'uploaded' && !showHistory && 'hidden'}`}>
                        <div className={`flex justify-between items-center p-1 rounded-t-md ${upload.status === 'scheduled' ? 'bg-yellow-300' : 'bg-green-300'}`}>
                            <div className='flex items-center'>
                                <BsClock color='black' size={16} className='mr-2' />
                                {upload.posts[0].title && (
                                    <p className="text-xs font-bold truncate ">{upload.posts[0].title}</p>
                                )}
                            </div>
                            <DropdownButton label={<BsThreeDotsVertical size={18} color='black' />} classes="!p-0 hover:bg-none" options={[
                                { label: `Edit`, onClick: () => handleEditButtonClick(upload), icon: <RiPencilLine size={20} className="ml-5 text-gray-600 font-bold" /> },
                                { label: `Duplicate`, onClick: () => handleDuplicateButtonClick(upload), icon: <FaCopy size={20} className="ml-5 text-gray-600 font-bold" /> },
                                { label: `Delete`, onClick: () => handleDeleteButtonClick(upload), icon: <RiDeleteBinLine size={20} className="ml-5 text-gray-600 font-bold" /> },
                            ]} />
                        </div>
                        <div className='flex-1 p-1'>
                            {upload.posts[0].description && (
                                <p className="text-xs truncate">{upload.posts[0].description}</p>
                            )}
                        </div>
                        <div className='flex justify-start items-center px-1'>
                            <div className='flex items-center flex-1 gap-2'>
                                {upload.posts.map((post, i) => (
                                    <IconForSocialMedia socialMedia={post.uploadTo} key={i} />
                                ))}
                            </div>
                            <span className='text-xs text-gray-500 ml-2'>
                                {upload.posts[0].schedule ? formatCustomDateTime(new Date(upload.posts[0].schedule)) : formatCustomDateTime(upload.posts[0].updatedAt._seconds * 1000)}
                            </span>
                        </div>
                    </div>
                ))}
                {matchingTodos && showTodo && matchingTodos.map((todo, index) => (
                    <div key={`todo-${index}`} className="flex items-center justify-between p-1 bg-blue-100 rounded-md mb-1">
                        <span className={`text-xs ${todo.completed ? 'line-through text-green-600' : 'text-gray-700'} flex-1`}>{todo.todo}</span>
                        <input
                            type="checkbox"
                            checked={todo.completed}
                            onChange={() => handleTodoToggle(todo.id, todo.completed)}
                            className="mr-2 self-center"
                            disabled={isCheckboxDisabled}
                        />
                    </div>
                ))}
            </div>

            {
                isHovered && !isPastDay() && (
                    <div className="flex border-t border-gray-300 cursor-pointer text-[12px] justify-center items-center hover:text-blue-500 absolute bottom-0 bg-white w-full py-1" onClick={handleUploadButtonClick}>
                        Upload <MdCloudUpload size={20} color="black" className='ml-2' />
                    </div>
                )
            }

            {
                showModal && (
                    <Modal open={showModal} closeModal={closeModal} type={'VideoUploaderModal'} otherInfo={clickedData} />
                )
            }
            {
                isDeleteModalOpen && (
                    <Modal open={isDeleteModalOpen} closeModal={closeModal} type={'VerifyDelete'} otherInfo={deletedPost} />
                )
            }
        </div >
    );
}
