import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Icons used in the main component:
import { FaCog, FaUsers } from 'react-icons/fa';
import { MdManageAccounts } from "react-icons/md";
import { LuActivity } from "react-icons/lu";
import { FaSignOutAlt, FaSpinner, FaCheck, FaSave, FaMoon, FaSun, FaRegTrashAlt } from 'react-icons/fa';
import { MdEmail, MdPhone } from 'react-icons/md';
import { GoVerified, GoUnverified } from 'react-icons/go';
import { TiUserDelete } from 'react-icons/ti';
import { IoIosArrowForward, IoMdAdd } from 'react-icons/io';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { RiPencilLine, RiUserAddLine, RiDeleteBinLine } from 'react-icons/ri';

// Components & Tools
import Tabs from '../../../components/Navigation/MenuTabs/Tabs';
import Breadcrumbs from '../../../components/Tools/Breadcrumbs';
import { toast } from 'react-toastify';
import useLocalStorage from 'use-local-storage';
import { getAuth, signOut } from "firebase/auth";

import Modal from '../../../components/Modal/Modal';
import SearchInput from '../../../components/Tools/SearchInput';
import Pagination from '../../../components/Tools/Pagination';
import DropdownButton from '../../../components/Tools/DropdownButton';
import Info from '../../../components/Tools/Info';

// Context
import { useWorkspace } from '../../../context/WorkspaceContext';
import { Button } from '../../../ui/button';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Settings = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [userSettings, setUserSettings] = useState(null);
    const [selectedTab, setSelectedTab] = useState('account'); // Default selected tab

    const [activeSection, setActiveSection] = useState(null);
    const hasRunOnce = useRef(false);

    const { activeWorkspace, idToken, user } = useWorkspace();

    const tabs = [
        { id: 'account', label: 'Account', icon: <MdManageAccounts /> },
        { id: 'team', label: 'Team', icon: <FaUsers /> },
        { id: 'settings', label: 'Settings', icon: <FaCog /> },
    ];

    // Fetch user and settings
    useEffect(() => {
        if (!hasRunOnce.current && idToken) {
            getUser();
            getUserSettings();
            hasRunOnce.current = true;
        }
    }, [idToken]);

    const getUser = async () => {
        if (!idToken) {
            console.error('idToken is not set');
            return;
        }
        try {
            const response = await axios.get(`${BACKEND_API_URL}/api/auth/user/getUser`, {
                headers: { Authorization: `Bearer ${idToken}` }
            });
            setUserInfo(response.data);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const getUserSettings = async () => {
        if (!idToken) {
            console.error('idToken is not set');
            return;
        }

        try {
            const response = await axios.get(`${BACKEND_API_URL}/api/auth/user/settings`, {
                params: { workspaceId: activeWorkspace.id },
                headers: { Authorization: `Bearer ${idToken}` }
            });

            // Initialize default fallback values
            const showGuide = response.data.showGuide ?? true;
            const showAnalytics = response.data.showAnalytics ?? true;
            const showTodo = response.data.showTodo ?? true;

            setUserSettings({
                ...response.data,
                showGuide,
                showAnalytics,
                showTodo
            });
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    // Handles which sub-section is "active" for auto-scroll
    const handleMenuClick = (tabId) => {
        const sectionRef = document.getElementById(tabId);
        if (sectionRef) {
            sectionRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleTabChange = (tabId) => {
        setSelectedTab(tabId);
    };

    return (
        <div className='dashboard-container max-w-[100%] w-[95%] lg:w-[1200px] mx-auto'>
            <div className="mb-3">
                <Breadcrumbs />
            </div>

            <div className=''>
                <div className='mb-5 w-max mx-auto'>
                    <Tabs tabs={tabs} onTabChange={handleTabChange} activeTab={selectedTab} main={true} />
                </div>

                {/* Main content switch based on selectedTab */}
                <div className='pt-3'>
                    {selectedTab === 'account' && (
                        <div className='flex'>
                            {/* Sidebar for sub-sections */}
                            <div className='w-[20%] hidden sm:flex border-r-2 border-gray-200'>
                                <Sidebar
                                    from={selectedTab}
                                    setActiveSection={setActiveSection}
                                    handleMenuClick={handleMenuClick}
                                    idToken={idToken}
                                    activeSection={activeSection}
                                />
                            </div>
                            <div className='flex flex-col flex-1 gap-5 px-10'>
                                <ProfileRender
                                    userInfo={userInfo}
                                    user={user}
                                    idToken={idToken}
                                    refreshUser={getUser}
                                    isActive={activeSection === 'Profile'}
                                />
                                <ActivityRender
                                    userSettings={userSettings}
                                    isActive={activeSection === 'Activity'}
                                    idToken={idToken}
                                />
                                <DangerZoneRender
                                    userInfo={userInfo}
                                    idToken={idToken}
                                    refreshUser={getUser}
                                    isActive={activeSection === 'Danger'}
                                />
                            </div>
                        </div>
                    )}

                    {selectedTab === 'team' && (
                        <div className='flex'>
                            <TeamSection userInfo={userInfo} />
                        </div>
                    )}

                    {selectedTab === 'billing' && (
                        <></>
                    )}

                    {selectedTab === 'settings' && (
                        <div className='flex'>
                            <div className='w-[20%] hidden sm:flex border-r-2 border-gray-200'>
                                <Sidebar
                                    from={selectedTab}
                                    setActiveSection={setActiveSection}
                                    handleMenuClick={handleMenuClick}
                                    idToken={idToken}
                                    activeSection={activeSection}
                                />
                            </div>
                            <div className='flex flex-col flex-1 px-10'>
                                <DashboardSection
                                    userSettings={userSettings}
                                    isActive={activeSection === 'Dashboard'}
                                    idToken={idToken}
                                />
                                <GeneralSection
                                    userSettings={userSettings}
                                    isActive={activeSection === 'General'}
                                    idToken={idToken}
                                />
                                <NotificationsSection
                                    userSettings={userSettings}
                                    isActive={activeSection === 'Notifications'}
                                    idToken={idToken}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Settings;

/* -----------------------------------------------------------------------
   SUBCOMPONENTS
   ----------------------------------------------------------------------- */

/** Sidebar menu: shows sub-sections (Profile, Activity, Danger, etc.) */
const Sidebar = ({ from, setActiveSection, handleMenuClick, activeSection }) => {
    const sidebarRef = useRef(null);
    const initialTop = useRef(0);

    useEffect(() => {
        const handleScroll = () => {
            if (sidebarRef.current) {
                const offsetTop = initialTop.current;
                if (window.scrollY > offsetTop) {
                    sidebarRef.current.classList.add('fixed', 'top-0', 'z-10');
                } else {
                    sidebarRef.current.classList.remove('fixed', 'top-0', 'z-10');
                }
            }
        };
        initialTop.current = sidebarRef.current?.offsetTop || 0;

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    let menuItems = [];
    if (from === 'account') {
        menuItems = [
            { icon: <MdManageAccounts />, text: 'Profile', color: 'blue' },
            { icon: <LuActivity />, text: 'Activity', color: 'blue' },
            { icon: <span>⚠️</span>, text: 'Danger', color: 'blue' }, // Replaced MdWarning for a quick icon
        ];
    }
    if (from === 'settings') {
        menuItems = [
            { icon: <span>📊</span>, text: 'Dashboard', color: 'blue' },
            { icon: <FaCog />, text: 'General', color: 'blue' },
            { icon: <span>🔔</span>, text: 'Notifications', color: 'blue' },
        ];
    }

    const handleClick = (sectionName) => () => {
        setActiveSection(sectionName);
        handleMenuClick(sectionName);
    };

    return (
        <ul ref={sidebarRef} className='flex flex-col gap-2 w-full px-2'>
            {menuItems.map((item, index) => (
                <li key={index}>
                    <div
                        className={`flex items-center gap-2 px-4 py-3 cursor-pointer text-${item.color}-700 ${activeSection === item.text ? 'bg-gray-200 rounded-md' : ''}`}
                        onClick={handleClick(item.text)}
                    >
                        {item.icon}
                        <span className="text-sm font-medium">{item.text}</span>
                        {activeSection === item.text && <span className="ml-auto"><IoIosArrowForward /></span>}
                    </div>
                </li>
            ))}
        </ul>
    );
};

/* -----------------------------------------------------------------------
   ACCOUNT: Profile
   ----------------------------------------------------------------------- */
const ProfileRender = ({
    userInfo,
    refreshUser,
    isActive,
    idToken,
    user,
}) => {
    console.log(userInfo, user);
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [confirmSubmit, setConfirmSubmit] = useState(false);
    const [changesMade, setChangesMade] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isModalSecurityOpen, setIsModalSecurityOpen] = useState(false);
    const [isModalVerifyEmailOpen, setIsModalVerifyEmailOpen] = useState(false);

    // For the highlight effect if the user clicked "Profile" in the sidebar
    const [isActiveSection, setIsActiveSection] = useState(false);

    useEffect(() => {
        if (userInfo?.docExists) {
            setName(userInfo.user.username || '');
            setEmail(userInfo.user.email || '');
            setPhone(userInfo.user.phone || '');
        }
    }, [userInfo]);

    // Blink border when this section is active from sidebar
    useEffect(() => {
        setIsActiveSection(isActive);
        if (isActive) {
            const timerId = setTimeout(() => setIsActiveSection(false), 2000);
            return () => clearTimeout(timerId);
        }
    }, [isActive]);

    const handleSignOut = () => {
        const auth = getAuth();
        signOut(auth)
            .then(() => navigate("/login"))
            .catch((error) => console.log(error));
    };

    const handleInputChange = (e) => {
        const { name: inputName, value } = e.target;
        if (inputName === 'name') setName(value);
        if (inputName === 'email') setEmail(value);
        if (inputName === 'phone') setPhone(value);
        setChangesMade(true);
    };

    const handleEdit = async (e) => {
        e.preventDefault();
        if (!changesMade) return;

        if (!confirmSubmit) {
            // The first click triggers a "confirm" step
            setConfirmSubmit(true);
            return;
        }

        // Second click actually attempts update
        setIsLoading(true);
        try {
            const response = await axios.patch(
                `${BACKEND_API_URL}/api/auth/user/profile`,
                { name, email, phone },
                { headers: { Authorization: `Bearer ${idToken}` } }
            );

            if (response.status === 200) {
                toast.success('Your profile information has been updated successfully!');
                setChangesMade(false);
            } else {
                toast.error('Failed to update. Please try again later.');
            }
        } catch (error) {
            toast.error('Failed to update. Please try again later.');
        } finally {
            setIsLoading(false);
            setConfirmSubmit(false);
            refreshUser(); // Refresh the user info
        }
    };

    // Password modal
    const openSecurityModal = () => {
        setIsModalSecurityOpen(true);
    };
    const closeSecurityModal = () => {
        setIsModalSecurityOpen(false);
        refreshUser();
    };

    // Verify Email modal
    const openVerifyEmailModal = () => {
        setIsModalVerifyEmailOpen(true);
    };
    const closeVerifyEmailModal = () => {
        setIsModalVerifyEmailOpen(false);
        refreshUser();
    };

    return (
        <>
            <div
                id="Profile"
                className={`flex flex-col dashboard-section !rounded-xl p-5 ${isActiveSection ? 'border-blink' : 'ease-in duration-500 transition-all'}`}
            >
                <div className="flex justify-between text-2xl font-semibold pb-2">
                    <div>Profile</div>
                    <Button
                        variant='destructive'
                        onClick={handleSignOut}
                        size='md'
                    >
                        Logout <FaSignOutAlt className="ml-2" />
                    </Button>
                </div>

                {userInfo && (
                    <form onSubmit={handleEdit}>
                        <div className="mt-5 flex flex-col w-max p-2">
                            {/* Name */}
                            <div className='text-[12px] flex flex-col mb-2'>
                                <label className='flex items-center gap-1'>
                                    <MdEmail className='mr-1' /> NAME:
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={name}
                                    onChange={handleInputChange}
                                    autoComplete="username"
                                    className="border border-gray-300 text-background bg-transparent px-2 py-1 rounded-md w-[200px]"
                                />
                            </div>

                            {/* Email */}
                            <div className="text-[12px] flex flex-col mb-2">
                                <label className='flex items-center gap-1'>
                                    <MdEmail className='mr-1' /> EMAIL:
                                </label>
                                <div className='flex gap-2'>
                                    <input
                                        type="email"
                                        name="email"
                                        value={email}
                                        onChange={handleInputChange}
                                        autoComplete="email"
                                        className="border border-gray-300 px-2 text-background bg-transparent py-1 rounded-md w-[200px]"
                                    />
                                    {/* Verification status */}
                                    {user && user.emailVerified ? (
                                        <GoVerified size={30} color='green' />
                                    ) : (
                                        <div
                                            className='hover:text-blue-500 hover:scale-105 transform cursor-pointer flex flex-col justify-center items-center'
                                            onClick={openVerifyEmailModal}
                                        >
                                            <GoUnverified size={25} />
                                            <p className='text-[9px] italic'>Get verified</p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Phone */}
                            <div className='text-[12px] flex flex-col mb-2'>
                                <label className='flex items-center gap-1'>
                                    <MdPhone className='mr-1' /> PHONE:
                                </label>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={phone}
                                    onChange={handleInputChange}
                                    autoComplete="phone"
                                    className="border border-gray-300 text-background bg-transparent px-2 py-1 rounded-md w-[200px]"
                                />
                            </div>

                            {/* Password (disabled) */}
                            <div className='text-[12px] flex flex-col mb-2'>
                                <label className='flex items-center gap-1'>
                                    <MdPhone className='mr-1' /> PASSWORD:
                                </label>
                                <input
                                    type="password"
                                    value="***********"
                                    disabled
                                    autoComplete="current-password"
                                    className="border border-gray-300 px-2 py-1 rounded-md w-[200px] opacity-50 disabled:cursor-not-allowed"
                                />
                            </div>

                            {/* Submit button */}
                            <div className='flex'>
                                <button
                                    type='submit'
                                    disabled={!changesMade || isLoading}
                                    className={`
                    text-white flex items-center justify-between mt-3
                    ${changesMade ? '' : 'opacity-50 cursor-not-allowed'}
                    ${confirmSubmit ? 'bg-green-500 hover:bg-green-600' : 'bg-blue-500 hover:bg-blue-600'}
                  `}
                                >
                                    {isLoading ? (
                                        <FaSpinner className="animate-spin" />
                                    ) : confirmSubmit ? (
                                        <>
                                            <p>Confirm</p>
                                            <FaCheck className='ml-2' />
                                        </>
                                    ) : (
                                        <>
                                            <p>Save</p>
                                            <FaSave className='ml-2' />
                                        </>
                                    )}
                                </button>

                                {/* Change password link */}
                                <p
                                    className='--text-link hover:underline self-end ml-3 text-sm cursor-pointer'
                                    onClick={openSecurityModal}
                                >
                                    Change password
                                </p>
                            </div>
                        </div>
                    </form>
                )}
            </div>

            {/* Password Modal */}
            {isModalSecurityOpen && (
                <Modal
                    open={isModalSecurityOpen}
                    closeModal={closeSecurityModal}
                    type={'PasswordModal'}
                    otherInfo={email}
                />
            )}
            {/* Verify Email Modal */}
            {isModalVerifyEmailOpen && (
                <Modal
                    open={isModalVerifyEmailOpen}
                    closeModal={closeVerifyEmailModal}
                    type={'VerifyEmailModal'}
                    otherInfo={userInfo?.username}
                />
            )}
        </>
    );
};

/* -----------------------------------------------------------------------
   ACCOUNT: Activity
   ----------------------------------------------------------------------- */
const ActivityRender = ({ userSettings, isActive, idToken }) => {
    const [logs, setLogs] = useState([]);
    const [filteredLogs, setFilteredLogs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [currentItems, setCurrentItems] = useState([]);
    const [maxPage, setMaxPage] = useState(null);

    const [isActiveSection, setIsActiveSection] = useState(false);

    const itemsPerPage = 3;

    useEffect(() => {
        setIsActiveSection(isActive);
        if (isActive) {
            const timerId = setTimeout(() => setIsActiveSection(false), 2000);
            return () => clearTimeout(timerId);
        }
    }, [isActive]);

    // Fetch logs
    useEffect(() => {
        const fetchLogs = async () => {
            try {
                const response = await axios.get(`${BACKEND_API_URL}/api/auth/user/logs`, {
                    headers: { Authorization: `Bearer ${idToken}` }
                });
                if (response.data.success) {
                    setLogs(response.data.logs);
                }
            } catch (error) {
                console.error('Error fetching logs:', error);
            }
        };

        if (idToken) {
            fetchLogs();
        }
    }, [idToken]);

    // Filter logs by search term
    useEffect(() => {
        if (!searchTerm) {
            setFilteredLogs(logs);
        } else {
            const filtered = logs.filter(log =>
                log.activity.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredLogs(filtered);
        }
        setCurrentPage(1); // reset to page 1 on new search
    }, [searchTerm, logs]);

    // Pagination
    useEffect(() => {
        const paginate = (items) => {
            const indexOfLastItem = currentPage * itemsPerPage;
            const indexOfFirstItem = indexOfLastItem - itemsPerPage;
            const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
            const maxPage = Math.ceil(items.length / itemsPerPage);

            return { currentItems, maxPage };
        };

        const { currentItems: paginatedItems, maxPage: paginatedMaxPage } = paginate(filteredLogs);
        setCurrentItems(paginatedItems);
        setMaxPage(paginatedMaxPage);
    }, [filteredLogs, currentPage]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= maxPage) {
            setCurrentPage(newPage);
        }
    };

    return (
        <div
            id='Activity'
            className={`flex flex-col p-5 gap-5 dashboard-section !rounded-xl ${isActiveSection ? 'border-blink' : 'ease-in duration-500 transition-all '}`}
        >
            <div className='flex items-center gap-2 text-2xl font-semibold border-b-2 border-blue-500 pb-2'>
                Account Activity
            </div>
            <div className='flex justify-between flex-col md:flex-row'>
                <p className='text-sm'>Check your account's latest activities on SocialFlick.</p>

                <div className='flex gap-2'>
                    <SearchInput value={searchTerm} onChange={handleSearchChange} />
                    {maxPage > 1 && (
                        <div className='self-end'>
                            <Pagination
                                currentPage={currentPage}
                                maxPage={maxPage}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    )}
                </div>
            </div>

            {/* Activity Logs */}
            <div className='flex flex-col space-y-4 min-h-[200px] max-w-full'>
                {currentItems.map((log, index) => {
                    const logDate = new Date(
                        log.timestamp._seconds * 1000 + log.timestamp._nanoseconds / 1000000
                    );
                    return (
                        <div
                            key={index}
                            className='flex max-w-full shadow border border-background rounded-lg p-4 items-start space-x-4'
                        >
                            <div className='flex flex-col items-center'>
                                <span className='text-blue-500 rounded-full text-lg font-medium'>
                                    {logDate.toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    })}
                                </span>
                                <span className='text-[10px] italic'>
                                    {logDate.toLocaleTimeString('en-US', { timeStyle: 'short' })}
                                </span>
                            </div>
                            <div className='flex-1 min-w-0 max-w-[80%] max-h-[50px] overflow-hidden'>
                                <p className='text-sm font-medium'>{log.activity}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

/* -----------------------------------------------------------------------
   ACCOUNT: Danger Zone
   ----------------------------------------------------------------------- */
const DangerZoneRender = ({
    userInfo,
    idToken,
    refreshUser,
    isActive,
}) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isActiveSection, setIsActiveSection] = useState(false);

    useEffect(() => {
        setIsActiveSection(isActive);
        if (isActive) {
            const timerId = setTimeout(() => setIsActiveSection(false), 2000);
            return () => clearTimeout(timerId);
        }
    }, [isActive]);

    const openDeleteAccountModal = () => {
        setIsDeleteModalOpen(true);
    };

    const closeDeleteAccountModal = () => {
        setIsDeleteModalOpen(false);
        // If the user canceled, we might refresh data. If they truly deleted
        // the account, we wouldn't even be here. But we'll call refresh anyway:
        refreshUser();
    };

    return (
        <div
            id='Danger'
            className={`flex flex-col p-5 gap-5 dashboard-section !rounded-xl ${isActiveSection ? 'border-blink' : 'ease-in duration-500 transition-all '}`}
        >
            <div className='flex items-center gap-2 text-2xl font-semibold border-b-2 border-blue-500 pb-2'>
                Danger Zone <span className='text-yellow-500'>⚠️</span>
            </div>
            <p className='text-sm'>
                This action will delete your SocialFlick account entirely, including all data, templates,
                history, etc. <span className='underline'>We cannot help you recover your account!</span>
            </p>

            <div className='flex justify-between items-center bg-red-400 w-1/2 mx-auto shadow-md rounded-md'>
                <div className='flex gap-2 items-center'>
                    <div className='rounded-full shadow-inner p-2'>
                        <TiUserDelete size={30} />
                    </div>
                    <p>{userInfo?.username}</p>
                </div>
                <div
                    className='rounded-sm p-2 mr-3 shadow-inner cursor-pointer'
                    onClick={openDeleteAccountModal}
                >
                    <FaRegTrashAlt size={20} color='red' />
                </div>
            </div>

            {/* Delete Account Modal */}
            {isDeleteModalOpen && (
                <Modal
                    open={isDeleteModalOpen}
                    closeModal={closeDeleteAccountModal}
                    type={'DeleteAccountModal'}
                    otherInfo={userInfo}
                />
            )}
        </div>
    );
};

/* -----------------------------------------------------------------------
   TEAM
   ----------------------------------------------------------------------- */
export const TeamSection = ({ userInfo }) => {
    const { workspaces, fetchWorkspaces, loading } = useWorkspace();

    const [openModalWorkspace, setOpenModalWorkspace] = useState(false);
    const [openModalAddTeam, setOpenModalAddTeam] = useState(false);
    const [openModalDeleteWorkspace, setOpenModalDeleteWorkspace] = useState(false);
    const [selectedWorkspace, setSelectedWorkspace] = useState(null);

    const handleCreateWorkspace = (e) => {
        e.preventDefault();
        setSelectedWorkspace(null);
        setOpenModalWorkspace(true);
    };

    const handleEditButtonClick = (workspace) => {
        setSelectedWorkspace(workspace);
        setOpenModalWorkspace(true);
    };

    const handleDeleteButtonClick = (workspace) => {
        setSelectedWorkspace(workspace);
        setOpenModalDeleteWorkspace(true);
    };

    const handleAddTeamMemberClick = (workspace) => {
        setSelectedWorkspace(workspace);
        setOpenModalAddTeam(true);
    };

    const closeModal = (refresh = false) => {
        setOpenModalAddTeam(false);
        setOpenModalWorkspace(false);
        setOpenModalDeleteWorkspace(false);
        if (refresh) {
            fetchWorkspaces();
        }
    };

    // Placeholder skeleton
    const SkeletonWorkspace = () => (
        <div className="flex justify-between p-4 border border-gray-300 rounded-lg shadow-md w-full animate-pulse">
            <div className="flex flex-col items-center justify-center h-full">
                <div className="h-6 bg-gray-300 rounded w-3/4 mb-2 mx-auto"></div>
                <div className="flex items-center justify-center w-32 h-full bg-gray-300 rounded-md"></div>
            </div>
            <div className="flex flex-col items-center">
                <div className="h-6 bg-gray-300 rounded w-3/4 mb-2"></div>
                <div className="w-full h-32 bg-gray-300 rounded-md mt-2"></div>
            </div>
            <div className="flex flex-col w-full pl-4">
                <div className="h-6 bg-gray-300 rounded w-1/2 mb-2"></div>
                <ul className="space-y-2">
                    <div className="flex flex-col gap-3">
                        <div className="h-10 bg-gray-300 rounded w-full"></div>
                        <div className="h-10 bg-gray-300 rounded w-full"></div>
                        <div className="h-10 bg-gray-300 rounded w-full"></div>
                    </div>
                </ul>
            </div>
        </div>
    );

    return (
        <div className="w-full mx-auto rounded-lg !p-10 --theme-color dashboard-section !rounded-xl">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Workspace Management</h2>
                <button
                    className="flex items-center bg-blue-500 text-white px-3 py-2 rounded-md hover:bg-blue-600"
                    onClick={handleCreateWorkspace}
                >
                    <IoMdAdd className="mr-2" /> Create New Workspace
                </button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-4">
                {loading ? (
                    <>
                        <SkeletonWorkspace />
                        <SkeletonWorkspace />
                    </>
                ) : (
                    workspaces.map((workspace) => {
                        // Determine current user’s role to see if they can edit
                        const currentUserRole = workspace.members.find(
                            (m) => m.email === userInfo?.user?.email
                        )?.role;
                        const isAuthorized = currentUserRole === 'owner' || currentUserRole === 'admin';

                        return (
                            <div
                                key={workspace.id}
                                className="flex items-start justify-between p-4 border border-gray-300 rounded-lg shadow-md"
                            >
                                <div className="flex flex-col items-center">
                                    <h3 className="text-lg font-semibold">{workspace.name}</h3>
                                    <img
                                        src={workspace.image || `${process.env.PUBLIC_URL}/random/no-image.png`}
                                        alt={workspace.name}
                                        className="w-full h-32 object-cover rounded-md mt-2"
                                    />
                                </div>
                                <div className="flex flex-col w-full pl-4">
                                    <h4 className="text-md font-semibold mb-2 text-gray-500">Team Members</h4>
                                    <ul className="space-y-2">
                                        <div className="flex flex-col gap-5">
                                            {workspace.members && workspace.members.length > 0 ? (
                                                workspace.members.map((member, idx) => (
                                                    <li key={idx} className="flex flex-col text-sm text-gray-500 border-b pb-2">
                                                        <div className="flex items-center">
                                                            <span className="font-medium text-gray-700">
                                                                {member.username}
                                                            </span>
                                                            <span className="ml-2 bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                                                                {member.role}
                                                            </span>
                                                        </div>
                                                        <span>Email: {member.email}</span>
                                                    </li>
                                                ))
                                            ) : (
                                                <li className="text-sm text-gray-500">No team members yet.</li>
                                            )}
                                        </div>
                                    </ul>
                                </div>

                                {isAuthorized && (
                                    <DropdownButton
                                        label={<BsThreeDotsVertical size={18} />}
                                        type={'singleLeftItems'}
                                        classes="!p-0 hover:bg-none"
                                        options={[
                                            {
                                                label: `Edit workspace`,
                                                onClick: () => handleEditButtonClick(workspace),
                                                icon: <RiPencilLine size={20} className="ml-5 text-blue-600 font-bold" />,
                                                disabled: !isAuthorized
                                            },
                                            {
                                                label: `Add team member`,
                                                onClick: () => handleAddTeamMemberClick(workspace),
                                                icon: <RiUserAddLine size={20} className="ml-5 text-blue-600 font-bold" />,
                                                disabled: !isAuthorized
                                            },
                                            {
                                                label: `Delete workspace`,
                                                onClick: () => handleDeleteButtonClick(workspace),
                                                icon: <RiDeleteBinLine size={20} className="ml-5 text-blue-600 font-bold" />,
                                                disabled: !isAuthorized
                                            },
                                        ]}
                                    />
                                )}
                            </div>
                        );
                    })
                )}
            </div>

            {/* Modals related to TeamSection */}
            {openModalAddTeam && (
                <Modal
                    open={openModalAddTeam}
                    closeModal={closeModal}
                    type={'AddTeamMemberModal'}
                    otherInfo={selectedWorkspace}
                />
            )}
            {openModalWorkspace && (
                <Modal
                    open={openModalWorkspace}
                    closeModal={closeModal}
                    type={'CreateWorkspaceModal'}
                    otherInfo={{ userInfo: userInfo.user, workspace: selectedWorkspace }}
                />
            )}
            {openModalDeleteWorkspace && (
                <Modal
                    open={openModalDeleteWorkspace}
                    closeModal={closeModal}
                    type={'ConfirmDeleteWorkspace'}
                    otherInfo={selectedWorkspace}
                />
            )}
        </div>
    );
};

/* -----------------------------------------------------------------------
   SETTINGS: Dashboard
   ----------------------------------------------------------------------- */
const DashboardSection = ({ isActive, userSettings, idToken }) => {
    const [isActiveSection, setIsActiveSection] = useState(false);

    const [storedTheme, setStoredTheme] = useState('');
    const [activeGuide, setActiveGuide] = useState(false);
    const [activeTodo, setActiveTodo] = useState(false);
    const [activeAnalytics, setActiveAnalytics] = useState(false);
    const [somethingChanged, setSomethingChanged] = useState(false);

    // local storage for theme
    const [theme, setTheme] = useLocalStorage('theme');

    const { activeWorkspace } = useWorkspace();

    useEffect(() => {
        setIsActiveSection(isActive);
        if (isActive) {
            const timerId = setTimeout(() => setIsActiveSection(false), 2000);
            return () => clearTimeout(timerId);
        }
    }, [isActive]);

    useEffect(() => {
        if (userSettings) {
            setStoredTheme(userSettings.theme);
            setActiveGuide(userSettings.showGuide);
            setActiveTodo(userSettings.showTodo);
            setActiveAnalytics(userSettings.showAnalytics);
        }
    }, [userSettings]);

    const toggleTheme = (clickedTheme) => {
        setStoredTheme(clickedTheme);
        if (userSettings && clickedTheme !== userSettings.theme) {
            userSettings.theme = clickedTheme;
            setSomethingChanged(true);
        }
    };

    const toggleGuide = () => {
        const newVal = !activeGuide;
        setActiveGuide(newVal);
        if (userSettings && newVal !== userSettings.showGuide) {
            userSettings.showGuide = newVal;
            setSomethingChanged(true);
        }
    };

    const toggleTodo = () => {
        const newVal = !activeTodo;
        setActiveTodo(newVal);
        if (userSettings && newVal !== userSettings.showTodo) {
            userSettings.showTodo = newVal;
            setSomethingChanged(true);
        }
    };

    const toggleAnalytics = () => {
        const newVal = !activeAnalytics;
        setActiveAnalytics(newVal);
        if (userSettings && newVal !== userSettings.showAnalytics) {
            userSettings.showAnalytics = newVal;
            setSomethingChanged(true);
        }
    };

    const handleSaveChanges = async () => {
        const obj = {
            showGuide: activeGuide,
            theme: storedTheme,
            showTodo: activeTodo,
            showAnalytics: activeAnalytics,
            workspaceId: activeWorkspace.id
        };
        // Persist theme to local storage
        setTheme(storedTheme);

        try {
            const response = await axios.post(
                `${BACKEND_API_URL}/api/auth/user/settings`,
                obj,
                { headers: { Authorization: `Bearer ${idToken}` } }
            );
            if (response.status === 201) {
                setSomethingChanged(false);
            } else {
                console.error('Failed to update user settings');
            }
        } catch (error) {
            console.error('Error updating user settings:', error);
        }
    };

    return (
        <div
            id="Dashboard"
            className={`flex flex-col p-5 mb-5 dashboard-section !rounded-xl ${isActiveSection ? 'border-blink' : 'ease-in duration-500 transition-all '}`}
        >
            <div className='flex justify-between items-end gap-5'>
                <div className="text-2xl font-semibold border-b-2 border-blue-500 pb-2 flex-1">
                    Dashboard
                </div>
                {somethingChanged && (
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
                        onClick={handleSaveChanges}
                    >
                        Save Changes
                    </button>
                )}
            </div>

            {/* THEME */}
            <div className='flex flex-col mt-5'>
                <div>
                    <p className='font-semibold'>Dashboard Theme</p>
                    <p className='text-[11px] mb-2'>Select the theme that fits your style</p>
                </div>
                <div className='flex gap-5'>
                    <div
                        className={`
              flex flex-1 relative items-center p-3 rounded-lg mt-3 gap-2 --dark-theme-color
              cursor-pointer hover:scale-105
              ${storedTheme === 'dark' ? '!border-blue-400 border-2' : ''}
            `}
                        onClick={() => toggleTheme('dark')}
                    >
                        <FaMoon />
                        Dark mode
                        {storedTheme === 'dark' && (
                            <div className='absolute right-[-10px] top-[-5px]'>
                                <FaCheck className='text-green-500' size={20} />
                            </div>
                        )}
                    </div>

                    <div
                        className={`
              flex flex-1 relative items-center gap-2 p-3 rounded-lg mt-3
              --light-theme-color cursor-pointer hover:scale-105
              ${storedTheme === 'light' ? '!border-blue-500 border-2' : ''}
            `}
                        onClick={() => toggleTheme('light')}
                    >
                        <FaSun />
                        Light mode
                        {storedTheme === 'light' && (
                            <div className='absolute right-[-10px] top-[-5px]'>
                                <FaCheck className='text-green-500' size={25} />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* SHOW/HIDE GUIDE & TODO */}
            <div className='flex flex-col lg:flex-row gap-2 mt-5'>
                <div className="flex flex-col w-[95%] md:w-[50%] border border-gray-300 p-3 rounded-lg">
                    <div className='flex items-center gap-2'>
                        <p className="mr-2 font-semibold">Guide/Onboarding</p>
                        <div className='w-max group rounded-full px-1 pt-1 hover:bg-blue-500'>
                            <Info type={'guide'} info={userSettings} />
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-sm mt-2'>
                            Show our 5 steps "Get Started" guide for a fast setup.
                        </p>
                        <label htmlFor="toggleGuide" className="flex items-center cursor-pointer">
                            <div
                                className={`
                  relative ${activeGuide ? 'bg-indigo-600' : 'bg-gray-200'}
                  rounded-full w-12 h-6 transition duration-200 ease-in-out
                `}
                            >
                                <div
                                    className={`
                    absolute left-1 top-1 bg-white w-4 h-4 rounded-full shadow-md transform
                    ${activeGuide ? 'translate-x-6' : 'translate-x-0'}
                    transition duration-200 ease-in-out
                  `}
                                ></div>
                            </div>
                            <input
                                type="checkbox"
                                id="toggleGuide"
                                className="sr-only"
                                checked={activeGuide}
                                onChange={toggleGuide}
                            />
                        </label>
                    </div>
                </div>

                <div className="flex flex-col w-[95%] md:w-[50%] border border-gray-300 p-3 rounded-lg">
                    <div className='flex items-center gap-2'>
                        <p className="mr-2 font-semibold">Todo</p>
                        <div className='w-max group rounded-full px-1 pt-1 hover:bg-blue-500'>
                            <Info type={'todo'} />
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-sm mt-2'>
                            Show your created todo list.
                        </p>
                        <label htmlFor="toggleTodo" className="flex items-center cursor-pointer">
                            <div
                                className={`
                  relative ${activeTodo ? 'bg-indigo-600' : 'bg-gray-200'}
                  rounded-full w-12 h-6 transition duration-200 ease-in-out
                `}
                            >
                                <div
                                    className={`
                    absolute left-1 top-1 bg-white w-4 h-4 rounded-full shadow-md transform
                    ${activeTodo ? 'translate-x-6' : 'translate-x-0'}
                    transition duration-200 ease-in-out
                  `}
                                ></div>
                            </div>
                            <input
                                type="checkbox"
                                id="toggleTodo"
                                className="sr-only"
                                checked={activeTodo}
                                onChange={toggleTodo}
                            />
                        </label>
                    </div>
                </div>
            </div>

            {/* ANALYTICS */}
            <div className='flex flex-col lg:flex-row gap-2 mt-5'>
                <div className="flex flex-col w-[95%] md:w-[50%] border border-gray-300 p-3 rounded-lg">
                    <div className='flex items-center gap-2'>
                        <p className="mr-2 font-semibold">Analytics</p>
                        <div className='w-max group rounded-full px-1 pt-1 hover:bg-blue-500'>
                            <Info type={'analytics'} />
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-sm mt-2'>
                            Show analytics section on your dashboard.
                        </p>
                        <label htmlFor="toggleAnalytics" className="flex items-center cursor-pointer">
                            <div
                                className={`
                  relative ${activeAnalytics ? 'bg-indigo-600' : 'bg-gray-200'}
                  rounded-full w-12 h-6 transition duration-200 ease-in-out
                `}
                            >
                                <div
                                    className={`
                    absolute left-1 top-1 bg-white w-4 h-4 rounded-full shadow-md transform
                    ${activeAnalytics ? 'translate-x-6' : 'translate-x-0'}
                    transition duration-200 ease-in-out
                  `}
                                ></div>
                            </div>
                            <input
                                type="checkbox"
                                id="toggleAnalytics"
                                className="sr-only"
                                checked={activeAnalytics}
                                onChange={toggleAnalytics}
                            />
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

/* -----------------------------------------------------------------------
   SETTINGS: General
   ----------------------------------------------------------------------- */
const GeneralSection = ({ isActive, userSettings, idToken }) => {
    const [selectedTimezone, setSelectedTimezone] = useState(userSettings?.timezone || 'UTC');
    const [somethingChanged, setSomethingChanged] = useState(false);
    const [isActiveSection, setIsActiveSection] = useState(false);
    const { activeWorkspace } = useWorkspace();
    useEffect(() => {
        setIsActiveSection(isActive);
        if (isActive) {
            const timerId = setTimeout(() => setIsActiveSection(false), 2000);
            return () => clearTimeout(timerId);
        }
    }, [isActive]);

    // Track changes
    useEffect(() => {
        if (userSettings && userSettings.timezone !== selectedTimezone) {
            setSomethingChanged(true);
        }
    }, [selectedTimezone, userSettings]);

    const handleTimezoneChange = (timezone) => {
        setSelectedTimezone(timezone);
    };

    const handleSaveChanges = async () => {
        const obj = {
            timezone: selectedTimezone,
            workspaceId: activeWorkspace.id
        };
        try {
            const response = await axios.post(
                `${BACKEND_API_URL}/api/auth/user/settings`,
                obj,
                { headers: { Authorization: `Bearer ${idToken}` } }
            );
            if (response.status === 201) {
                setSomethingChanged(false);
            } else {
                console.error('Failed to save new timezone');
            }
        } catch (error) {
            console.error('Error saving new timezone:', error);
        }
    };

    return (
        <div
            id="General"
            className={`flex flex-col p-5 mb-5 dashboard-section !rounded-xl ${isActiveSection ? 'border-blink' : 'ease-in duration-500 transition-all '}`}
        >
            <div className='flex justify-between items-end gap-5'>
                <div className="text-2xl font-semibold border-b-2 border-blue-500 pb-2 flex-1">
                    General Settings
                </div>
                {somethingChanged && (
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
                        onClick={handleSaveChanges}
                    >
                        Save Changes
                    </button>
                )}
            </div>

            <div className='flex flex-col mt-5'>
                <div>
                    <p className='font-semibold'>Timezone</p>
                    <p className='text-[11px] mb-2'>Set your preferred timezone</p>
                </div>
                <div className='flex items-end justify-between text-black'>
                    <Timezones
                        currentTimezone={userSettings?.timezone}
                        onTimezoneChange={handleTimezoneChange}
                    />
                </div>
            </div>
        </div>
    );
};

/** A small timezone selector */
const Timezones = ({ currentTimezone, onTimezoneChange }) => {
    const [selectedTimezone, setSelectedTimezone] = useState(currentTimezone || 'UTC');

    const tzList = {
        "Etc/GMT+12": "(GMT-12:00) International Date Line West",
        "Pacific/Midway": "(GMT-11:00) Midway Island, Samoa",
        "Pacific/Honolulu": "(GMT-10:00) Hawaii",
        "US/Alaska": "(GMT-09:00) Alaska",
        "America/Los_Angeles": "(GMT-08:00) Pacific Time (US & Canada)",
        "US/Arizona": "(GMT-07:00) Arizona",
        "America/Managua": "(GMT-06:00) Central America",
        "US/Central": "(GMT-06:00) Central Time (US & Canada)",
        "America/Bogota": "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
        "US/Eastern": "(GMT-05:00) Eastern Time (US & Canada)",
        "Canada/Atlantic": "(GMT-04:00) Atlantic Time (Canada)",
        "America/Argentina/Buenos_Aires": "(GMT-03:00) Buenos Aires, Georgetown",
        "America/Noronha": "(GMT-02:00) Mid-Atlantic",
        "Atlantic/Azores": "(GMT-01:00) Azores",
        "UTC": "(GMT+00:00) Dublin, Edinburgh, Lisbon, London",
        "Europe/Amsterdam": "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
        "Europe/Helsinki": "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
        "Europe/Moscow": "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
        "Asia/Tehran": "(GMT+03:30) Tehran",
        "Asia/Yerevan": "(GMT+04:00) Yerevan",
        "Asia/Kabul": "(GMT+04:30) Kabul",
        "Asia/Yekaterinburg": "(GMT+05:00) Yekaterinburg",
        "Asia/Karachi": "(GMT+05:00) Islamabad, Karachi, Tashkent",
        "Asia/Calcutta": "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
        "Asia/Katmandu": "(GMT+05:45) Kathmandu",
        "Asia/Dhaka": "(GMT+06:00) Astana, Dhaka",
        "Asia/Rangoon": "(GMT+06:30) Yangon (Rangoon)",
        "Asia/Bangkok": "(GMT+07:00) Bangkok, Hanoi, Jakarta",
        "Asia/Hong_Kong": "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
        "Asia/Seoul": "(GMT+09:00) Seoul",
        "Australia/Adelaide": "(GMT+09:30) Adelaide",
        "Australia/Canberra": "(GMT+10:00) Canberra, Melbourne, Sydney",
        "Asia/Magadan": "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
        "Pacific/Auckland": "(GMT+12:00) Auckland, Wellington",
        "Pacific/Tongatapu": "(GMT+13:00) Nuku'alofa"
    };

    const handleChange = (event) => {
        const timezone = event.target.value;
        setSelectedTimezone(timezone);
        onTimezoneChange(timezone);
    };

    return (
        <select
            name="timezone"
            className="w-full lg:w-1/2 overflow-hidden border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={selectedTimezone}
            onChange={handleChange}
        >
            <option value="" disabled>Select Timezone</option>
            {Object.entries(tzList).map(([tz, label]) => (
                <option key={tz} value={tz}>
                    {label}
                </option>
            ))}
        </select>
    );
};

/* -----------------------------------------------------------------------
   SETTINGS: Notifications
   ----------------------------------------------------------------------- */
const NotificationsSection = ({ userSettings, isActive = false, idToken }) => {

    const [isActiveSection, setIsActiveSection] = useState(false);
    const [isActiveNotifications, setIsActiveNotifications] = useState(userSettings?.notifications?.isActive || false);
    const [errorNotifications, setErrorNotifications] = useState(userSettings?.notifications?.errorNotifications || false);
    const [socialAccountDisconnection, setSocialAccountDisconnection] = useState(
        userSettings?.notifications?.socialAccountDisconnection || false
    );
    const [somethingChanged, setSomethingChanged] = useState(false);

    const { activeWorkspace } = useWorkspace();

    useEffect(() => {
        setIsActiveSection(isActive);
        if (isActive) {
            const timerId = setTimeout(() => setIsActiveSection(false), 2000);
            return () => clearTimeout(timerId);
        }
    }, [isActive]);

    // Toggle entire notifications on/off
    const handleActivateNotifications = (activate) => {
        if (!activate) {
            // Turn off all child notifications if user deactivates everything
            setErrorNotifications(false);
            setSocialAccountDisconnection(false);
        }
        setIsActiveNotifications(activate);
        setSomethingChanged(true);
    };

    // Toggle error notifications
    const handleToggleErrors = () => {
        const newValue = !errorNotifications;
        setErrorNotifications(newValue);
        setSomethingChanged(true);
    };

    // Toggle social account disconnection
    const handleToggleDisconnection = () => {
        const newValue = !socialAccountDisconnection;
        setSocialAccountDisconnection(newValue);
        setSomethingChanged(true);
    };

    const handleSaveChanges = async () => {
        const updated = {
            notifications: {
                isActive: isActiveNotifications,
                errorNotifications: errorNotifications,
                socialAccountDisconnection: socialAccountDisconnection
            },
            workspaceId: activeWorkspace.id
        };

        try {
            const response = await axios.post(
                `${BACKEND_API_URL}/api/auth/user/settings`,
                updated,
                { headers: { Authorization: `Bearer ${idToken}` } }
            );
            if (response.status === 201) {
                setSomethingChanged(false);
            } else {
                console.error('Failed to update notifications');
            }
        } catch (error) {
            console.error('Error updating notifications:', error);
        }
    };

    return (
        <div
            id="Notifications"
            className={`flex flex-col p-5 dashboard-section !rounded-xl ${isActiveSection ? 'border-blink' : 'ease-in duration-500 transition-all '}`}
        >
            <div className='flex justify-between items-end gap-5'>
                <div className="text-2xl font-semibold border-b-2 border-blue-500 pb-2 flex-1">
                    Notifications
                </div>
                {somethingChanged && (
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
                        onClick={handleSaveChanges}
                    >
                        Save Changes
                    </button>
                )}
            </div>
            <div className='flex flex-col'>
                <p>Receive notifications</p>
                <p className='text-sm mt-2'>
                    Activate notifications to receive alerts for actions within the app
                </p>
                <div className='flex pt-2'>
                    <div className='flex mr-5'>
                        <label htmlFor="activateNotifications">
                            <input
                                type="radio"
                                name="notifications"
                                value="activate"
                                id="activateNotifications"
                                checked={isActiveNotifications}
                                onChange={() => handleActivateNotifications(true)}
                                className='mr-2 self-center'
                            />
                            Activate
                        </label>
                    </div>
                    <div className='flex'>
                        <label htmlFor="deactivateNotifications">
                            <input
                                type="radio"
                                name="notifications"
                                value="deactivate"
                                id="deactivateNotifications"
                                checked={!isActiveNotifications}
                                onChange={() => handleActivateNotifications(false)}
                                className='mr-2'
                            />
                            Deactivate
                        </label>
                    </div>
                </div>
            </div>

            {/* Child toggles (greyed out if general notifications are off) */}
            <div className={`${!isActiveNotifications && 'opacity-20 pointer-events-none'}`}>
                <div className="flex flex-col mt-3">
                    <div className="flex flex-col w-[95%] md:w-[50%] border border-gray-300 p-3 rounded-lg">
                        <p className="mr-2">Error notifications</p>
                        <div className="flex justify-between">
                            <p className='text-sm mt-2'>
                                Receive alerts for post upload errors.
                            </p>
                            <label htmlFor="toggleError" className="flex items-center cursor-pointer">
                                <div
                                    className={`
                    relative ${errorNotifications ? 'bg-indigo-600' : 'bg-gray-200'}
                    rounded-full w-12 h-6 transition duration-200 ease-in-out
                  `}
                                >
                                    <div
                                        className={`
                      absolute left-1 top-1 bg-white w-4 h-4 rounded-full shadow-md transform
                      ${errorNotifications ? 'translate-x-6' : 'translate-x-0'}
                      transition duration-200 ease-in-out
                    `}
                                    ></div>
                                </div>
                                <input
                                    type="checkbox"
                                    id="toggleError"
                                    className="sr-only"
                                    checked={errorNotifications}
                                    onChange={handleToggleErrors}
                                />
                            </label>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col mt-3">
                    <div className="flex flex-col w-[95%] md:w-[50%] border border-gray-300 p-3 rounded-lg">
                        <p className="mr-2">Social Account Disconnection</p>
                        <div className="flex justify-between">
                            <p className='text-sm mt-2'>
                                Get notified for upcoming social media logouts.
                            </p>
                            <label htmlFor="toggleDisconnection" className="flex items-center cursor-pointer">
                                <div
                                    className={`
                    relative ${socialAccountDisconnection ? 'bg-indigo-600' : 'bg-gray-200'}
                    rounded-full w-12 h-6 transition duration-200 ease-in-out
                  `}
                                >
                                    <div
                                        className={`
                      absolute left-1 top-1 bg-white w-4 h-4 rounded-full shadow-md transform
                      ${socialAccountDisconnection ? 'translate-x-6' : 'translate-x-0'}
                      transition duration-200 ease-in-out
                    `}
                                    ></div>
                                </div>
                                <input
                                    type="checkbox"
                                    id="toggleDisconnection"
                                    className="sr-only"
                                    checked={socialAccountDisconnection}
                                    onChange={handleToggleDisconnection}
                                />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
