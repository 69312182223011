import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { FaInfoCircle, FaPlus, FaRegCalendarCheck } from 'react-icons/fa';
import Textarea from '../../Tools/Textarea';
import Modal from '../../Modal/Modal';
import CheckboxBtn from '../Checkbox/Checkboxbtn';
import axios from 'axios';
import Info from '../../Tools/Info';
import Tabs from '../../Navigation/MenuTabs/Tabs';
import { v4 as uuidv4 } from 'uuid';
import { LuSend } from 'react-icons/lu';
import { formatCustomDateTime } from '../../../utils/date';
import { getFileType } from '../../../utils/getFileType';
import VideoPreview from '../Preview/Preview';
import UploadArea from '../Preview/UploadArea';
import { Button } from '../../../ui/button';
import { useWorkspace } from '../../../context/WorkspaceContext';
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const UploadForm = ({ draftFormData, onFormAction, video, uploadType = 'video', onFileSelect }) => {
  const { activeWorkspace, idToken } = useWorkspace();
  const [openModal, setOpenModal] = useState(false);
  const [userSocials, setUserSocials] = useState([]);
  const [isModalScheduleOpen, setIsModalScheduleOpen] = useState(false);
  const [socialChecks, setSocialChecks] = useState({
    facebook: false,
    instagram: false,
    tiktok: false,
    youtube: false,
  });
  const [errorMessages, setErrorMessages] = useState([]);

  const broadcastChannel = useMemo(() => new BroadcastChannel('socialsChannel'), []);

  const uniquePostId = uuidv4();
  const [deactivatedFormData, setDeactivatedFormData] = useState([]);

  const siteMenuInitial = useMemo(() => [
    { id: 'draft', label: 'Draft', icon: null },
    { id: 'tiktok', label: 'TT', icon: null },
    { id: 'youtube', label: 'YT', icon: null },
    { id: 'instagram', label: 'IG', icon: null },
    { id: 'facebook', label: 'FB', icon: null },
    { id: 'x', label: 'X', icon: null },
  ], []);

  const [siteMenu, setSiteMenu] = useState([]);
  const [activeMenu, setActiveMenu] = useState('draft');

  const [formData, setFormData] = useState([{
    uploadTo: 'draft',
    draftId: '',
    platformId: '',
    postId: uniquePostId,
    title: '',
    description: '',
    uploadType: uploadType,
    privacyStatus: 'public',
    commentEnabled: true,
    isContentDisclosed: false,
    duetEnabled: true,
    stitchEnabled: true,
    isBranded: false,
    isOwnBrand: false,
    hasBeenEdited: false,
  }]);

  const initialSetupRef = useRef(true);
  const initialLoad = useRef(true);
  const isUpdatingUserSocials = useRef(true);
  const newFormDataRef = useRef(null);


  const getUserSocials = useCallback(async () => {
    if (!idToken || !activeWorkspace.id) return;

    try {
      const response = await axios.get(BACKEND_API_URL + '/api/auth/user/social_media_tokens', {
        params: { workspaceId: activeWorkspace.id },
        headers: { Authorization: `Bearer ${idToken}` },
      });

      response.data.forEach((item) => {
        item.checkbox = false;
      });

      let allowedPlatforms = response.data;

      if (uploadType === 'post') {
        allowedPlatforms = response.data.filter(
          (item) =>
            item.platform === 'facebook' || item.platform === 'instagram' || item.platform === 'x'
        );
      } else {
        allowedPlatforms = response.data;
      }

      for (const social of response.data) {
        if (social.platform in socialChecks) {
          setSocialChecks(prev => ({ ...prev, [social.platform]: social.checkbox }));
        }
      }
      setUserSocials(allowedPlatforms);
      return response.data;
    } catch (error) {
      console.error('Error fetching social media tokens:', error);
    }
  }, [idToken, activeWorkspace.id, uploadType, socialChecks]);

  useEffect(() => {
    if (initialSetupRef.current && activeWorkspace.id && idToken) {

      // Handle broadcast messages
      const handleBroadcastMessage = async () => {
        try {
          broadcastChannel.onmessage = async (event) => {
            if (event.data === 'callbackLogin') {
              await getUserSocials();
              setOpenModal(false);
            }
          };
        } catch (error) {
          console.error('Error handling broadcast message:', error);
        }
      };

      handleBroadcastMessage();

      // Fetch initial user socials
      getUserSocials();
      initialSetupRef.current = false;
    }

    // Cleanup function for broadcast channel
    return () => {
      broadcastChannel.close();
    };
  }, [broadcastChannel, getUserSocials, setOpenModal, activeWorkspace.id, idToken]);


  // DONE EFFECTIVIZING

  useEffect(() => {
    if (video.firebasePath) {
      setFormData((prevFormData) => prevFormData.map((item) => ({
        ...item,
        hasBeenEdited: true,
      })));
    }
  }, [video]);

  const insertDraftMenu = useCallback((data) => {
    const containsDraftStatus = data.some(data => data.status === 'draft');
    if (containsDraftStatus) {
      setSiteMenu(prevSiteMenu => {
        const draftTab = siteMenuInitial.find(menuItem => menuItem.id === 'draft');
        if (draftTab && !prevSiteMenu.some(menuItem => menuItem.id === 'draft')) {
          return [...prevSiteMenu, draftTab];
        }
        return prevSiteMenu;
      });
    }
  }, [siteMenuInitial, setSiteMenu]);



  useEffect(() => {
    if (initialLoad.current && draftFormData && draftFormData.length > 0) {
      const hasMatchingPlatform = draftFormData.some(draft =>
        userSocials.some(social => social.platform_id === draft.platformId)
      );
      if (hasMatchingPlatform) {
        const updatedDraftFormData = draftFormData.map((data) => ({
          ...data,
          postId: uniquePostId,
        }));
        setFormData(updatedDraftFormData);
        insertDraftMenu(updatedDraftFormData);
        initialLoad.current = false;
      }
    }
  }, [draftFormData, uniquePostId, insertDraftMenu, userSocials]);

  const handleSitieMenuChange = (newTab) => {
    setActiveMenu(newTab);
  };



  useEffect(() => {
    if (isUpdatingUserSocials.current && userSocials.length > 0 && draftFormData.length > 0) {
      isUpdatingUserSocials.current = false;
      const updatedUserSocials = userSocials.map(userSocial => {
        const isMatched = draftFormData.some(upload => upload.platformId === userSocial.platform_id);

        if (userSocial.platform in socialChecks) {
          setSocialChecks(prev => ({ ...prev, [userSocial.platform]: isMatched }));
        }


        return { ...userSocial, checkbox: isMatched };
      });

      if (JSON.stringify(updatedUserSocials) !== JSON.stringify(userSocials)) {
        setUserSocials(updatedUserSocials);
      }
    }
  }, [draftFormData, userSocials, socialChecks]);


  useEffect(() => {
    if (userSocials.length > 0) {
      const atLeastOneChecked = userSocials.some((social) => social.checkbox === true);
      let isDraft = false;

      if (draftFormData && draftFormData.length > 0) {
        isDraft = draftFormData.some(upload => upload.uploadTo === 'draft');
      }

      let updatedSiteMenu = [];

      if (atLeastOneChecked) {
        updatedSiteMenu = siteMenuInitial
          .filter(menuItem =>
            userSocials.some(social => social.platform === menuItem.id && social.checkbox === true)
          )
          .flatMap(menuItem => {
            const matchedSocials = userSocials.filter(
              social => social.platform === menuItem.id && social.checkbox === true
            );

            const matched = matchedSocials.map(social => ({
              ...menuItem,
              id: social.platform_id,
              label: `${menuItem.label} (${social.username.substring(0, 4)}..)`
            }));
            return matched;
          });
      }
      if (!atLeastOneChecked || isDraft) {
        const draftTab = { id: 'draft', label: 'Draft', icon: null };
        if (!updatedSiteMenu.some(menuItem => menuItem.id === 'draft')) {
          updatedSiteMenu.push(draftTab);
        }
      }

      if (JSON.stringify(updatedSiteMenu) !== JSON.stringify(siteMenu)) {
        setSiteMenu(updatedSiteMenu);
      }
      const isActiveMenuValid = updatedSiteMenu.some(menuItem => menuItem.id === activeMenu);

      if (!isActiveMenuValid) {
        if (updatedSiteMenu.some(menuItem => menuItem.id === 'draft')) {
          if (activeMenu !== 'draft') {
            setActiveMenu('draft');
          }
        } else if (updatedSiteMenu.length > 0) {
          if (activeMenu !== updatedSiteMenu[0].id) {
            setActiveMenu(updatedSiteMenu[0].id);
          }
        } else {
          if (activeMenu !== '') {
            setActiveMenu('');
          }
        }
      }
    }
  }, [draftFormData, userSocials, siteMenuInitial, activeMenu, siteMenu]);


  const closeModal = (action) => {
    if (action instanceof Date) {
      const newFormData = formData.map((data) => ({
        ...data,
        schedule: action,
      }));
      setFormData(newFormData)
      newFormDataRef.current = newFormData;
    }

    setOpenModal(false);
    setIsModalScheduleOpen(false);
  };

  useEffect(() => {
    if (newFormDataRef.current) {
      handleActionClick('schedule');
      newFormDataRef.current = null;
    }
  }, [formData]);

  const handleFormDataChange = (platform, newFormData) => {
    setFormData(prevFormData =>
      prevFormData.map(data => {
        if (data.platformId === platform || data.uploadTo === platform) {
          return { ...data, ...newFormData, hasBeenEdited: true };
        } else {
          return data;
        }
      })
    );
  };

  const handleActionClick = (action) => {
    let editedFormData = formData.filter(
      (data) => data.hasBeenEdited
    );

    if (editedFormData.length >= 2) {
      editedFormData = editedFormData.filter((data) => data.uploadTo !== 'draft');
    }

    onFormAction(action, editedFormData);
  };

  const socialCheckboxesActivate = (userSocial) => {
    setUserSocials((prevUserSocials) => {
      // Toggle the checkbox state for the selected social platform
      const updatedUserSocials = prevUserSocials.map((social) => {
        if (social.platform_id === userSocial.platform_id) {
          return { ...social, checkbox: !social.checkbox };
        }
        return social;
      });

      // Update socialChecks state for the toggled platform
      if (userSocial.platform in socialChecks) {
        setSocialChecks((prev) => ({ ...prev, [userSocial.platform]: !userSocial.checkbox }));
      }

      // Update formData based on the checkbox state
      setFormData((prevFormData) => {
        const draftEntry = prevFormData.find(data => data.uploadTo === 'draft');

        if (!userSocial.checkbox) {
          // If the checkbox is now checked, add the platform data back to formData
          const existingData = deactivatedFormData.find(data => data.platformId === userSocial.platform_id);
          if (existingData) {
            const existingObj = prevFormData.find(data => data.platformId === userSocial.platform_id);
            if (!existingObj) {
              prevFormData = [...prevFormData, existingData];
              setDeactivatedFormData((prev) => prev.filter(data => data.platformId !== userSocial.platform_id));
            }
          } else {
            const existingObj = prevFormData.find(data => data.platformId === userSocial.platform_id);
            if (!existingObj) {
              prevFormData = [...prevFormData, {
                uploadTo: userSocial.platform,
                draftId: '',
                postId: uniquePostId,
                title: draftEntry ? draftEntry.title : '',
                description: draftEntry ? draftEntry.description : '',
                uploadType: uploadType,
                privacyStatus: 'public',
                commentEnabled: true,
                isContentDisclosed: false,
                duetEnabled: true,
                stitchEnabled: true,
                isBranded: false,
                isOwnBrand: false,
                hasBeenEdited: false,
                platformId: userSocial.platform_id
              }];
            }
          }
        } else {
          // If the checkbox is now unchecked, remove the platform data from formData
          const dataToDeactivate = prevFormData.find(data => data.uploadTo === userSocial.platform);
          if (dataToDeactivate) {
            setDeactivatedFormData((prev) => [...prev, dataToDeactivate]);
            prevFormData = prevFormData.filter(data => data.platformId !== userSocial.platform_id);
          }
        }

        // Ensure there's always at least one draft entry in formData
        if (prevFormData.length === 0) {
          prevFormData.push({
            uploadTo: 'draft',
            draftId: '',
            postId: uniquePostId,
            title: '',
            description: '',
            uploadType: uploadType,
            privacyStatus: 'public',
            commentEnabled: true,
            isContentDisclosed: false,
            duetEnabled: true,
            stitchEnabled: true,
            isBranded: false,
            isOwnBrand: false,
            hasBeenEdited: false,
            platformId: '',
          });
        }

        // Update postId for all entries in formData
        return prevFormData.map((data) => ({
          ...data,
          postId: uniquePostId,
        }));
      });

      return updatedUserSocials;
    });
  };

  const findPlatformData = (platform) => {
    return formData.find(data => data.platformId === platform) || formData[0];
  };

  const handleErrors = (errors) => {
    setErrorMessages(errors);
  };


  return (
    <>
      <div className='flex flex-col h-full pb-1'>
        <div className='flex flex-col md:flex-row w-full h-full overflow-y-auto'>
          {/* LEFT PANEL */}
          <div className={`p-5 !w-full md:!w-3/5`}>
            <SocialMediaCheckboxes
              userSocials={userSocials}
              socialCheckboxesActivate={socialCheckboxesActivate}
              setOpenModal={setOpenModal}
            />
            <div className="flex flex-col relative mb-2">
              <Tabs tabs={siteMenu} onTabChange={handleSitieMenuChange} activeTab={activeMenu} main={true} />
            </div>

            <div className='w-full md:!w-2/3 mx-auto'>
              <Form
                formData={findPlatformData(activeMenu)}
                onFormDataChange={(newFormData) => handleFormDataChange(activeMenu, newFormData)}
                userSocials={userSocials}
              />

              <div className="py-2 mx-auto">
                <UploadArea onFileSelect={onFileSelect} uploadType={uploadType} />
              </div>

              {socialChecks.tiktok && formData.uploadTo === 'tiktok' && (
                <TikTokPolicy formData={formData} />
              )}
            </div>
          </div>

          {/* RIGHT PANEL */}
          <div className='!w-full md:!w-2/5 border-l p-5'>
            <VideoPreview
              draftVideo={video.firebasePath}
              draftThumb={video.thumbnail}
              onFileSelect={onFileSelect}
              uploadType={uploadType}
              formData={formData}
              formTabs={siteMenu}
              userSocials={userSocials}
              activeMenuForm={activeMenu}
              onError={handleErrors}
            />
          </div>
        </div>

        <ActionButtons
          formData={formData}
          handleActionClick={handleActionClick}
          setIsModalScheduleOpen={setIsModalScheduleOpen}
          socialChecks={socialChecks}
          disableButtons={errorMessages.length > 0}
        />
      </div>


      {/* MODALS */}
      {isModalScheduleOpen && (
        <Modal
          open={isModalScheduleOpen}
          otherInfo={formData[0].schedule ? new Date(formData[0].schedule) : new Date()}
          closeModal={closeModal}
          type={'ScheduledModal'}
        />
      )}
      {openModal && (
        <Modal
          open={openModal}
          closeModal={closeModal}
          type={'ConnectSocialsModal'}
        />
      )}
    </>
  );
};

const SocialMediaCheckboxes = ({ userSocials, socialCheckboxesActivate, setOpenModal }) => (
  <div className={`flex flex-col mb-5`}>
    <div className='flex justify-between'>
      <p className='text-md font-semibold'>Select your socials</p>
      <div className='flex items-center gap-1 border-dashed border-2 border-gray-300 py-2 px-3 transition-all duration-300 hover:border-solid rounded-md cursor-pointer hover:border-blue-300' onClick={() => setOpenModal(true)}>
        <FaPlus size={10} />
        <span className='text-[11px]'>Connect</span>
      </div>
    </div>
    <div className={`flex w-full gap-4 px-1 py-2 overflow-x-auto overflow-y-hidden`}>
      {userSocials.length > 0 && userSocials.map((socialMedia, index) => (
        <div
          key={index}
          className={`${socialMedia.checkbox ? 'opacity-100' : 'opacity-40'} relative transition-transform transform hover:scale-105 hover:opacity-100`}
          onClick={() => socialCheckboxesActivate(socialMedia)}
        >
          <CheckboxBtn
            name={socialMedia.username}
            platform={socialMedia.platform}
            logo={socialMedia.logo}
            active={socialMedia.checkbox}
          />
        </div>
      ))}
    </div>
  </div>
);

const ActionButtons = ({ formData, handleActionClick, setIsModalScheduleOpen, socialChecks, disableButtons }) => {

  return (
    <div className='flex py-3 relative items-end border-t px-5'>
      <div className='flex gap-2 items-end justify-end w-full'>
        <Button
          variant="outline"
          size="xl"
          className="border-b border-gray-200 px-2 text-gray-700"
          onClick={() => handleActionClick('draft')}
        >
          Save Draft
        </Button>
        <Button
          variant="secondary"
          size="xl"
          className={`flex gap-1 items-center ${disableButtons ? 'cursor-not-allowed bg-gray-300' : ''}`}
          onClick={() => {
            if (!disableButtons) {
              setIsModalScheduleOpen(true);
            }
          }}
          disabled={disableButtons}
        >
          {formData[0].schedule ? formatCustomDateTime(formData[0].schedule) : 'Schedule'}
          <FaRegCalendarCheck />
        </Button>
        <Button
          variant="default"
          size="xl"
          className={`flex gap-1 items-center ${disableButtons ? 'cursor-not-allowed bg-gray-300' : ''}`}
          onClick={() => {
            if (!disableButtons) {
              handleActionClick('upload');
            }
          }}
          disabled={disableButtons}
        >
          <span>Publish</span>
          <LuSend />
        </Button>
      </div>

      <div className='relative ml-2'>
        {socialChecks.tiktok && formData.isContentDisclosed === true && !formData.isOwnBrand && !formData.isBranded && (
          <p className="text-red-500 text-[10px] absolute w-[135px]">You need to indicate if your content promotes yourself or a third party</p>
        )}
      </div>
    </div>
  );
};

const TikTokPolicy = ({ formData }) => (
  <div className="text-[11px] mt-2">
    {!formData.isBranded ? (
      <span>* By posting, you agree to TikTok's <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en" target="_blank" rel="noreferrer" style={{ color: "#FE2C55" }}>Music Usage Confirmation</a></span>
    ) : (
      <span>* By posting, you agree to TikTok's <a href="https://www.tiktok.com/legal/page/global/bc-policy/en" target="_blank" rel="noreferrer" style={{ color: "#FE2C55" }}>Brand Content Policy</a> and <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en" target="_blank" rel="noreferrer" style={{ color: "#FE2C55" }}>Music Usage Confirmation</a></span>
    )}
  </div>
);

const Form = ({
  formData,
  onFormDataChange,
  userSocials,
}) => {
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleInputChange = (field, value) => {
    const newFormData = { [field]: value };
    onFormDataChange(newFormData);
  };

  const handleDescriptionChange = (value) => {
    handleInputChange('description', value);
  };

  const toggleDisclosure = () => {
    if (!formData.isContentDisclosed) {
      handleInputChange('isOwnBrand', false);
      handleInputChange('privacyStatus', false);
    }
    handleInputChange('isContentDisclosed', !formData.isContentDisclosed);
  };

  const toggleBranded = () => {
    handleInputChange('isBranded', !formData.isBranded);
    if (!formData.isBranded) {
      handleInputChange('privacyStatus', 'public');
    }
  };

  return (
    <form onClick={(e) => e.stopPropagation()}>
      <div className={``}>
        {(getFileType(formData.uploadType) === 'video') && (formData.uploadTo === 'facebook' || formData.uploadTo === 'youtube') && (
          <label className="form-label pt-2">
            <input
              type="text"
              value={formData.title}
              onChange={(e) => handleInputChange('title', e.target.value)}
              className={`form-input appearance-none outline-none border-none resize-none ${isInputFocused ? 'bg-white' : 'bg-gray-100'
                }`}
              placeholder={`Title (Only for youtube videos and facebook video posts)`}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
            />
          </label>
        )}
        <div className='py-2'>
          <Textarea onDescriptionChange={handleDescriptionChange} initialDescription={formData.description} placeholder={formData.uploadTo === 'youtube' ? 'description' : 'caption'} formData={formData} handlePrivacyStatusChange={handleInputChange} />
        </div>

        <div className='flex w-full gap-5'>
          {getFileType(formData.uploadType) === 'video' && formData.uploadTo === 'facebook' && (
            <label className="flex flex-col font-semibold justify-start items-start mt-5 w-1/2 text-sm font-medium text-gray-900">
              Type:
              <select
                value={formData.uploadType}
                onChange={(e) => handleInputChange('uploadType', e.target.value)}
                className="form-select w-full rounded-lg border-gray-300 text-gray-700 sm:text-sm"
              >
                <option value="video">Video Post</option>
                <option value="reel">Reel</option>
              </select>
            </label>
          )}

          {getFileType(formData.uploadType) === 'video' && formData.uploadTo === 'instagram' && (
            <label className="flex flex-col font-semibold justify-start items-start mt-5 w-1/2 text-sm font-medium text-gray-900">
              Type:
              <select
                value={formData.uploadType}
                onChange={(e) => handleInputChange('uploadType', e.target.value)}
                className="form-select w-full rounded-lg border-gray-300 text-gray-700 sm:text-sm"
              >
                <option value="REELS">Reels</option>
                <option value="STORIES">Story</option>
              </select>
            </label>
          )}
        </div>

        {formData.uploadTo === 'tiktok' && (
          <TikTokSettings
            formData={formData}
            handleInputChange={handleInputChange}
            toggleDisclosure={toggleDisclosure}
            toggleBranded={toggleBranded}
            userSocials={userSocials}
          />
        )}
      </div>
    </form>
  );
};

const TikTokSettings = ({ formData, handleInputChange, toggleDisclosure, toggleBranded, userSocials }) => (
  <div className='flex flex-col py-3 bg-gray-200 rounded-lg px-2'>
    <div className='flex justify-between'>
      <p className='font-semibold mr-3'>Allow users to:</p>
      <div className='group'>
        <Info type="tiktokFeatures" />
      </div>
    </div>

    {userSocials && userSocials.map((item) => (
      item.platform === 'tiktok' && (
        <div className='flex space-x-3' key={item.id}>
          <label className=''>
            <input
              type="checkbox"
              checked={formData.commentEnabled}
              onChange={(e) => handleInputChange('commentEnabled', e.target.checked)}
            />
            Comment
          </label>
          <br />
          <label className={`relative group`}>
            <input
              type="checkbox"
              checked={formData.duetEnabled}
              onChange={(e) => handleInputChange('duetEnabled', e.target.checked)}
            />
            Duet
          </label>
          <br />
          <label className={`relative group`}>
            <input
              type="checkbox"
              checked={formData.stitchEnabled}
              onChange={(e) => handleInputChange('stitchEnabled', e.target.checked)}
              className={`item`}
            />
            <span>Stitch</span>
          </label>
        </div>
      )
    ))}

    <div className='flex flex-col mt-5'>
      <div className='flex relative'>
        <p className='flex-1 font-semibold'>Disclose video content:</p>
        <label className="switch relative">
          <input type="checkbox" onChange={toggleDisclosure} checked={formData.isContentDisclosed} className="sr-only" />
          <div className={`w-12 h-6 rounded-full shadow-md bg-gray-400 ${formData.isContentDisclosed && 'bg-green-500'}`}>
            <div
              className={`indicator w-6 h-6 rounded-full bg-white transform ${formData.isContentDisclosed ? 'translate-x-full' : 'translate-x-0'} transition duration-300 ease-in-out`}
            ></div>
          </div>
        </label>
      </div>
      <div>
        <p className='text-[10px]'>Turn on to disclose that this video promotes goods or services.</p>
      </div>
      {formData.isContentDisclosed && (
        <DisclosureSettings
          formData={formData}
          handleInputChange={handleInputChange}
          toggleBranded={toggleBranded}
        />
      )}
    </div>
  </div>
);

const DisclosureSettings = ({ formData, handleInputChange, toggleBranded }) => (
  <div className='flex flex-col transition duration-300'>
    <div className='flex bg-blue-300 p-2 m-2 rounded-lg text-[12px]'>
      <FaInfoCircle size={20} className='mr-2' />
      <span>Your video will be labeled <span className='underline font-semibold'>Promotional Content</span>. This cannot be changed once your video is posted!</span>
    </div>
    <div className='flex-col w-[80%] mx-auto'>
      <label className="flex">
        <span className='flex-1 font-semibold'>Your Brand</span>
        <input type="checkbox" onChange={(e) => handleInputChange('isOwnBrand', e.target.checked)} checked={formData.isOwnBrand} />
      </label>
      <p className='text-[12px]'>You are promoting yourself or your own business/brand. This video will be classified as <span className='font-semibold underline'>Brand Organic</span></p>
    </div>
    <div className='flex-col w-[80%] mx-auto my-3'>
      <label className="flex">
        <span className='flex-1 font-semibold'>Branded Content</span>
        <input type="checkbox" onChange={toggleBranded} checked={formData.isBranded} />
      </label>
      <p className='text-[12px]'>You are promoting another brand or a third party. This video will be classified as <span className='font-semibold underline'>Branded Content</span>. <span className='text-red-300 underline'>Privacy setting will be set to public!</span></p>
    </div>
    <div>
      <p className='text-[10px]'>* Your photo/video will be labeled as <span className='font-semibold underline'>Paid Partnership</span></p>
    </div>
  </div>
);

export default UploadForm;
