import React, { useState } from 'react';
import axios from 'axios';
import { useWorkspace } from '../../../context/WorkspaceContext.js'; // Import useWorkspace

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const TikTokLoginButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { idToken } = useWorkspace(); // Access idToken

  const handleLoginClick = () => {
    if (!idToken) {
      console.error('idToken is not set');
      return;
    }

    setIsLoading(true);
    axios.get(BACKEND_API_URL + '/api/tiktok/oauth', {
      headers: { Authorization: `Bearer ${idToken}` }
    })
      .then(response => {
        window.open(response.data, '_blank');
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <button className="flex items-center w-full justify-between bg-black h-[70px]" onClick={handleLoginClick} disabled={isLoading}>
      {isLoading ? 'Loading...' : (
        <>
          <div className="mr-3 w-12 h-12 bg-white rounded-full flex items-center justify-center">
            <img
              src={'../socials/tiktok-logo.svg'}
              alt="Instagram Logo"
              className="w-8 h-8"
            />
          </div>
          <p className='button-text-tiktok'>
            {'TikTok'}
          </p>
        </>
      )}
      <div></div>
    </button>
  );
}

export default TikTokLoginButton;