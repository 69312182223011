import React, { useState } from "react";
import Chart from "react-apexcharts";
import { BsThreeDotsVertical } from "react-icons/bs";
import DropdownButton from "../Tools/DropdownButton";
import { FaEye } from "react-icons/fa";

const Heatmap = ({ data, showWeekly }) => {
    const [isWeeklyView, setIsWeeklyView] = useState(showWeekly);

    // Ensure data is always an array
    const postData = Array.isArray(data) ? data : [];

    const heatmapData = isWeeklyView
        ? generateWeeklyHeatmapData(postData)
        : generateHeatmapData(postData);

    const chartOptions = {
        series: heatmapData,
        options: {
            chart: {
                height: 450,
                type: "heatmap",
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                heatmap: {
                    enableShades: false,
                    colorScale: {
                        ranges: [
                            {
                                from: 0,
                                to: 0,
                                color: "#e0e0e0", // Light gray for 0 posts
                                name: "No Posts",
                            },
                            {
                                from: 1,
                                to: 1,
                                color: "#ffeb3b", // Yellow for 1 post
                                name: "1 Post",
                            },
                            {
                                from: 2,
                                to: 2,
                                color: "#ff9800", // Orange for 3 posts
                                name: "2 Posts",
                            },
                            {
                                from: 3,
                                to: 3,
                                color: "#ff5722", // Deep Orange for 4 posts
                                name: "3 Posts",
                            },
                            {
                                from: 4,
                                to: 6,
                                color: "#e91e63", // Pink for 6 posts
                                name: "4-6 Posts",
                            },
                            {
                                from: 7,
                                to: 9,
                                color: "#673ab7", // Deep Purple for 7 posts
                                name: "7-9 Posts",
                            },
                            {
                                from: 10,
                                to: 100,
                                color: "#1a237e", // Dark blue for 10+ posts
                                name: "10+ Posts",
                            },
                        ],
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                type: "category",
                categories: generateDaysArray(isWeeklyView), // Generate categories based on view
            },
            title: {
                text: isWeeklyView
                    ? "Weekly Posting Routine for " +
                    capitalizeFirstLetter(
                        new Date().toLocaleString("default", { month: "long" })
                    )
                    : "6-Month Posting Routine",
                align: "center",
                style: {
                    fontSize: "20px",
                    fontWeight: "600",
                    fontFamily: "Poppins, sans-serif",
                    letterSpacing: "0.5px",
                    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
                    color: "var(--text-color)",
                },
            },
        },
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // Function to toggle between views
    const toggleView = () => {
        setIsWeeklyView((prevView) => !prevView);
    };

    return (
        <div className="relative">
            <div className="absolute top-0 right-0 z-10">
                <DropdownButton
                    label={<BsThreeDotsVertical size={20} />}
                    options={[
                        {
                            label: `${isWeeklyView ? "Show 6-Month View" : "Show Weekly View"
                                }`,
                            onClick: () => toggleView(),
                            icon: <FaEye className="text-blue-600 font-bold" />,
                        },
                    ]}
                />
            </div>
            <div id="chart">
                <Chart
                    options={chartOptions.options}
                    series={chartOptions.series}
                    type={chartOptions.options.chart.type}
                    width={"100%"}
                    height={300}
                />
            </div>
        </div>
    );
};

function generateHeatmapData(postData = []) {
    const postCountsByDate = {};

    if (!Array.isArray(postData)) {
        console.error("postData is not an array in generateHeatmapData", postData);
        return [];
    }

    postData.forEach((post) => {
        if (post.posts && Array.isArray(post.posts)) {
            post.posts.forEach((subPost) => {
                // Convert Firestore timestamp to JavaScript Date
                const date = new Date(subPost.updatedAt._seconds * 1000);
                const formattedDate = date.toISOString().split("T")[0]; // Extract date in YYYY-MM-DD format
                if (postCountsByDate[formattedDate]) {
                    postCountsByDate[formattedDate] += 1; // Increment count if date already exists
                } else {
                    postCountsByDate[formattedDate] = 1; // Initialize count for new date
                }
            });
        }
    });

    const series = [];

    const today = new Date();
    const startMonth = new Date(today.getFullYear(), today.getMonth() - 5, 1); // 5 months before this month
    const endMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // End of the next month

    for (
        let month = new Date(endMonth);
        month >= startMonth;
        month.setMonth(month.getMonth() - 1)
    ) {
        const monthData = [];
        const monthName =
            month.toLocaleString("default", { month: "long" }) +
            " " +
            month.getFullYear();

        const daysInMonth = new Date(
            month.getFullYear(),
            month.getMonth() + 1,
            0
        ).getDate();

        for (let day = 1; day <= daysInMonth; day++) {
            const date = new Date(month.getFullYear(), month.getMonth(), day);
            const formattedDate = date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
            const metricValue = postCountsByDate[formattedDate] || 0; // Use post count if available, otherwise 0

            monthData.push({ x: day.toString(), y: metricValue }); // Use day as x-axis label
        }

        series.push({
            name: monthName,
            data: monthData,
        });
    }

    return series;
}

function generateWeeklyHeatmapData(postData = []) {
    const postCountsByDate = {};

    if (!Array.isArray(postData)) {
        console.error(
            "postData is not an array in generateWeeklyHeatmapData",
            postData
        );
        return [];
    }

    // Count posts by date
    postData.forEach((post) => {
        if (post.posts && Array.isArray(post.posts)) {
            post.posts.forEach((subPost) => {
                // Convert Firestore timestamp to JavaScript Date
                const date = new Date(subPost.updatedAt._seconds * 1000);
                const formattedDate = date.toISOString().split("T")[0];
                if (postCountsByDate[formattedDate]) {
                    postCountsByDate[formattedDate] += 1;
                } else {
                    postCountsByDate[formattedDate] = 1;
                }
            });
        }
    });

    const series = [];
    const today = new Date();
    const month = today.getMonth(); // Get current month (0-based index)
    const year = today.getFullYear(); // Get current year

    const daysInMonth = new Date(year, month + 1, 0).getDate(); // Number of days in the current month

    let currentDay = 1;

    // We calculate the number of weeks by dividing daysInMonth by 7 and adjusting for any extra days
    while (currentDay <= daysInMonth) {
        const startDay = currentDay;
        const endDay = Math.min(currentDay + 6, daysInMonth); // The end day should not exceed the number of days in the month

        const weekData = [];

        // Fill in the actual data for each day in the week
        for (let day = startDay; day <= endDay; day++) {
            const date = new Date(year, month, day);
            const formattedDate = date.toISOString().split("T")[0];
            const metricValue = postCountsByDate[formattedDate] || 0; // Use post count if available, otherwise 0
            weekData.push({ x: day.toString(), y: metricValue });
        }

        // Fill remaining slots with zeros to make the week contain 7 days
        while (weekData.length < 7) {
            weekData.push({ x: "", y: 0 });
        }

        // Push each week as a separate series with the day span like "1-7", "8-14", etc.
        series.push({
            name: `${startDay}-${endDay}`, // Show day span like "1-7", "8-14", etc.
            data: weekData,
        });

        // Move to the next week
        currentDay = endDay + 1;
    }

    // Reverse the series so the last week is displayed first
    return series.reverse();
}

function generateDaysArray(isWeekly) {
    const days = [];

    if (isWeekly) {
        for (let day = 1; day <= 7; day++) {
            days.push(day.toString()); // Convert day to string to match the x-axis format
        }
    } else {
        for (let day = 1; day <= 31; day++) {
            days.push(day.toString()); // Convert day to string to match the x-axis format
        }
    }
    return days;
}

export default Heatmap;
