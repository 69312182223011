// src/routes/WorkspaceRoutes.js
import React, { useState, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useWorkspace } from "./context/WorkspaceContext";
import { isMobile as checkIsMobile, handleResize, debounce } from './utils/utils';

// Import pages
import Dashboard from "./pages/Protected/Dashboard/Dashboard";
import Upload from "./pages/Protected/Content/Upload/Upload";
import Schedule from "./pages/Protected/Schedule/Schedule";
import Templates from "./pages/Protected/Templates/Templates";
import History from "./pages/Protected/History/History";
import Socials from "./pages/Protected/Socials/Socials";
import Settings from "./pages/Protected/Settings/Settings";
import Support from "./pages/Protected/Support/Support";

import Instagram from "./pages/Protected/Callbacks/Instagram";
import Google from "./pages/Protected/Callbacks/Google";
import Tiktok from "./pages/Protected/Callbacks/Tiktok";
import Facebook from "./pages/Protected/Callbacks/Facebook";
import X from "./pages/Protected/Callbacks/X";

// Special pages
import NoWorkspace from "./pages/Protected/NoWorkspace/NoWorkspace";

import LoadingIndicator from "./components/Tools/LoadingIndicator";
import VerticalNavbar from "./components/Navigation/VerticalNavbar/VerticalNavbar";
import HorizontalMobileNavbar from "./components/Navigation/HorizontalMobileNavbar/HorizontalMobileNavbar";
import TopVerticalBar from "./components/Navigation/TopVerticalBar/TopVerticalBar";

function WorkspaceRoutes() {
    const [isMobile, setIsMobile] = useState(checkIsMobile());

    useEffect(() => {
        const debouncedHandleResize = debounce(() => handleResize(setIsMobile), 100);
        window.addEventListener("resize", debouncedHandleResize);
        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, []);

    const {
        loading,
        workspaces,
    } = useWorkspace();

    if (loading) {
        return <LoadingIndicator showLogo={true} className="h-screen w-screen" />;
    }

    // Render navbars based on userLoggedIn and isMobile
    return (
        <div className={`flex ${isMobile && 'flex-col'} min-h-screen w-full`}>
            {!isMobile && <VerticalNavbar />}

            <div className="flex flex-col flex-1">
                <TopVerticalBar />

                {/* Existing routing logic */}
                {workspaces.length === 0 ? (
                    <Routes>
                        <Route path="/dashboard/no-workspace" element={<NoWorkspace />} />
                        <Route path="*" element={<Navigate to="/dashboard/no-workspace" />} />
                    </Routes>
                ) : (
                    <Routes>
                        <Route path="/" element={<Navigate to="/dashboard" />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/dashboard/upload" element={<Upload />} />
                        <Route path="/dashboard/schedule" element={<Schedule />} />
                        <Route path="/dashboard/templates" element={<Templates />} />
                        <Route path="/dashboard/posts" element={<History />} />
                        <Route path="/dashboard/socials" element={<Socials />} />
                        <Route path="/dashboard/settings" element={<Settings />} />
                        <Route path="/dashboard/support" element={<Support />} />

                        {/* Callbacks */}
                        <Route path="/callback/instagram" element={<Instagram />} />
                        <Route path="/callback/google" element={<Google />} />
                        <Route path="/callback/tiktok" element={<Tiktok />} />
                        <Route path="/callback/facebook" element={<Facebook />} />
                        <Route path="/callback/x" element={<X />} />

                        {/* Anything else not matched => go to dashboard */}
                        <Route path="*" element={<Navigate to="/dashboard" />} />
                    </Routes>
                )}
            </div>

            {isMobile && <HorizontalMobileNavbar />}
        </div>
    );
}

export default WorkspaceRoutes;
