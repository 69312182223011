import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FaCheck } from 'react-icons/fa';
import { Modal } from '../Modal/Modal';
import { useWorkspace } from '../../context/WorkspaceContext';


const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Guide = ({ userSettings }) => {
    const [completed, setCompleted] = useState([
        { id: 'socials', completed: false },          // index 1
        { id: 'video', completed: false },            // index 2
        { id: 'schedule', completed: false },         // index 3
        { id: 'templates', completed: false },        // index 4
        { id: 'settings', completed: false } // index 5
    ]);

    const STEP_BOXES = [
        {
            index: 1,
            link: '/dashboard/socials',
            icon: '/onboarding/guide/connect.svg',
            title: 'Connect Socials',
            description: 'Connect and manage all your socials...'
        },
        {
            index: 2,
            link: '/dashboard/upload',
            icon: '/onboarding/guide/upload.svg',
            title: 'Upload a Video',
            description: 'Start sharing your content in a flick...'
        },
        {
            index: 3,
            link: '/dashboard/schedule',
            icon: '/onboarding/guide/schedule.svg',
            title: 'Schedule A Video',
            description: 'Schedule your videos to post exactly when you want...'
        },
        {
            index: 4,
            link: '/dashboard/templates',
            icon: '/onboarding/guide/templates.svg',
            title: 'Create Templates',
            description: 'Create and reuse your own hashtag or caption templates...'
        },
        {
            index: 5,
            link: '/dashboard/settings',
            icon: '/onboarding/guide/dashboard.png',
            title: 'Configure Dashboard Settings',
            description: 'Customize your dashboard settings...'
        }
    ];

    const [completedSteps, setCompletedSteps] = useState(0);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [userHidedGuide, setUserHidedGuide] = useState(false);

    const { activeWorkspace, idToken } = useWorkspace();
    const hasRunOnceRef = useRef(false);

    // Count how many steps are completed (all 6 in `STEPS`, including index 0 for onboarding)
    useEffect(() => {
        const count = completed.reduce((acc, step) => (step.completed ? acc + 1 : acc), 0);
        setCompletedSteps(count);
    }, [completed]);

    // On mount (or when idToken changes), run checks once
    useEffect(() => {
        if (!hasRunOnceRef.current && idToken) {
            hasRunOnceRef.current = true;
            (async () => {
                try {
                    await checkUserOnboarding();
                    await checkSocials();
                    await checkDrafts();
                    await checkTemplates();
                    // You can also add a check for "dashboardSettings" here if desired.
                } catch (error) {
                    console.error('Failed to check user steps:', error);
                }
            })();
        }
    }, [idToken]);

    // -------------- Step Check Functions ---------------

    const checkUserOnboarding = async () => {
        // If user completed entire onboarding flow
        if (userSettings?.onboarding?.completed) {
            setCompleted(prev => {
                const updated = [...prev];
                updated[0].completed = true; // index 0 => 'onboarding'
                return updated;
            });
        }
    };

    const checkSocials = async () => {
        if (!idToken) return;
        try {
            const response = await axios.get(`${BACKEND_API_URL}/api/auth/user/social_media_tokens`, {
                params: { workspaceId: activeWorkspace.id },
                headers: { Authorization: `Bearer ${idToken}` },
            });
            if (response.data.length > 0) {
                setCompleted(prev => {
                    const updated = [...prev];
                    updated[1].completed = true; // index 1 => 'socials'
                    return updated;
                });
            }
        } catch (error) {
            console.error('Error fetching social media tokens:', error);
        }
    };

    const checkDrafts = async () => {
        if (!idToken) return;
        try {
            const response = await axios.get(`${BACKEND_API_URL}/api/auth/user/drafts`, {
                params: { workspaceId: activeWorkspace.id },
                headers: { Authorization: `Bearer ${idToken}` },
            });
            const hasUploadedDraft = response.data.some(draft => draft.status === 'uploaded');
            const hasScheduledDraft = response.data.some(draft => draft.status === 'schedule');

            setCompleted(prev => {
                const updated = [...prev];
                if (hasUploadedDraft) updated[2].completed = true;  // index 2 => 'video'
                if (hasScheduledDraft) updated[3].completed = true; // index 3 => 'schedule'
                return updated;
            });
        } catch (error) {
            console.log(error);
        }
    };

    const checkTemplates = async () => {
        try {
            const response = await axios.get(`${BACKEND_API_URL}/api/auth/user/templates`, {
                params: { workspaceId: activeWorkspace.id },
                headers: { Authorization: `Bearer ${idToken}` },
            });
            if (response.data.length > 0) {
                setCompleted(prev => {
                    const updated = [...prev];
                    updated[4].completed = true; // index 4 => 'templates'
                    return updated;
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    // -------------- UI Helpers ---------------

    const calculateProgress = () => {
        // We have 6 steps total in STEPS (0..5). 
        // If you only want to measure the 5 visible steps, adjust accordingly.
        const totalSteps = completed.length; // => 6
        return ((completedSteps / totalSteps) * 100).toFixed(0);
    };

    const closeModal = (confirm) => {
        setShowConfirmationModal(false);
        if (confirm) {
            // The user wants to hide the guide permanently
            setUserHidedGuide(true);
            // Optionally, you could persist this setting to the backend.
        }
    };

    // -------------- RENDER ---------------

    if (userHidedGuide) {
        return null; // If user has hidden the guide, render nothing
    }

    return (
        <div className="flex flex-col dashboard-section">
            <div className="flex flex-col gap-2 mb-2">
                <div className="flex justify-between">
                    <h2 className="border-b-2 border-blue-500 w-max">Explore SocialFlick</h2>
                    <p
                        className="text-[10px] italic text-red-500 hover:underline cursor-pointer"
                        onClick={() => setShowConfirmationModal(true)}
                    >
                        Hide this guide
                    </p>
                </div>

                <div className="flex justify-between">
                    <p className="italic text-sm font-light mb-1">
                        Welcome to SocialFlick. Ready to skyrocket your social media presence?
                        <span className="not-italic text-lg ml-1">🚀</span>
                    </p>

                    <div className="flex flex-col w-[200px]">
                        <div className="text-xs font-semibold text-teal-600">
                            Step {completedSteps}/{completed.length}
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="flex-grow h-2 bg-teal-200 rounded-full overflow-hidden">
                                <div
                                    style={{ width: `${calculateProgress()}%` }}
                                    className="h-full bg-teal-500 transition-all duration-500 ease-in-out"
                                />
                            </div>
                            <div className="text-xs font-semibold text-teal-600">
                                {calculateProgress()}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Steps Row */}
            <div className="flex overflow-auto gap-2 justify-between overflow-hidden">
                {STEP_BOXES.map((step, idx) => {
                    const stepDone = completed[step.index]?.completed;
                    return (
                        <React.Fragment key={step.index}>
                            <Link
                                to={step.link}
                                className={`--theme-colors box w-[15%] min-w-[200px] border hover:border-blue-500 flex flex-col relative ${stepDone ? 'opacity-40 hover:opacity-100' : ''
                                    } cursor-pointer`}
                            >
                                <div className="w-[100px] mx-auto py-2">
                                    <img
                                        src={`${process.env.PUBLIC_URL}${step.icon}`}
                                        alt={step.title}
                                    />
                                </div>
                                <div className="p-3 flex-1">
                                    <p className="font-semibold">{step.title}</p>
                                    <p className="text-[11px] font-light">{step.description}</p>
                                </div>
                                {stepDone && (
                                    <div className="absolute right-[-8px] top-[-7px]">
                                        <FaCheck className="text-green-500" size={25} />
                                    </div>
                                )}
                            </Link>

                            {/* Show arrow between steps, except after the last box */}
                            {idx < STEP_BOXES.length - 1 && (
                                <div className="self-center w-[30px]">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/random/next.png`}
                                        alt="next"
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>

            {/* Confirmation Modal */}
            {showConfirmationModal && (
                <Modal open={showConfirmationModal} type="ConfirmHideGuide" closeModal={closeModal} />
            )}
        </div>
    );
};

export default Guide;
