// RadioButtons.js

import React from 'react';

const RadioButtons = ({ viewMode, setViewMode }) => {
    return (
        <fieldset className="flex p-1 bg-blue-500 rounded-xl">
            <label
                htmlFor="viewModeMonth"
                className={`block cursor-pointer p-3 text-sm font-medium shadow-sm hover:border-gray-200 ${viewMode === 'month'
                    && 'bg-blue-700 ring-1 rounded-lg'
                    }`}
            >
                <input
                    type="radio"
                    name="viewMode"
                    value="month"
                    id="viewModeMonth"
                    className="sr-only"
                    checked={viewMode === 'month'}
                    onChange={() => setViewMode('month')}
                />

                <div className="flex flex-col items-center">
                    <p className="text-white">Monthly</p>
                </div>
            </label>

            <label
                htmlFor="viewModeWeek"
                className={`block cursor-pointer  p-3 text-sm font-medium shadow-sm hover:border-gray-200 ${viewMode === 'week'
                    && 'bg-blue-700 ring-1 rounded-lg'
                    }`}
            >
                <input
                    type="radio"
                    name="viewMode"
                    value="week"
                    id="viewModeWeek"
                    className="sr-only"
                    checked={viewMode === 'week'}
                    onChange={() => setViewMode('week')}
                />

                <div className="flex flex-col items-center">
                    <p className="text-white">Weekly</p>
                </div>
            </label>


            <label
                htmlFor="viewModeList"
                className={`block cursor-pointer  p-3 text-sm font-medium shadow-sm hover:border-gray-200 ${viewMode === 'list'
                    && 'bg-blue-700 ring-1 rounded-lg'
                    }`}
            >
                <input
                    type="radio"
                    name="viewMode"
                    value="list"
                    id="viewModeList"
                    className="sr-only"
                    checked={viewMode === 'list'}
                    onChange={() => setViewMode('list')}
                />

                <div className="flex flex-col items-center">
                    <p className="text-white">List</p>
                </div>
            </label>
        </fieldset>
    );
};

export default RadioButtons;
