import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { MdCloudUpload } from 'react-icons/md';
import { BsInstagram, BsYoutube, BsTiktok, BsFacebook, BsThreeDotsVertical, BsTwitterX, BsClock } from 'react-icons/bs';
import { RiDeleteBinLine, RiPencilLine } from 'react-icons/ri';
import Modal from '../Modal/Modal';
import DropdownButton from '../Tools/DropdownButton';
import { formatCustomDateTime } from '../../utils/date';
import { useWorkspace } from '../../context/WorkspaceContext';
import axios from 'axios';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Week = ({ week, userUploads, showHistory, showScheduled, refreshUserUploads, todos, showTodo }) => {
    return (
        <div className="flex-1 overflow-x-auto">
            <div className="grid grid-cols-7 min-w-[700px] h-full">
                {week.map((day, idx) => (
                    <Day
                        day={day}
                        key={idx}
                        userUploads={userUploads}
                        showHistory={showHistory}
                        showScheduled={showScheduled}
                        refreshUserUploads={refreshUserUploads}
                        todos={todos}
                        showTodo={showTodo}
                    />
                ))}
            </div>
        </div>
    );
};
export default Week;

const Day = ({ day, userUploads, todos, showHistory, showScheduled, refreshUserUploads, showTodo }) => {
    const { idToken, activeWorkspace } = useWorkspace();
    const [showModal, setShowModal] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [clickedData, setClickedData] = useState({
        date: null,
        draft: null,
    });
    const [deletedPost, setDeletedPost] = useState(null);
    const [weeklyUploads, setWeeklyUploads] = useState([]);
    const [matchingTodos, setMatchingTodos] = useState([]);
    const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true);

    useEffect(() => {
        if (userUploads && userUploads.length > 0) {
            const matchingUploadsArray = [];
            userUploads.forEach((upload) => {
                const post = upload.posts[0];
                const uploadDate = post.schedule ? new Date(post.schedule) : new Date(post.updatedAt._seconds * 1000);
                const dayDate = new Date(day.$d);
                if (
                    uploadDate.getFullYear() === dayDate.getFullYear() &&
                    uploadDate.getMonth() === dayDate.getMonth() &&
                    uploadDate.getDate() === dayDate.getDate()
                ) {
                    matchingUploadsArray.push(upload);
                }
            });
            setWeeklyUploads(matchingUploadsArray);
        }
    }, [userUploads, day]);

    useEffect(() => {
        if (todos) {
            const matchingTodosArray = todos.filter(todo => {
                if (todo.date) {
                    const todoDate = new Date(todo.date);
                    return todoDate.getDate() === day.date();
                }
                return false;
            });
            setMatchingTodos(matchingTodosArray);
        }
    }, [todos, day]);

    useEffect(() => {
        if (idToken && activeWorkspace) {
            setIsCheckboxDisabled(false);
        }
    }, [idToken, activeWorkspace]);

    const handleTodoToggle = async (id, completed) => {
        if (!idToken) return;
        setIsCheckboxDisabled(true);
        const updatedTodos = [...todos];
        const index = updatedTodos.findIndex(item => item.id === id);
        if (index !== -1) {
            updatedTodos[index].completed = !completed;
            try {
                await axios.post(
                    BACKEND_API_URL + '/api/auth/user/todo',
                    { id: updatedTodos[index].id, todo: updatedTodos[index].todo, workspaceId: activeWorkspace.id, date: updatedTodos[index].date },
                    { headers: { Authorization: `Bearer ${idToken}` } }
                );
                setMatchingTodos(updatedTodos);
            } catch (error) {
                console.log(error);
            }
            setIsCheckboxDisabled(false);
        } else {
            console.error(`Todo with id ${id} not found.`);
        }
    };

    const getCurrentDayClass = () => {
        return day.isSame(dayjs(), 'day') ? 'text-white font-semibold rounded-full px-2 bg-blue-500' : '';
    };

    const isPastDay = () => {
        return day.isBefore(dayjs(), 'day');
    };

    const closeModal = () => {
        setShowModal(false);

        if (isDeleteModalOpen === true) {
            setDeleteModalOpen(false);
            refreshUserUploads();
        }

        if (showModal === true) {
            setShowModal(false);
            refreshUserUploads();
        }
    };

    const IconForSocialMedia = ({ socialMedia }) => {
        switch (socialMedia) {
            case 'youtube':
                return <BsYoutube color="red" size={13} />;
            case 'instagram':
                return <BsInstagram color="#C13584" size={13} />;
            case 'tiktok':
                return <BsTiktok color="black" size={13} />;
            case 'facebook':
                return <BsFacebook color="blue" size={13} />;
            case 'x':
                return <BsTwitterX color="black" size={13} />;
            default:
                return null;
        }
    };

    const handleUploadButtonClick = () => {
        setShowModal(true);
        setClickedData({ date: day.toDate() });
    };

    const handleEditButtonClick = (draft) => {
        setClickedData({ draft });
        setShowModal(true);
    };

    const handleDeleteButtonClick = (upload) => {
        setDeleteModalOpen(true);
        setDeletedPost(upload);
    };

    const handleDuplicateButtonClick = (upload) => {
        const duplicateData = { ...upload };
        delete duplicateData.post_id;
        duplicateData.posts = duplicateData.posts.map(post => {
            const newPost = { ...post };
            delete newPost.post_id;
            return newPost;
        });
        setClickedData({ draft: duplicateData });
        setShowModal(true);
    };

    return (
        <div className={`relative border border-gray-200 flex flex-col h-full ${isPastDay() && !showModal && !isDeleteModalOpen
            ? 'opacity-30 bg-gray-200 border-gray-300 shadow-inner'
            : 'opacity-100 --theme-colors shadow-sm lg:hover:border-gray-300'
            }`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <header className="flex flex-col items-center my-1 py-3">
                <p className={`text-sm text-center ${getCurrentDayClass()}`}>{day.format('ddd DD')}</p>
            </header>

            {/* Main container for all uploads of the day */}
            <div className="flex-1 flex flex-col overflow-y-auto">
                {weeklyUploads.length > 0 && (
                    <>
                        {weeklyUploads.map((upload, index) => (
                            <div key={`${index}`} className={`flex border border-gray-200 flex-col w-[95%] min-h-[80px] max-h-[80px] hover:border-gray-500 mx-auto rounded-lg mb-2 cursor-pointer --theme-colors ${upload?.status === 'scheduled' && !showScheduled && 'hidden'} ${upload?.status === 'uploaded' && !showHistory && 'hidden'}`}>
                                {/* Header Row */}
                                <div className={`flex justify-between items-center p-1 rounded-t-md ${upload?.status === 'scheduled' ? 'bg-yellow-300' : 'bg-green-300'}`}>
                                    <div className='flex items-center'>
                                        <BsClock color='black' size={16} className='mr-2' />
                                        {upload?.posts[0]?.title && (
                                            <p className="text-xs font-bold truncate ">{upload?.posts[0]?.title}</p>
                                        )}
                                    </div>
                                    <DropdownButton label={<BsThreeDotsVertical size={18} color='black' />} classes="!p-0 hover:bg-none" options={[
                                        { label: `Edit`, onClick: () => handleEditButtonClick(upload), icon: <RiPencilLine size={20} className="ml-5 text-gray-600 font-bold" /> },
                                        { label: `Delete`, onClick: () => handleDeleteButtonClick(upload), icon: <RiDeleteBinLine size={20} className="ml-5 text-gray-600 font-bold" /> },
                                        { label: `Duplicate`, onClick: () => handleDuplicateButtonClick(upload), icon: <RiPencilLine size={20} className="ml-5 text-gray-600 font-bold" /> },
                                    ]} />
                                </div>
                                {/* Middle Row */}
                                <div className='flex-1 p-1'>
                                    {upload?.posts[0]?.description && (
                                        <p className="text-xs truncate">{upload?.posts[0]?.description}</p>
                                    )}
                                </div>
                                {/* Bottom Row */}
                                <div className='flex justify-start items-center px-1'>
                                    <div className='flex items-center flex-1 gap-2'>
                                        {upload?.posts.map((post, i) => (
                                            <IconForSocialMedia socialMedia={post.uploadTo} key={i} />
                                        ))}
                                    </div>
                                    <span className='text-xs text-gray-500 ml-2 self-end'>
                                        {upload?.posts[0]?.schedule ? formatCustomDateTime(upload.posts[0].schedule) : formatCustomDateTime(upload.posts[0].updatedAt._seconds * 1000)}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </>
                )}
                {matchingTodos && showTodo && matchingTodos.map((todo, index) => (
                    <div key={`todo-${index}`} className="flex items-center justify-between p-1 bg-blue-100 rounded-md mb-1">
                        <span className={`text-xs ${todo.completed ? 'line-through text-green-600' : 'text-gray-700'} flex-1`}>{todo.todo}</span>
                        <input
                            type="checkbox"
                            checked={todo.completed}
                            onChange={() => handleTodoToggle(todo.id, todo.completed)}
                            className="mr-2 self-center"
                            disabled={isCheckboxDisabled}
                        />
                    </div>
                ))}
            </div>

            {isHovered && !isPastDay() && (
                <div
                    className="flex border-t border-gray-300 cursor-pointer text-[12px] justify-center items-center hover:text-blue-500 absolute bottom-0 bg-white w-full py-1"
                    onClick={handleUploadButtonClick}
                >
                    Upload <MdCloudUpload size={20} color="black" className="ml-2" />
                </div>
            )}

            {showModal && (
                <Modal
                    open={showModal}
                    closeModal={closeModal}
                    type={'VideoUploaderModal'}
                    otherInfo={clickedData}
                />
            )}
            {isDeleteModalOpen && (
                <Modal
                    open={isDeleteModalOpen}
                    closeModal={closeModal}
                    type={'VerifyDelete'}
                    otherInfo={deletedPost}
                />
            )}
        </div>
    );
};