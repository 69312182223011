import React from 'react';
import AuthForm from '../../components/Auth/AuthForm';
import { Link } from 'react-router-dom';
import './Auth.css';
import useLocalStorage from 'use-local-storage';

const SignupPage = ({ checkLoginStatus }) => {
  const [theme] = useLocalStorage("theme", "light");

  const logoSrc = theme === 'light' ? './logo/logo-vertical-black.png' : './logo/logo-vertical.png';

  return (
    <div className={`flex flex-col min-h-screen --theme-colors ${theme === 'light' ? 'background-gradient' : 'dark-background-gradient'}`}>
      <div className="w-full px-5 py-3">
        <Link to="https://socialflick.io">
          <img src={logoSrc} className="w-[200px]" alt="logo" />
        </Link>
      </div>

      <div className='flex flex-col md:flex-row lg:flex-row items-center justify-center flex-1 gap-5 lg:gap-x-5'>
        <AuthForm isLogin={false} checkLoginStatus={checkLoginStatus} />
      </div>
    </div>
  );
}

export default SignupPage;