import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoadingIndicator from '../../../components/Tools/LoadingIndicator';
import { useWorkspace } from '../../../context/WorkspaceContext.js';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Tiktok = () => {
    const hasRunOnceRef = useRef(false);
    const navigate = useNavigate();
    const { idToken, activeWorkspace } = useWorkspace();
    const broadcastChannel = new BroadcastChannel('socialsChannel');

    useEffect(() => {
        if (!hasRunOnceRef.current && idToken) {
            hasRunOnceRef.current = true;

            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');

            if (code) {
                (async () => {
                    await tiktokGenerateAccessToken(code);
                })();

                async function tiktokGenerateAccessToken(code) {
                    try {
                        await axios.post(BACKEND_API_URL + '/api/tiktok/generate_access_token', { code, workspaceId: activeWorkspace.id }, {
                            headers: { Authorization: `Bearer ${idToken}` }
                        });
                        broadcastChannel.postMessage('callbackLogin');
                        window.close();
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }
    }, [navigate, idToken]);

    return (
        <div className='flex justify-center items-center w-full h-full'>
            <LoadingIndicator />
        </div>
    );
}

export default Tiktok;