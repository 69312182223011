import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import LoadingIndicator from '../../../components/Tools/LoadingIndicator';
import { useWorkspace } from '../../../context/WorkspaceContext.js';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Facebook = () => {
    const hasRunOnceRef = useRef(false);
    const { idToken, activeWorkspace } = useWorkspace();

    useEffect(() => {
        if (!hasRunOnceRef.current && idToken) {
            hasRunOnceRef.current = true;
            const broadcastChannel = new BroadcastChannel('socialsChannel');

            const url = window.location.hash;
            const queryString = url.split('#')[1];
            const params = new URLSearchParams(queryString);
            const paramsObject = {};
            params.forEach((value, key) => {
                paramsObject[key] = value;
            });
            paramsObject.workspaceId = activeWorkspace.id;

            if (paramsObject) {
                (async () => {
                    await facebookGenerateAccessToken(paramsObject);
                })();

                async function facebookGenerateAccessToken(paramsObject) {
                    try {
                        const response = await axios.post(BACKEND_API_URL + '/api/facebook/generate_access_token', paramsObject, {
                            headers: { Authorization: `Bearer ${idToken}` }
                        });
                        broadcastChannel.postMessage('callbackLogin');
                        window.close();
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }
    }, [idToken]);

    return (
        <div className='flex justify-center items-center w-full h-full'>
            <LoadingIndicator />
        </div>
    );
}

export default Facebook;