import React, { useState } from 'react';
// import './toggle.css'; // Remove this line as we will use Tailwind CSS

const Toggle = ({ initial = false, onToggle }) => {
    const [isOn, setIsOn] = useState(initial);

    const handleToggle = () => {
        setIsOn(!isOn);
        if (onToggle) onToggle(!isOn);
    };

    return (
        <div
            className={`w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer transition-colors duration-300 ${isOn ? 'bg-green-500' : 'bg-gray-300'}`}
            onClick={handleToggle}
        >
            <div
                className={`bg-white w-5 h-5 rounded-full shadow-md transform transition-transform duration-300 ${isOn ? 'translate-x-6' : 'translate-x-0'}`}
            />
        </div>
    );
};

export default Toggle;
