// CalendarHeader.js

import React from 'react';
import dayjs from 'dayjs';
import { LuRefreshCcw } from 'react-icons/lu';
import RadioButtons from '../Tools/Radio/RadioButtons';
import { formatCustomDate } from '../../utils/date';
import { IoSettings } from 'react-icons/io5';
import Toggle from '../../ui/toggle';
import { GrFormPrevious, GrFormNext } from 'react-icons/gr';
import { Button } from '../../ui/button';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
const CalendarHeader = ({
    viewMode,
    setViewMode,
    monthIndex,
    handlePrevMonth,
    handleNextMonth,
    handleReset,
    weekStartDate,
    handlePrevWeek,
    handleNextWeek,
    showHistory,
    showScheduled,
    setShowHistory,
    setShowScheduled,
    showTodo,
    setShowTodo,
}) => {
    const currentMonthIndex = dayjs().month();
    const isCurrentWeek = weekStartDate.isSame(dayjs().startOf('week'), 'day');
    const navigate = useNavigate();

    return (
        <header className="flex flex-col md:flex-row justify-between md:items-center dashboard-section">
            <div className="flex justify-center sm:justify-start mb-2 sm:mb-0 flex-1">
                <RadioButtons viewMode={viewMode} setViewMode={setViewMode} />
            </div>

            {/* Date and Reset Button */}
            <div className="flex items-center justify-center sm:justify-end gap-2">
                {viewMode === 'month' && (
                    <h2 className="text-xl sm:text-2xl md:text-4xl text-gray-500 font-medium">
                        {dayjs(new Date(dayjs().year(), monthIndex)).format('MMMM YYYY')}
                    </h2>
                )}
                {viewMode === 'week' && (
                    <h2 className="text-xl sm:text-2xl md:text-3xl text-gray-500 font-medium">
                        {formatCustomDate(weekStartDate)} -{' '}
                        {weekStartDate.endOf('week').format('DD / MM')}
                    </h2>
                )}

                {viewMode === 'list' && (
                    <h2 className="text-xl sm:text-2xl md:text-3xl text-gray-500 font-medium">
                        All uploads
                    </h2>
                )}

                {/* Reset Button */}
                {((viewMode === 'month' && monthIndex !== currentMonthIndex) ||
                    (viewMode === 'week' && !isCurrentWeek)) && (
                        <div onClick={handleReset} className="cursor-pointer">
                            <LuRefreshCcw className="text-blue-300" size={24} />
                        </div>
                    )}
            </div>

            {/* Second Row: Show/Hide Items and Navigation Arrows */}
            <div className="flex items-center justify-center md:justify-end gap-5 w-full mt-2 flex-1">
                <Button variant='default' size='lg' className='flex items-center gap-2' onClick={() => {
                    navigate('/dashboard/upload');
                }}>
                    <IoIosAddCircleOutline size={25} />
                    New post
                </Button>
                {/* Show/Hide Items Dropdown */}
                <div className="flex justify-center sm:justify-start mb-2 sm:mb-0">
                    <ShowHideDropdown
                        showHistory={showHistory}
                        showScheduled={showScheduled}
                        setShowHistory={setShowHistory}
                        setShowScheduled={setShowScheduled}
                        showTodo={showTodo}
                        setShowTodo={setShowTodo}
                    />
                </div>

                {/* Navigation Arrows */}
                <div className="flex justify-center sm:justify-end">
                    {viewMode === 'month' ? (
                        <>
                            <button
                                onClick={handlePrevMonth}
                                className="bg-blue-200 border-none cursor-pointer p-2 focus:outline-none mx-1 rounded shadow-sm"
                            >
                                <span className="cursor-pointer text-gray-600"><GrFormPrevious size={25} /></span>
                            </button>
                            <button
                                onClick={handleNextMonth}
                                className="bg-blue-200 border-none cursor-pointer p-2 focus:outline-none mx-1 rounded shadow-sm"
                            >
                                <span className="text-gray-600"><GrFormNext size={25} /></span>
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                onClick={handlePrevWeek}
                                className="bg-blue-200 border-none cursor-pointer p-2 focus:outline-none mx-1 rounded shadow-sm"
                            >
                                <span className="cursor-pointer text-gray-600"><GrFormPrevious size={25} /></span>
                            </button>
                            <button
                                onClick={handleNextWeek}
                                className="bg-blue-200 border-none cursor-pointer p-2 focus:outline-none mx-1 rounded shadow-sm"
                            >
                                <span className="text-gray-600"><GrFormNext size={25} /></span>
                            </button>
                        </>
                    )}
                </div>
            </div>
        </header>
    );
};

export default CalendarHeader;


const ShowHideDropdown = ({
    showHistory,
    showScheduled,
    setShowHistory,
    setShowScheduled,
    showTodo,
    setShowTodo,
}) => {
    return (
        <div className="relative flex items-center flex-col">
            <div className="flex">
                <div className="relative">
                    <details className="group [&_summary::-webkit-details-marker]:hidden">
                        <summary className="flex cursor-pointer items-center gap-2 text-gray-900 transition hover:border-gray-600">
                            <span className="text-sm font-medium border border-gray-200 rounded-md p-2 shadow-sm"> <IoSettings size={25} className='--theme-colors' /></span>
                        </summary>

                        <div className="z-50 group-open:absolute group-open:right-0 group-open:top-auto group-open:mt-2 ">
                            <div className="w-max rounded border border-gray-200 --theme-colors">
                                <ul className="space-y-1 border-t border-gray-200 p-4">
                                    <li>
                                        <label className="inline-flex items-center gap-2">
                                            <Toggle initial={showHistory} onToggle={() => setShowHistory((prev) => !prev)} />
                                            <span className="text-sm font-medium text-gray-700 bg-green-300">
                                                Show previous uploads?
                                            </span>
                                        </label>
                                    </li>

                                    <li>
                                        <label className="inline-flex items-center gap-2">
                                            <Toggle initial={showScheduled} onToggle={() => setShowScheduled((prev) => !prev)} />
                                            <span className="text-sm font-medium text-gray-700 bg-yellow-300">
                                                Show scheduled uploads?
                                            </span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="inline-flex items-center gap-2">
                                            <Toggle initial={showTodo} onToggle={() => setShowTodo((prev) => !prev)} />
                                            <span className="text-sm font-medium text-gray-700 bg-blue-300">
                                                Show Todo?
                                            </span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </details>
                </div>
            </div>
        </div>
    );
};
