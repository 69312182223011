import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import useLocalStorage from 'use-local-storage';
import { auth } from '../firebase';

const WorkspaceContext = createContext();

export const useWorkspace = () => useContext(WorkspaceContext);

export const WorkspaceProvider = ({ children }) => {
    const [workspaces, setWorkspaces] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeWorkspace, setActiveWorkspace] = useLocalStorage('activeWorkspace', '');
    const [idToken, setIdToken] = useState(null);
    const [user, setUser] = useState(null);
    const [firestoreUser, setFirestoreUser] = useState(null);

    const fetchWorkspaces = async () => {

        try {
            const res = await axios.get(process.env.REACT_APP_BACKEND_API_URL + '/api/auth/user/workspaces', {
                headers: { Authorization: `Bearer ${idToken}` },
            });
            setWorkspaces(res.data);
            setError(null);
        } catch (err) {
            console.error("Error fetching workspaces:", err.response ? err.response.data : err.message);
            setError('Failed to load workspaces');
        } finally {
            setLoading(false);
        }
    };

    const changeActiveWorkspace = (workspace) => {
        setActiveWorkspace(workspace);
    };

    const fetchFirestoreUser = async () => {
        if (!idToken) {
            console.error("No ID token available");
            return;
        }

        try {
            const res = await axios.get(process.env.REACT_APP_BACKEND_API_URL + '/api/auth/user/getUser', {
                headers: { Authorization: `Bearer ${idToken}` },
            });

            if (res.data.docExists) {
                setFirestoreUser(res.data.user);
            } else {
                setFirestoreUser(null);
            }
        } catch (err) {
            console.error("Error fetching Firestore user:", err.response ? err.response.data : err.message);
            setFirestoreUser(null);
        }
    };

    useEffect(() => {
        if (idToken) {
            fetchWorkspaces();
            fetchFirestoreUser();
        }
    }, [idToken]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
            if (currentUser) {
                const token = await currentUser.getIdToken();
                setIdToken(token);
                setUser(currentUser);
            } else {
                setIdToken(null);
                setUser(null);
                setActiveWorkspace('');
                setWorkspaces([]);
            }
        });

        if (!workspaces.includes(activeWorkspace) && workspaces.length > 0) {
            setActiveWorkspace(workspaces[0]);
        }

        return () => unsubscribe();
    }, [activeWorkspace, workspaces]);

    return (
        <WorkspaceContext.Provider value={{ workspaces, loading, error, activeWorkspace, fetchWorkspaces, changeActiveWorkspace, idToken, user, firestoreUser }}>
            {children}
        </WorkspaceContext.Provider>
    );
};