import { useState, useEffect, useRef } from "react";
import axios from 'axios'; // Import Axios
import { RiPencilLine, RiDeleteBinLine } from 'react-icons/ri';
import { BsInstagram, BsYoutube, BsTiktok, BsFacebook, BsThreeDotsVertical, BsTwitterX } from 'react-icons/bs';
import { isMobile as checkIsMobile, handleResize, debounce } from '../../utils/utils'; // Import utility functions
import { getFileType } from "../../utils/getFileType";
import Pagination from "../Tools/Pagination";
import { Modal } from "../Modal/Modal";
import ContentLoader from 'react-content-loader';
import { formatCustomDateTime } from "../../utils/date";
import DropdownButton from "../Tools/DropdownButton";
import { useWorkspace } from '../../context/WorkspaceContext';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Drafts = ({ onEditDraft }) => {
    const [drafts, setDrafts] = useState([]);
    const [activeTab, setActiveTab] = useState('draft'); // Initialize the active tab
    const [currentPage, setCurrentPage] = useState(1);
    const [tabs, setTabs] = useState([
        { id: 'draft', label: 'Drafts', count: { length: 0, color: 'bg-blue-500' } },
        { id: 'scheduled', label: 'Scheduled', count: { length: 0, color: 'bg-yellow-500' } },
        { id: 'uploaded', label: 'History' },
    ]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [modalGroup, setModalGroup] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const hasRunOnceRef = useRef(false);
    const [isMobile, setIsMobile] = useState(checkIsMobile());
    const { activeWorkspace, idToken } = useWorkspace();

    useEffect(() => {
        const debouncedHandleResize = debounce(() => handleResize(setIsMobile), 100);
        window.addEventListener("resize", debouncedHandleResize);
        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, [isMobile]);

    useEffect(() => {
        const getDrafts = async () => {
            if (!idToken) return;
            try {
                const response = await axios.get(BACKEND_API_URL + '/api/auth/user/drafts', {
                    params: { workspaceId: activeWorkspace.id },
                    headers: { Authorization: `Bearer ${idToken}` },
                });
                const arr = response.data;
                setDrafts(arr);
                await updateTabCount(arr);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        if (!hasRunOnceRef.current && isDeleteModalOpen === false) {
            hasRunOnceRef.current = true;
            getDrafts();
        }

        if (isDeleteModalOpen === true) {
            hasRunOnceRef.current = false;
        }
    }, [isDeleteModalOpen, activeWorkspace, idToken]);

    const updateTabCount = async (mergedData) => {
        if (mergedData.length <= 0) return;

        const tabCounts = {
            draft: 0,
            scheduled: 0,
            failed: 0,
        };
        mergedData.forEach(group => {
            if (group.status === 'draft') {
                tabCounts.draft++;
            } else if (group.status === 'failed') {
                tabCounts.failed++;
            }
            else if (group.status === 'scheduled') {
                tabCounts.scheduled++;
            }
        });

        const hasFailedDrafts = tabCounts.failed > 0;

        setTabs(prevTabs => {
            const updatedTabs = [...prevTabs];

            if (!hasFailedDrafts) {
                setActiveTab('draft');
            } else {
                setActiveTab('failed');
                // Check if the "Failed" tab doesn't exist, add it as the first index
                if (!updatedTabs.find(tab => tab.id === 'failed')) {
                    updatedTabs.unshift({ id: 'failed', label: 'Failed', count: { length: 0, color: 'bg-red-500' } });
                }
            }

            return updatedTabs.map(tab => {
                if (tab.id in tabCounts) {
                    return { ...tab, count: { ...tab.count, length: tabCounts[tab.id] } };
                }
                return tab;
            });
        });
    };

    const handleTabChange = (newTab) => {
        setActiveTab(newTab); // Update the active tab when a tab is changed
        setCurrentPage(1); // Reset current page when tab changes
    };

    const paginate = () => {
        if (!drafts || drafts.length <= 0) return;
        const itemsPerPage = 3;
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;

        // Filter and sort drafts based on the latest updatedAt of their posts
        let sortedDrafts = drafts
            .map(group => {
                const latestPost = group.posts.reduce((latest, post) => {
                    return new Date(post.updatedAt) > new Date(latest.updatedAt) ? post : latest;
                }, group.posts[0]);
                return { ...group, latestUpdatedAt: new Date(latestPost.updatedAt) };
            })
            .sort((a, b) => b.latestUpdatedAt - a.latestUpdatedAt);

        // Filter drafts based on the active tab
        let filteredDrafts = sortedDrafts.map(group => ({
            ...group,
            posts: group.posts.filter(post => post.status === activeTab)
        })).filter(group => group.posts.length > 0);

        const maxPage = Math.ceil(filteredDrafts.length / itemsPerPage);

        const f = filteredDrafts.filter(post => post.status === activeTab);
        const currentItems = f.slice(indexOfFirstItem, indexOfLastItem);

        return { currentItems, maxPage };
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= paginate().maxPage) {
            setCurrentPage(newPage);
        }
    };

    const [currentItems, setCurrentItems] = useState([]);
    const [maxPage, setMaxPage] = useState(1);
    useEffect(() => {
        if (drafts && drafts.length > 0) {
            const { currentItems, maxPage } = paginate();
            setCurrentItems(currentItems);
            setMaxPage(maxPage);
        } else {
            setCurrentItems([]);
            setMaxPage(1);
        }
    }, [drafts, activeTab, currentPage]);

    const handleDeleteButtonClick = (group) => {
        setModalGroup(group);
        setIsDeleteModalOpen(true);
    };

    const closeModal = () => {
        setModalGroup(null);
        setIsDeleteModalOpen(false);
    };

    return (
        <div className="">
            {/* Tabs */}
            <div className="grid w-full grid-cols-4 mb-4">
                {
                    tabs.map((tab) => (
                        <button
                            key={tab.id}
                            onClick={() => handleTabChange(tab.id)}
                            className={`flex items-center justify-center gap-2 py-2 px-4 text-sm font-medium rounded-md 
                ${activeTab === tab.id ? 'bg-primary text-white' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
                        >
                            {getIconForTab(tab.id)}
                            <span>{tab.label}</span>
                            {tab.count && (
                                <span
                                    className={`px-2 rounded-full text-xs ${tab.count.color} text-white`}
                                >
                                    {tab.count.length}
                                </span>
                            )}
                        </button>
                    ))
                }
            </div >

            {/* Tab Content */}
            <div>
                {
                    isLoading ? (
                        <Loader />
                    ) : (
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200 text-sm text-left">
                                <thead>
                                    <tr>
                                        <th className="px-4 py-2 font-medium">Status</th>
                                        <th className="px-4 py-2 font-medium">Content</th>
                                        {['scheduled', 'uploaded', 'failed'].includes(activeTab) && (
                                            <th className="px-4 py-2 font-medium">Date</th>
                                        )}
                                        <th className="px-4 py-2 font-medium">Media</th>
                                        <th className="px-4 py-2 font-medium">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {currentItems && currentItems.length > 0 ? (
                                        currentItems.map((group) => (
                                            <TableRow
                                                key={group.post_id}
                                                group={group}
                                                onEditDraft={onEditDraft}
                                                handleDeleteButtonClick={handleDeleteButtonClick}
                                                activeTab={activeTab}
                                            />
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="text-center py-4">
                                                You have no {activeTab} posts yet...
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
            </div >

            {/* Pagination */}
            {
                maxPage > 1 && (
                    <div className="flex justify-end mt-4">
                        <Pagination
                            currentPage={currentPage}
                            maxPage={maxPage}
                            onPageChange={handlePageChange}
                        />
                    </div>
                )
            }

            {
                isDeleteModalOpen && (
                    <Modal open={isDeleteModalOpen} closeModal={closeModal} type={'VerifyDelete'} otherInfo={modalGroup} />
                )
            }
        </div >
    );
};

const TableRow = ({ group, onEditDraft, handleDeleteButtonClick, activeTab }) => {
    const post = group.posts[0];

    return (
        <tr>
            <td className="px-4 py-2">
                <div className="flex items-center gap-2">
                    {group.posts.map((groupPost, index) => (
                        <div key={index} className="flex gap-1">
                            <IconForSocialMedia socialMedia={groupPost.uploadTo} />
                        </div>
                    ))}
                    <span
                        className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium uppercase 
              ${post.status === 'failed' ? 'bg-red-100 text-red-800' :
                                post.status === 'draft' ? 'bg-blue-100 text-blue-800' :
                                    post.status === 'scheduled' ? 'bg-yellow-100 text-yellow-800' :
                                        'bg-green-100 text-green-800'}`}
                    >
                        {post.status}
                    </span>
                </div>
            </td>
            <td className="px-4 py-2">
                <p className="font-semibold">{post.title}</p>
                {post.description?.length > 300 ? post.description.substring(0, 200) + "..." : post.description}
            </td>
            {['scheduled', 'uploaded', 'failed'].includes(activeTab) && (
                <td className="px-4 py-2">
                    <div className="text-sm">
                        {post.status === 'scheduled' && (
                            <p>{formatCustomDateTime(new Date(post.schedule))}</p>
                        )}
                        {post.status === 'uploaded' && (
                            <p>{formatCustomDateTime(new Date(post.updatedAt))}</p>
                        )}
                        {post.status === 'failed' && (
                            <p>{formatCustomDateTime(new Date(post.updatedAt._seconds * 1000))}</p>
                        )}
                    </div>
                </td>
            )}
            <td className="px-4 py-2">
                <div className="w-16 h-16 rounded-lg overflow-hidden bg-gray-100">
                    <img
                        src={
                            getFileType(post.uploadType) === 'video'
                                ? post.thumbnail && post.thumbnail !== '' ? post.thumbnail : `${process.env.PUBLIC_URL}/no-thumbnail.png`
                                : post.firebase_path && post.firebase_path !== '' ? post.firebase_path : `${process.env.PUBLIC_URL}/no-thumbnail.png`
                        }
                        alt={post.title}
                        className="w-full h-full object-cover"
                    />
                </div>
            </td>
            <td className="px-4 py-2">
                <div className="flex space-x-2">
                    <DropdownButton label={<BsThreeDotsVertical size={18} />} classes="!p-0 hover:bg-none" options={[
                        { label: `Edit`, onClick: () => onEditDraft(group), icon: <RiPencilLine size={20} className="ml-5 text-blue-600 font-bold" /> },
                        ...(post.status !== 'uploaded'
                            ? [
                                {
                                    label: `Delete`,
                                    onClick: () => handleDeleteButtonClick(group),
                                    icon: <RiDeleteBinLine size={20} className="ml-5 text-blue-600 font-bold" />,
                                },
                            ]
                            : []),
                    ]} />
                </div>
            </td>
        </tr>
    );
};

const Loader = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={84}
        viewBox="0 0 100 84"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="15" y="15" rx="3" ry="3" width="120" height="12" />
        <rect x="15" y="40" rx="3" ry="3" width="400" height="12" />
        <rect x="15" y="65" rx="3" ry="3" width="80" height="12" />
    </ContentLoader>
);

const IconForSocialMedia = ({ socialMedia }) => {
    const social = socialMedia.socialMedia || socialMedia;
    switch (social) {
        case 'youtube':
            return <BsYoutube color='red' size={13} />;
        case 'instagram':
            return <BsInstagram color='#C13584' size={13} />;
        case 'tiktok':
            return <BsTiktok color='black' size={13} />;
        case 'facebook':
            return <BsFacebook color='blue' size={13} />;
        case 'x':
            return <BsTwitterX color='black' size={13} />;
        default:
            return null; // Handle other cases or return a default icon
    }
};

const getIconForTab = (tabId) => {
    switch (tabId) {
        case 'failed':
            return <RiDeleteBinLine className="w-4 h-4" />;
        case 'draft':
            return <RiPencilLine className="w-4 h-4" />;
        case 'scheduled':
            return <RiPencilLine className="w-4 h-4" />;
        case 'uploaded':
            return <RiPencilLine className="w-4 h-4" />;
        default:
            return null;
    }
};

export default Drafts;
