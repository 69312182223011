import React, { useState, useEffect } from 'react';
import { auth, sendPasswordResetEmail } from '../../firebase';

const ResetPasswordUnauth = ({ closeModal, email }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [emailInput, setEmailInput] = useState(email ? email : '');
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true);
    const [feedback, setFeedback] = useState('');

    const sendResetPasswordEmail = async () => {
        if (isLoading || isConfirmButtonDisabled) return;
        setIsLoading(true);

        try {
            await sendPasswordResetEmail(auth, emailInput);
            setFeedback('A password reset link has been sent to your email!');
            setIsConfirmButtonDisabled(true);
        } catch (error) {
            setFeedback('Error: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    };

    useEffect(() => {
        const isValid = validateEmail(emailInput);
        setIsConfirmButtonDisabled(!isValid || isLoading);
    }, [emailInput, isLoading]);

    return (
        <div className="fixed flex items-center justify-center modal-overlay"
            onClick={(e) => { if (e.target === e.currentTarget) closeModal(); }}>
            <div className="--theme-colors px-4 py-5 sm:p-6 rounded-md items-center justify-center flex-column w-[500px] relative">
                <div
                    onClick={closeModal}
                    className="absolute top-2 right-2 font-bold text-2xl cursor-pointer text-gray-500 hover:text-gray-700 focus:outline-none"
                    aria-label="Close"
                >
                    &times;
                </div>
                <h2 className="text-2xl font-semibold text-gray-900 mb-4 text-center">
                    RESET YOUR PASSWORD
                </h2>
                <p className="text-gray-700 mb-4 text-center">
                    To enhance your account security, we will send you an email containing a verification code to reset your password.
                </p>
                <div className='text-center'>
                    <input
                        type="email"
                        value={emailInput}
                        onChange={(e) => { setEmailInput(e.target.value); }}
                        placeholder="Enter your email"
                        className="bg-gray-100 rounded-full py-2 px-4 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-400 mr-5"
                    />
                    {isLoading ? (
                        <div className="loading-animation"></div>
                    ) : (
                        <button
                            onClick={sendResetPasswordEmail}
                            className={`bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 ${isConfirmButtonDisabled ? 'bg-gray-300 cursor-not-allowed' : ''}`}
                            disabled={isConfirmButtonDisabled || isLoading}
                        >
                            Confirm
                        </button>
                    )}
                    {feedback && <p className={feedback.includes('Error') ? '--error-message' : '--success-message'}>{feedback}</p>}
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordUnauth;
