import React, { useState } from 'react';
import './LinkUpload.css';
import axios from 'axios';
import LoadingIndicator from '../../Tools/LoadingIndicator';
import { useWorkspace } from '../../../context/WorkspaceContext';
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const LinkUpload = ({ onVideoPath }) => {
    const [isUploading, setIsUploading] = useState(false);
    const [link, setLink] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { idToken } = useWorkspace();
    const handleUploadURLClick = () => {
        setErrorMessage(''); // Clear any previous error message

        // Validate the link before proceeding
        if (!isValidURL(link)) {
            setErrorMessage('Please enter a valid URL...');
            return;
        }

        if (link.includes('tiktok.com') || link.includes('instagram.com')) {
            setErrorMessage('Downloads from this website are not possible, use another platform to download the video.');
            return;
        }

        setIsUploading(true);
        let endpoint;

        if (link.includes('youtube.com') || link.includes('youtu.be')) {
            endpoint = `${BACKEND_API_URL}/services/upload/link/youtube`;
        } else {
            endpoint = `${BACKEND_API_URL}/services/upload/link/general`;
        }

        axios.post(endpoint, { link }, { headers: { Authorization: `Bearer ${idToken}` }, timeout: 600000 })
            .then(response => {
                setIsUploading(false);
                const local = extractLocalPath(response.data.url);
                onVideoPath(response.data.url, local);
            })
            .catch(error => {
                setIsUploading(false);
                setErrorMessage(error.response?.data?.message || 'An error occurred during upload.');
                console.error(error);
            });
    }

    const handleInputChange = (event) => {
        setLink(event.target.value);
    }

    const isValidURL = (string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    }

    const extractLocalPath = (url) => {
        const urlObj = new URL(url);
        const pathname = urlObj.pathname;
        const pathParts = pathname.split('/');
        const folder = pathParts[2]; // Extract the folder name
        const fileName = pathParts[3]; // Extract the file name
        return `${folder}/${fileName}`;
    }

    return (
        <div className="container w-[650px] max-w-[85%] mx-auto">
            <div className="webflow-style-input">
                <input type="text" placeholder="Enter your link..." onChange={handleInputChange}></input>
                <button type="button" className="linkUploadBtn uppercase" onClick={handleUploadURLClick} disabled={isUploading}>
                    {isUploading ? <LoadingIndicator showLogo={false} showText={false} size={6} /> : 'Upload'}
                </button>
            </div>
            <div>
                <p className='text-[10px] italic'>* Some websites block download requests, find other ways to download the content (tiktok, instagram etc)...</p>
            </div>

            <div className='flex justify-center items-center pt-10'>
                {isUploading && <LoadingIndicator showLogo={false} showText={true} textType='download' size={24} />}
            </div>

            {errorMessage && (
                <div className="error-message">
                    <div className='w-[200px] mx-auto opacity-80 animate-pulse'>
                        <img src={`${process.env.PUBLIC_URL}/random/no-video.png`} alt='Failed to download the video' />
                    </div>
                    <p className='w-[70%] mx-auto text-center italic text-red-500 text-sm'>{errorMessage}</p>
                </div>
            )}
        </div>
    );
}

export default LinkUpload;
