import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './AuthForm.css';
import { auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, googleProvider, signInWithPopup } from '../../firebase'; // Import the auth instance
import CustomInput from '../Tools/Input/CustomInput.js';
import { FiUser } from 'react-icons/fi';
import { MdOutlineMailOutline } from 'react-icons/md';
import { RiLockPasswordLine } from 'react-icons/ri';
import { Button } from '../../ui/button.js';
import axios from 'axios'; // Import axios for making HTTP requests
import { render } from '@react-email/components';
import Welcome from '../../emails/Welcome.tsx';
import SendEmail from '../Tools/SendEmail'
import useLocalStorage from 'use-local-storage';
import { getFirebaseErrorMessage } from '../../utils/firebase.js';
import { FcGoogle } from 'react-icons/fc';


const AuthForm = ({ isLogin, sendOpenModal }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [activeWorkspace, setActiveWorkspace] = useLocalStorage('activeWorkspace');

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });
  const { name, email, password } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAuthentication = async (formData, isLogin) => {
    try {
      //LOGIN
      if (isLogin) {
        await signInWithEmailAndPassword(auth, formData.email, formData.password);
      } else {
        //SIGNUP
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          formData.email,
          formData.password
        );
        const user = userCredential.user;

        // Call the backend register endpoint
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/auth/register`,
          {
            username: formData.name,
            email: formData.email,
            password: formData.password,
            uid: user.uid, // Pass the Firebase UID to the backend
          }
        );
        if (response.status === 201) {
          setActiveWorkspace(response.data.workspace);
          const emailHtml = render(<Welcome username={formData.name} link={response.data.link} />);
          await SendEmail('Welcome to SocialFlick – Your Journey Begins Here 🚀', emailHtml, formData.email);
          navigate('/dashboard');
        }
      }
    } catch (error) {
      console.log(error);
      let errorMessage = 'Something went wrong. Please try again later.';
      if (error.code) {
        errorMessage = getFirebaseErrorMessage(error.code);
      }
      else if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      }
      setError(errorMessage);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !password) {
      return;
    }
    handleAuthentication(formData, isLogin);
  };

  // Google Sign In
  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      // Check if user is new
      const isNewUser = result._tokenResponse?.isNewUser;

      if (isNewUser) {
        // Register in backend
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/auth/register`,
          {
            username: user.displayName || '',
            email: user.email,
            password: null,  // not needed for social sign-in
            uid: user.uid,
          }
        );

        if (response.status === 201) {
          setActiveWorkspace(response.data.workspace);
          // Possibly send welcome email
          const emailHtml = render(
            <Welcome username={user.displayName || ''} />
          );
          await SendEmail(
            'Welcome to SocialFlick – Your Journey Begins Here 🚀',
            emailHtml,
            user.email
          );
          navigate('/dashboard');
        }
      }
    } catch (error) {
      let errorMessage = 'Something went wrong. Please try again later.';
      if (error.code) {
        errorMessage = getFirebaseErrorMessage(error.code);
      } else if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      }
      setError(errorMessage);
    }
  };

  return (
    <div className="flex flex-col --theme-colors md:flex-row justify-between gap-5 items-center rounded-xl border border-gray-200 shadow-sm">
      <div className='flex-col p-5'>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto w-[70px]"
            src={`${isLogin ? '../icons/login.png' : '../icons/signup.png'}`}
            alt="logo"
          />
          <h2 className="pt-5 text-center text-2xl font-semibold leading-9 tracking-tight tracking-wide">
            {isLogin ? 'Login to your account' : 'Signup'}
          </h2>

          <p className="text-center text-md text-gray-500 leading-9 tracking-tight tracking-wide">
            {isLogin ? 'Welcome back, nice to see you again!' : 'Start your journey with us!'}
          </p>
        </div>

        <div className='border-b border-gray-200 my-5 mx-5'></div>

        <div className="px-5 flex flex-col gap-2 w-[400px] max-w-[400px]">
          <form className="space-y-4" onSubmit={handleSubmit}>
            {!isLogin && (
              <div>
                <label htmlFor="email" className="block text-sm font-medium">
                  Username *
                </label>
                <CustomInput
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  placeholder="Type your username"
                  Icon={FiUser}
                />
              </div>
            )}
            <div>
              <label htmlFor="email" className="block text-sm font-medium">
                Email *
              </label>
              <div className="mt-2">
                <CustomInput
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  placeholder="Type your email"
                  Icon={MdOutlineMailOutline}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6">
                  Password *
                </label>
              </div>
              <CustomInput
                type="password"
                name="password"
                value={password}
                onChange={handleChange}
                placeholder="Type your password"
                Icon={RiLockPasswordLine}
              />
            </div>
            {isLogin && (
              <p className="text-sm text-right font-light text-indigo-600 hover:text-indigo-500 cursor-pointer" onClick={(e) => sendOpenModal(email)}>
                Forgot password?
              </p>
            )}

            <div className="pt-5 w-full flex flex-col gap-2 items-center">
              <Button variant={'primary'} size='lg' className='w-full'>
                {isLogin ? 'Log in' : 'Sign up →'}
              </Button>

              <span className='text-center text-sm text-gray-500 text-center w-full'>
                or
              </span>

              {/* Google Sign-In Button */}
              <Button
                variant={'outline'}
                size='lg'
                className='w-full flex items-center justify-center'
                onClick={handleGoogleSignIn}
                style={{ border: '1px solid #E0E0E0' }}
              >
                <FcGoogle className='mr-2' />
                {isLogin ? 'Login with Google' : 'Sign up with Google'}
              </Button>
            </div>
          </form>
          {error &&
            <div className="--error-message">
              <p className="text-red-600">{error}</p>
            </div>
          }

          {isLogin ? (
            <p className="mt-6 text-center text-sm text-gray-500">
              New to SocialFlick?{' '}
              <Link to="/signup" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                Sign up for free
              </Link>
            </p>
          ) : (
            <p className="mt-6 text-center text-sm text-gray-500">
              Already have an account?{' '}
              <Link to="/login" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                Log in
              </Link>
            </p>
          )}

        </div>
      </div>
    </div>
  );
};

export default AuthForm;