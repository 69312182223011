import React, { useEffect, useRef, useState } from 'react';
import Tabs from '../../Navigation/MenuTabs/Tabs';
import { FaBookmark, FaComment, FaEye, FaHeart, FaRegBookmark, FaUpload, FaWhatsapp } from 'react-icons/fa';
import { LuSend } from "react-icons/lu";
import { CiImageOn, CiWarning } from "react-icons/ci";
import ThumbnailSelector from '../Thumbnail/Thumbnailselector';
import { getFileType } from '../../../utils/getFileType';
import { MdError, MdVerified, MdWarning } from 'react-icons/md';
import { GoDotFill } from 'react-icons/go';
import { IoEllipsisHorizontal } from 'react-icons/io5';
import { AiFillLike } from 'react-icons/ai';
import { MdPublic, MdLock, MdVisibilityOff } from 'react-icons/md';
import { BiRepost, BiSolidBarChartAlt2 } from 'react-icons/bi';

const VideoPreview = ({ draftVideo, draftThumb, onFileSelect, uploadType, formData, formTabs, userSocials, activeMenuForm, onError }) => {
  const videoRef = useRef(null);
  const [activeMenu, setActiveMenu] = useState('preview');
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [firebasePath, setFirebasePath] = useState(draftVideo);
  const [firebaseThumbnail, setFirebaseThumbnail] = useState(draftThumb);
  const [isModalOpen, setModalOpen] = useState(false);
  const [activePreviewSocialMenu, setActivePreviewSocialMenu] = useState(activeMenuForm);
  const [previewTabs, setPreviewTabs] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [warningMessages, setWarningMessages] = useState([]);

  const draftVideoRef = useRef(draftVideo);
  const draftThumbRef = useRef(draftThumb);

  const siteMenu = [
    { id: 'preview', label: 'Preview', icon: <FaEye /> },
    ...(firebasePath && uploadType === 'video' ? [{ id: 'thumbnail', label: 'Thumbnail', icon: <CiImageOn /> }] : []),
    //{ id: 'comment', label: 'Comments', icon: <FaComment /> },
  ];

  useEffect(() => {
    if (draftVideoRef.current !== draftVideo) {
      setFirebasePath(draftVideo);
      draftVideoRef.current = draftVideo;
    }
  }, [draftVideo]);

  useEffect(() => {
    if (draftThumbRef.current !== draftThumb) {
      setFirebaseThumbnail(draftThumb);
      draftThumbRef.current = draftThumb;
    }
  }, [draftThumb]);

  useEffect(() => {
    const draftTab = { id: "draft", label: "Draft", icon: null };
    const hasDraftUpload = formData.some(data => data.uploadTo === 'draft');
    const updatedFormTabs = hasDraftUpload && !formTabs.some(tab => tab.id === "draft")
      ? [draftTab, ...formTabs]
      : formTabs;

    setPreviewTabs(updatedFormTabs);
  }, [formTabs, activePreviewSocialMenu, formData]);

  useEffect(() => {
    setActivePreviewSocialMenu(activeMenuForm);
  }, [activeMenuForm]);

  const handleSiteMenuChange = (newTab) => {
    setActiveMenu(newTab);
  };

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  const handleThumbnailSelect = (img) => {
    setFirebaseThumbnail(img.imageUrl);
    onFileSelect(firebasePath, img.imageUrl)
  };

  const toggleModal = () => setModalOpen(!isModalOpen);

  const handlePreviewSocialMenuChange = (newTab) => {
    setActivePreviewSocialMenu(newTab);
  };

  const activeFormData = formData.find(data => data.platformId === activePreviewSocialMenu || data.uploadTo === activePreviewSocialMenu);
  const activeSocial = userSocials.find(social => social.platform_id === activePreviewSocialMenu);

  useEffect(() => {
    const errors = [];
    const warnings = [];
    const platformsRequiringMedia = ['instagram', 'youtube', 'tiktok'];
    const platformsWithCheckbox = userSocials.filter(social => platformsRequiringMedia.includes(social.platform) && social.checkbox === true);

    // Check if no userSocials have checkbox set to true
    const noActiveSocials = userSocials.every(social => !social.checkbox);
    if (noActiveSocials) {
      errors.push({
        message: 'Activate at least one social to be able to publish or schedule.',
        icon: MdError,
        color: 'red'
      });
    }

    // Check for platforms missing descriptions, excluding drafts
    const platformsMissingDescription = formData
      .filter(data => !data.description && data.uploadTo !== 'draft')
      .map(data => data.uploadTo);

    if (platformsMissingDescription.length > 0) {
      const platformNames = platformsMissingDescription.join(', ');
      warnings.push({
        message: `The following platforms are missing descriptions: ${platformNames}.`,
        icon: CiWarning,
        color: 'yellow'
      });
    }

    if (getFileType(uploadType) === 'video') {
      const platformsNeedingVideo = platformsWithCheckbox;
      if (platformsNeedingVideo.length > 0 && !firebasePath) {
        const platformNames = platformsNeedingVideo.map(social => social.platform.charAt(0).toUpperCase() + social.platform.slice(1)).join(', ');
        errors.push({
          message: `${platformNames} require a video!`,
          icon: MdError,
          color: 'red'
        });
      }
    }

    if (getFileType(uploadType) === 'image') {
      const instagramSocial = userSocials.find(social => social.platform === 'instagram' && social.checkbox === true);
      if (instagramSocial && !firebasePath) {
        errors.push({
          message: `Instagram requires an image!`,
          icon: MdError,
          color: 'red'
        });
      }
    }
    setErrorMessages(errors);
    setWarningMessages(warnings);
    onError(errors);
  }, [firebasePath, userSocials, formData, uploadType]);

  const renderPost = () => {
    switch (activeSocial?.platform) {
      case 'instagram':
        return <InstagramPost activeSocial={activeSocial} activeFormData={activeFormData} firebasePath={firebasePath} videoRef={videoRef} handleVideoLoad={handleVideoLoad} toggleModal={toggleModal} uploadType={uploadType} />;
      case 'facebook':
        return <FacebookPost activeSocial={activeSocial} activeFormData={activeFormData} firebasePath={firebasePath} videoRef={videoRef} handleVideoLoad={handleVideoLoad} toggleModal={toggleModal} uploadType={uploadType} />;
      case 'tiktok':
        return <TiktokPost activeSocial={activeSocial} activeFormData={activeFormData} firebasePath={firebasePath} videoRef={videoRef} handleVideoLoad={handleVideoLoad} toggleModal={toggleModal} uploadType={uploadType} />;
      case 'x':
        return <TwitterPost activeSocial={activeSocial} activeFormData={activeFormData} firebasePath={firebasePath} videoRef={videoRef} handleVideoLoad={handleVideoLoad} toggleModal={toggleModal} uploadType={uploadType} />;
      default:
        return <DefaultPost activeSocial={activeSocial} activeFormData={activeFormData} firebasePath={firebasePath} videoRef={videoRef} handleVideoLoad={handleVideoLoad} toggleModal={toggleModal} uploadType={uploadType} />;
    }
  };

  return (
    <div className="video-preview h-full flex flex-col">
      <div>
        <Tabs tabs={siteMenu} onTabChange={handleSiteMenuChange} activeTab={activeMenu} main={true} />
      </div>

      {activeMenu === 'preview' && (
        <div className={`relative flex flex-col justify-center py-4`}>
          <p className='text-center text-xl font-semibold'>Social Media Post Preview</p>
          {formData.length <= 1 ? (
            <p className='text-center text-sm italic min-h-[2rem]'>
              At the left panel 👈 select your social media platform and start crafting your uploads or continue working on your draft.
            </p>
          ) : (
            <p className='text-center text-sm italic min-h-[2rem]'>&nbsp;</p>
          )}
          <div className='flex flex-col'>
            {(errorMessages.length > 0 || warningMessages.length > 0 || activeFormData?.uploadTo === 'draft') && (
              <GlobalAlert messages={[
                ...errorMessages,
                ...warningMessages,
                ...(activeFormData?.uploadTo === 'draft' ? [{
                  message: 'This is your draft. It wont be uploaded to your socials, only to SocialFlick.',
                  icon: CiWarning,
                  color: 'yellow'
                }] : [])
              ]} />
            )}
          </div>
          <Tabs tabs={previewTabs} onTabChange={handlePreviewSocialMenuChange} activeTab={activePreviewSocialMenu} main={true} />
          <div className='mt-2 w-3/4 mx-auto'>
            {renderPost()}
          </div>
        </div>
      )}

      {firebasePath && uploadType === 'video' && (
        <div>
          <ThumbnailSelector videoName={firebasePath} onThumbnailSelect={handleThumbnailSelect} draftThumbnail={firebaseThumbnail} isVisible={activeMenu === 'thumbnail' && uploadType === 'video'} />
        </div>
      )}

      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
          onClick={toggleModal}
        >
          <img
            src={firebasePath}
            alt="enlarged media"
            style={{ maxWidth: '90%', maxHeight: '90%' }}
          />
        </div>
      )}
    </div>
  );
};

export default VideoPreview;



const InstagramPost = ({ activeSocial, activeFormData, firebasePath, videoRef, handleVideoLoad, toggleModal, uploadType }) => {
  const handleImageError = (event, platform) => {
    event.target.src = `../socials/${platform}-logo.svg`;
  };

  const getVisibilityIcon = () => {
    switch (activeFormData?.privacyStatus) {
      case 'public':
        return <MdPublic size={18} />;
      case 'private':
        return <MdLock size={18} />;
      case 'unlisted':
        return <MdVisibilityOff size={18} />;
      default:
        return <MdPublic size={18} />;
    }
  };

  return (
    <div>
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="flex items-center p-4 border-b">
          <img
            src={activeSocial?.logo || ``}
            alt={activeSocial?.username}
            className="w-10 h-10 rounded-full"
            onError={(e) => handleImageError(e, activeSocial.platform)}
          />
          <div className="ml-3 flex gap-2 items-center w-full">
            <p className="font-semibold text-md">{activeSocial.username}</p>
            <MdVerified color='blue' />
            <GoDotFill size={10} />
            <p className="text-sm text-gray-500 flex-1">3 d</p>
            <IoEllipsisHorizontal />
            {getVisibilityIcon()}
          </div>
        </div>
        <div className="post-content max-w-[300px] sm:w-[300px] mx-auto">
          {firebasePath && (
            <>
              {getFileType(uploadType) === 'video' ? (
                <video
                  ref={videoRef}
                  onLoadedMetadata={handleVideoLoad}
                  controls
                  preload="auto"
                  className={`rounded-md`}
                >
                  <source src={firebasePath} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={firebasePath}
                  className='cursor-pointer'
                  alt="uploaded media"
                  onClick={toggleModal} // Open modal when clicked
                />)}

            </>
          )}
        </div>
        <div className="flex gap-2 p-4 border-t w-full">
          <div className="text-gray-500"><FaHeart /></div>
          <div className="text-gray-500"><FaComment /></div>
          <div className="text-gray-500 flex-1"><LuSend /></div>
          <div className="text-gray-500"><FaRegBookmark /></div>
        </div>
        <div className="p-4">
          <p className='text-sm font-semibold'>156 likes</p>
          <div className='flex gap-2 items-start'>
            <p className='text-sm font-semibold'>{activeSocial.username}</p>
            <MdVerified color='blue' />
            <p className="text-sm">{activeFormData?.description ? styleHashtags(activeFormData.description) : 'Write your caption...'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const FacebookPost = ({ activeSocial, activeFormData, firebasePath, videoRef, handleVideoLoad, toggleModal, uploadType }) => {
  const handleImageError = (event, platform) => {
    event.target.src = `../socials/${platform}-logo.svg`;
  };

  // Format the date and time
  const formattedDate = new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
  const formattedTime = new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

  const getVisibilityIcon = () => {
    switch (activeFormData?.privacyStatus) {
      case 'public':
        return <MdPublic size={18} />;
      case 'private':
        return <MdLock size={18} />;
      case 'unlisted':
        return <MdVisibilityOff size={18} />;
      default:
        return <MdPublic size={18} />;
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="flex items-center px-4 py-2">
        <img
          src={activeSocial?.logo || ``}
          alt={activeSocial?.username}
          className="w-10 h-10 rounded-full"
          onError={(e) => handleImageError(e, activeSocial.platform)}
        />
        <div className="ml-1 flex flex-col">
          <p className="font-semibold text-md">{activeSocial.username}</p>
          <div className="flex items-center gap-2">
            <span>{getVisibilityIcon()}</span>
            <p className="text-sm text-gray-500">{`${formattedDate} at ${formattedTime}`}</p>
          </div>
        </div>
      </div>
      <div className="px-4 py-2 border-b">
        <p className="text-sm font-semibold">{activeFormData?.title || ''}</p>
        <p className="text-sm">{activeFormData?.description ? styleHashtags(activeFormData.description) : 'Write your caption...'}</p>
      </div>
      <div className="post-content max-w-[300px] sm:w-[300px] mx-auto">
        {firebasePath && (
          <>
            {getFileType(uploadType) === 'video' ? (
              <video
                ref={videoRef}
                onLoadedMetadata={handleVideoLoad}
                controls
                preload="auto"
                className={`rounded-md`}
              >
                <source src={firebasePath} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={firebasePath}
                className='cursor-pointer'
                alt="uploaded media"
                onClick={toggleModal} // Open modal when clicked
              />)}

          </>
        )}
      </div>
      <div className="flex items-center p-4 border-t w-full">
        <div className="text-gray-500"><AiFillLike color='blue' /></div>
        <div className="text-gray-500"><FaHeart color='red' /></div>
        <div className="text-gray-500 ml-2 flex-1">6K</div>
        <div className="flex items-center gap-2">
          <p className="text-sm">432 comments</p>
          <p className="text-sm">500 shares</p>
        </div>
      </div>
      <div className="flex justify-around border-t px-1 py-4">
        <div className="flex items-center gap-2">
          <div className="text-gray-500"><AiFillLike size={20} /></div>
          <p className="text-sm">Like</p>
        </div>
        <div className="flex items-center gap-2">
          <div className="text-gray-500"><FaComment size={20} /></div>
          <p className="text-sm">Comment</p>
        </div>
        <div className="flex items-center gap-2">
          <div className="text-gray-500"><FaWhatsapp size={20} /></div>
          <p className="text-sm">Send</p>
        </div>
        <div className="flex items-center gap-2">
          <div className="text-gray-500"><LuSend size={20} /></div>
          <p className="text-sm">Share</p>
        </div>


      </div>
    </div>
  );
};



const DefaultPost = ({ activeSocial, activeFormData, firebasePath, videoRef, handleVideoLoad, toggleModal, uploadType }) => {
  const handleImageError = (event) => {
    event.target.onerror = null;
    event.target.src = '';
    event.target.style.display = 'none';
    event.target.nextSibling.style.display = 'flex';
  };

  const getVisibilityIcon = () => {
    switch (activeFormData?.privacyStatus) {
      case 'public':
        return <MdPublic size={18} />;
      case 'private':
        return <MdLock size={18} />;
      case 'unlisted':
        return <MdVisibilityOff size={18} />;
      default:
        return <MdPublic size={18} />;
    }
  };

  const hasLogo = activeSocial?.logo;

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="flex items-center px-4 py-2">
        {hasLogo ? (
          <img
            src={activeSocial.logo}
            alt={activeSocial.username}
            className="w-10 h-10 rounded-full"
            onError={handleImageError}
          />
        ) : (
          <div className="w-10 h-10 rounded-full bg-purple-300 flex items-center justify-center text-white font-bold">
            D
          </div>
        )}
        <div className="ml-3 flex flex-col">
          <p className="font-semibold text-md">{activeSocial?.username || 'Draft'}</p>
          <div className="flex items-center gap-2">
            <div className="text-gray-500">{getVisibilityIcon()}</div>
            <p className="text-[11px] italic text-gray-500">Now</p>
          </div>
        </div>
      </div>
      <div className="px-4 py-2 border-b">
        <p className="text-sm font-semibold">{activeFormData?.title || ''}</p>
        <p className="text-sm">{activeFormData?.description ? styleHashtags(activeFormData.description) : 'Write your caption...'}</p>
      </div>
      <div className="post-content max-w-[300px] sm:w-[300px] mx-auto">
        {firebasePath && (
          <>
            {getFileType(uploadType) === 'video' ? (
              <video
                ref={videoRef}
                onLoadedMetadata={handleVideoLoad}
                controls
                preload="auto"
                className={`rounded-md`}
              >
                <source src={firebasePath} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={firebasePath}
                className='cursor-pointer'
                alt="uploaded media"
                onClick={toggleModal} // Open modal when clicked
              />
            )}
          </>
        )}
      </div>
      <div className="flex gap-2 p-4 border-t w-full">
        <div className="text-red-500"><FaHeart /></div>
        <div className="text-gray-500"><FaComment /></div>
        <div className="text-gray-500 flex-1"><LuSend /></div>
      </div>
    </div>
  );
};


const TiktokPost = ({ activeSocial, activeFormData, firebasePath, videoRef, handleVideoLoad, toggleModal, uploadType }) => {
  const handleImageError = (event) => {
    event.target.onerror = null;
    event.target.src = '';
    event.target.style.display = 'none';
    event.target.nextSibling.style.display = 'flex';
  };

  const getVisibilityIcon = () => {
    switch (activeFormData?.privacyStatus) {
      case 'public':
        return <MdPublic size={18} />;
      case 'private':
        return <MdLock size={18} />;
      case 'unlisted':
        return <MdVisibilityOff size={18} />;
      default:
        return <MdPublic size={18} />;
    }
  };

  const hasLogo = activeSocial?.logo;

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="flex items-center px-4 py-2">
        {hasLogo ? (
          <img
            src={activeSocial.logo}
            alt={activeSocial.username}
            className="w-10 h-10 rounded-full"
            onError={handleImageError}
          />
        ) : (
          <div className="w-10 h-10 rounded-full bg-purple-300 flex items-center justify-center text-white font-bold">
            D
          </div>
        )}
        <div className="ml-3 flex flex-col">
          <p className="font-semibold text-md">{activeSocial?.username || 'Draft'}</p>
          <div className="flex items-center gap-2">
            <div className="text-gray-500">{getVisibilityIcon()}</div>
            <p className="text-[11px] italic text-gray-500">Now</p>
          </div>
        </div>
      </div>
      <div className="px-4 py-2 border-b">
        <p className="text-sm font-semibold">{activeFormData?.title || ''}</p>
        <p className="text-sm">{activeFormData?.description ? styleHashtags(activeFormData.description) : 'Write your caption...'}</p>
      </div>
      <div className="relative max-w-[300px] !w-[90%] sm:w-[300px] mx-auto">
        {firebasePath && (
          <>
            {getFileType(uploadType) === 'video' && (
              <video
                ref={videoRef}
                onLoadedMetadata={handleVideoLoad}
                controls
                preload="auto"
                className="w-full h-auto rounded-md"
              >
                <source src={firebasePath} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}

            <img
              src="../socials/tiktok.svg"
              alt="Overlay SVG"
              className="pointer-events-none absolute top-0 left-0 w-full h-full opacity-50"
            />
          </>
        )}
      </div>

    </div>
  );
};



const TwitterPost = ({ activeSocial, activeFormData, firebasePath, videoRef, handleVideoLoad, toggleModal, uploadType }) => {
  const handleImageError = (event) => {
    event.target.onerror = null;
    event.target.src = '';
    event.target.style.display = 'none';
    event.target.nextSibling.style.display = 'flex';
  };

  const getVisibilityIcon = () => {
    switch (activeFormData?.privacyStatus) {
      case 'public':
        return <MdPublic size={18} />;
      case 'private':
        return <MdLock size={18} />;
      case 'unlisted':
        return <MdVisibilityOff size={18} />;
      default:
        return <MdPublic size={18} />;
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="flex items-center px-4 py-2 w-full">
        <div className="flex items-center p-4 w-full">
          <img
            src={activeSocial?.logo || ``}
            alt={activeSocial?.username}
            className="w-10 h-10 rounded-full"
            onError={(e) => handleImageError(e, activeSocial.platform)}
          />
          <div className="ml-3 flex gap-2 items-center w-full">
            <p className="font-semibold text-md">{activeSocial.username}</p>
            <MdVerified color='blue' />
            <GoDotFill size={10} />
            <p className="text-sm text-gray-500 flex-1">Now</p>
            {getVisibilityIcon()}
            <IoEllipsisHorizontal />
          </div>
        </div>
      </div>
      <div className="px-4 py-2 border-b">
        <p className="text-sm font-semibold">{activeFormData?.title || ''}</p>
        <p className="text-sm">{activeFormData?.description ? styleHashtags(activeFormData.description) : 'Write your caption...'}</p>
      </div>
      <div className="relative max-w-[300px] !w-[90%] sm:w-[300px] mx-auto">
        {firebasePath && (
          <>
            {getFileType(uploadType) === 'video' ? (
              <video
                ref={videoRef}
                onLoadedMetadata={handleVideoLoad}
                controls
                preload="auto"
                className={`rounded-md`}
              >
                <source src={firebasePath} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={firebasePath}
                className='cursor-pointer'
                alt="uploaded media"
                onClick={toggleModal} // Open modal when clicked
              />
            )}
          </>
        )}
      </div>
      <div className="flex justify-around border-t px-1 py-4">
        <div className="flex items-center gap-2">
          <div className="text-gray-500"><FaComment size={20} /></div>
          <p className="text-sm">3K</p>
        </div>
        <div className="flex items-center gap-2">
          <div className="text-gray-500"><BiRepost size={20} /></div>
          <p className="text-sm">4.1K</p>
        </div>
        <div className="flex items-center gap-2">
          <div className="text-gray-500"><FaHeart size={20} color='red' /></div>
          <p className="text-sm">54K</p>
        </div>
        <div className="flex items-center gap-2">
          <div className="text-gray-500"><BiSolidBarChartAlt2 size={20} /></div>
          <p className="text-sm">3M</p>
        </div>

        <div className="flex items-center gap-2">
          <div className="text-gray-500"><FaBookmark size={10} /></div>
          <FaUpload size={10} />
        </div>

      </div>
    </div>
  );
};


const styleHashtags = (text) => {
  const hashtagRegex = /#(\w+)/g;
  return text.split(hashtagRegex).map((part, index) =>
    index % 2 === 1 ? <span key={index} className="text-blue-500">#{part}</span> : part
  );
};

// Enhanced GlobalAlert component to handle multiple messages with collapsible functionality
const GlobalAlert = ({ messages }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const errorCount = messages.filter(msg => msg.color === 'red').length;
  const warningCount = messages.filter(msg => msg.color === 'yellow').length;

  const getBackgroundColor = (color) => {
    switch (color) {
      case 'red':
        return '#FEF2F2'; // Equivalent to bg-red-50
      case 'green':
        return '#ECFDF5'; // Equivalent to bg-green-50
      case 'blue':
        return '#EFF6FF'; // Equivalent to bg-blue-50
      case 'yellow':
        return '#FFFBEB'; // Equivalent to bg-yellow-50
      default:
        return '#FFFFFF'; // Default to white if color is not recognized
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="mb-4">
      <div className="flex items-center mb-2">
        {errorCount > 0 && (
          <div className="flex items-center bg-red-100 text-red-500 px-3 py-1 rounded-l-md mr-2">
            <MdError className="mr-1" />
            <span>{errorCount}</span>
          </div>
        )}
        {warningCount > 0 && (
          <div className="flex items-center bg-yellow-100 text-yellow-500 px-3 py-1 rounded-r-md">
            <MdWarning className="mr-1" />
            <span>{warningCount}</span>
          </div>
        )}
        {(errorCount > 0 || warningCount > 0) && (
          <div onClick={toggleCollapse} className="text-blue-500 border-b border-blue-500 px-2 py-1 cursor-pointer ml-2 font-semibold" style={{ userSelect: 'none' }}>
            {isCollapsed ? 'Show' : 'Hide'}
          </div>
        )}
      </div>
      {!isCollapsed && (errorCount > 0 || warningCount > 0) && (
        <div>
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`flex items-center gap-3 border border-${msg.color}-200 text-${msg.color}-600 p-4 text-[14px] rounded mb-2`}
              style={{ backgroundColor: getBackgroundColor(msg.color) }}
            >
              <msg.icon size={30} />
              <span>{msg.message}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
