import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoadingIndicator from '../../../components/Tools/LoadingIndicator';
import { useWorkspace } from '../../../context/WorkspaceContext.js';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const X = () => {
    const hasRunOnceRef = useRef(false);
    const navigate = useNavigate();
    const { idToken, activeWorkspace } = useWorkspace();
    const codeVerifier = localStorage.getItem('codeVerifier');
    const localState = localStorage.getItem('state');
    const broadcastChannel = new BroadcastChannel('socialsChannel');

    useEffect(() => {
        if (!hasRunOnceRef.current && idToken) {
            hasRunOnceRef.current = true;

            // Save code from URL when user logs in with X
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const state = urlParams.get('state');

            if (code && (state === localState)) {
                (async () => {
                    try {
                        await xGenerateAccessToken(code);
                    } catch (error) {
                        console.error('An error occurred:', error);
                    }
                })();

                async function xGenerateAccessToken(code) {
                    try {
                        const response = await axios.post(BACKEND_API_URL + '/api/x/generate_access_token', { code, state, workspaceId: activeWorkspace.id, codeVerifier }, {
                            headers: { Authorization: `Bearer ${idToken}` },
                            withCredentials: true
                        });
                        localStorage.removeItem('codeVerifier');
                        localStorage.removeItem('state');
                        broadcastChannel.postMessage('callbackLogin');
                        window.close();
                        return response.data; // Return the data for chaining
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }
    }, [navigate, idToken]);

    return (
        <div className='flex justify-center items-center w-full h-full'>
            <LoadingIndicator />
        </div>
    );
}

export default X;