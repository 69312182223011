import axios from 'axios';
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

// Function to send an email
export default async function sendEmail(subject, emailHtml, email) {
  try {
    const response = await axios.post(`${BACKEND_API_URL}/api/auth/user/email/send`, { subject, emailHtml, email });
    console.log('Email sent successfully:', response);
    return response;
  } catch (error) {
    console.error('Error sending email:', error.response ? error.response.data : error);
    throw error;
  }
}
