import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import useLocalStorage from 'use-local-storage';
import './Todo.css';
import { FaCheck, FaTrash } from "react-icons/fa";
import DropdownButton from "../Tools/DropdownButton";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useWorkspace } from '../../context/WorkspaceContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CiCalendar } from "react-icons/ci";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Todo = () => {
    const hasRunOnceRef = useRef(false);
    const [activeWorkspace] = useLocalStorage("activeWorkspace");
    const [showTodo, setShowTodo] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [currentAllItems, setCurrentAllItems] = useState([]);
    const [uncheckedCurrentItems, setUncheckedCurrentItems] = useState([]);
    const { idToken } = useWorkspace();
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    useEffect(() => {
        if (!hasRunOnceRef.current && idToken) {
            hasRunOnceRef.current = true;
            getTodos();
        }
    }, [idToken, activeWorkspace]);

    const getTodos = async () => {
        if (!idToken) return;
        try {
            const response = await axios.get(BACKEND_API_URL + '/api/auth/user/todo/all', {
                params: { workspaceId: activeWorkspace.id },
                headers: { Authorization: `Bearer ${idToken}` },
            });
            if (response.status === 200 && response.data && response.data.userTodo) {
                const list = response.data.userTodo.list;
                setShowTodo(list);
                setUncheckedCurrentItems(list.filter(item => !item.completed));
                setCurrentAllItems(list);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteTodo = async (id) => {
        if (!idToken) return;
        try {
            const response = await axios.delete(`${BACKEND_API_URL}/api/auth/user/todo/${id}`, {
                params: { workspaceId: activeWorkspace.id },
                headers: { Authorization: `Bearer ${idToken}` },
            });
            if (response.status === 200) {
                const todos = showTodo.filter(todo => todo.id !== id);
                setShowTodo(todos);
                setUncheckedCurrentItems(todos.filter(item => !item.completed));
                setCurrentAllItems(todos);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const handleTodoToggle = async (id) => {
        if (!idToken) return;
        const updatedShowTodo = [...showTodo];
        const index = updatedShowTodo.findIndex(item => item.id === id);
        if (index !== -1) {
            updatedShowTodo[index].completed = !updatedShowTodo[index].completed;
            try {
                const response = await axios.post(
                    BACKEND_API_URL + '/api/auth/user/todo/update',
                    { todoId: updatedShowTodo[index].id, completed: updatedShowTodo[index].completed, workspaceId: activeWorkspace.id },
                    { headers: { Authorization: `Bearer ${idToken}` } }
                );
                setShowTodo(response.data.data.list);
                setUncheckedCurrentItems(response.data.data.list.filter(item => !item.completed));
                setCurrentAllItems(response.data.data.list);
            } catch (error) {
                console.log(error);
            }
        } else {
            console.error(`Todo with id ${id} not found.`);
        }
    };

    const calculateProgress = () => {
        if (showTodo.length === 0) {
            return 0;
        }

        const completedCount = showTodo.filter(item => item.completed).length;
        const per = (completedCount / showTodo.length) * 100;
        return per.toFixed(0);
    };

    const getDateStyle = (date) => {
        const today = new Date();
        const diffTime = new Date(date) - today;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays < 0) {
            return 'bg-green-200'; // Past dates
        } else if (diffDays <= 3) {
            return 'bg-red-200'; // Dates within the next 3 days
        } else {
            return 'bg-yellow-200'; // Dates more than 3 days away
        }
    };

    return (
        <div className='dashboard-section'>
            <div className='flex justify-between items'>
                <h3>Tasks</h3>
                <DropdownButton label={<BsThreeDotsVertical size={20} />} options={[
                    { label: `${showAll ? 'Show Unchecked' : 'Show All'}`, onClick: () => toggleShowAll(), icon: <FaCheck className="text-blue-600 font-bold" /> },
                ]} />
            </div>

            {/* Progress bar */}
            <div className="mt-3 flex items-center">
                <div className="flex-grow h-2 bg-teal-200 rounded-full overflow-hidden">
                    <div
                        style={{ width: `${calculateProgress()}%` }}
                        className={`h-full bg-teal-500 transition-all duration-500 ease-in-out`}
                    ></div>
                </div>
                <div className="text-xs ml-2 font-semibold text-teal-600">
                    {calculateProgress()}%
                </div>
            </div>

            {uncheckedCurrentItems.length === 0 && currentAllItems.length === 0 && (
                <p className="italic text-sm text-center py-3">Create your first task</p>
            )}

            {showAll ? (
                <>
                    {/* Display all todos */}
                    <ul className="mt-2 space-y-1">
                        <div className="max-h-[150px] overflow-auto">
                            {currentAllItems.map((item, index) => (
                                <li key={index} className={`flex items-center group hover:bg-gray-100 px-2 rounded-md`}>
                                    <label className="flex-grow flex items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            checked={item.completed}
                                            onChange={() => handleTodoToggle(item.id, item.completed)}
                                            className="mr-3 custom-checkboxes"
                                        />
                                        <span className={`text-sm ${item.completed ? 'line-through' : ''} ml-1`}>{item.todo}</span>
                                        {item.date && (
                                            <span className={`text-xs text-gray-500 ml-2 date-display ${getDateStyle(item.date)} opacity-0 group-hover:opacity-100 transition-opacity duration-300`}>
                                                {new Date(item.date).getDate()}/{new Date(item.date).getMonth() + 1}
                                            </span>
                                        )}
                                    </label>
                                    <span onClick={() => handleDeleteTodo(item.id)} className="ml-3 text-red-500 opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer">
                                        <FaTrash className="text-red-500 opacity-50" />
                                    </span>
                                </li>
                            ))}
                        </div>
                        <div className="flex justify-between">
                            <TextArea getTodos={getTodos} />
                        </div>
                    </ul>
                </>
            ) : (
                <>
                    {/* Display unchecked todos */}
                    <ul className="mt-2 space-y-1">
                        <div className="max-h-[150px] overflow-auto">
                            {uncheckedCurrentItems.map((item, index) => (
                                <li key={index} className={`flex items-center group hover:bg-gray-100 px-2 rounded-md`}>
                                    <label className="flex-grow flex items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            checked={item.completed}
                                            onChange={() => handleTodoToggle(item.id, item.completed)}
                                            className="mr-3 custom-checkboxes"
                                        />
                                        <span className={`text-sm flex-1 ${item.completed ? 'line-through' : ''} ml-1`}>{item.todo}</span>
                                        {item.date && (
                                            <span className={`text-xs text-gray-500 ml-2 px-1 py-0.5 rounded-md ${getDateStyle(item.date)}`}>
                                                {new Date(item.date).getDate()}/{new Date(item.date).getMonth() + 1}
                                            </span>
                                        )}
                                    </label>
                                    <span onClick={() => handleDeleteTodo(item.id)} className="ml-3 text-red-500 opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer  hidden group-hover:block">
                                        <FaTrash className="text-red-500 opacity-70" />
                                    </span>
                                </li>
                            ))}
                            {uncheckedCurrentItems.length === 0 && (
                                <p className="italic text-sm text-center py-3">Work done, no more tasks to complete...</p>
                            )}
                        </div>
                        <div className="flex justify-between">
                            <TextArea getTodos={getTodos} />
                        </div>
                    </ul>
                </>
            )}
        </div>
    );
};

const TextArea = ({ getTodos }) => {
    const [todo, setTodo] = useState('');
    const [activeWorkspace] = useLocalStorage("activeWorkspace");
    const { idToken } = useWorkspace();
    const [selectedDate, setSelectedDate] = useState(null);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const handleChange = (e) => {
        setTodo(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!idToken) return;
        const uniqueId = Date.now() + Math.random().toString(36).substr(2, 9);
        try {
            await axios.post(BACKEND_API_URL + '/api/auth/user/todo', {
                id: uniqueId,
                todo: todo,
                workspaceId: activeWorkspace.id,
                date: selectedDate || null
            }, {
                headers: { Authorization: `Bearer ${idToken}` }
            });
            setTodo('');
            setSelectedDate(new Date());
            getTodos();
        } catch (error) {
            console.log(error);
        }
    };

    const toggleDatePicker = () => {
        setIsDatePickerOpen(!isDatePickerOpen);
    };

    return (
        <form className="flex space-x-2 w-full mt-3 relative" onSubmit={handleSubmit}>
            <input
                type="text"
                name="name"
                id="name"
                value={todo}
                onChange={handleChange}
                placeholder='Create a todo'
                required
                className="pl-2 w-full block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            <div className="border border-gray-300 rounded-md p-2 flex items-center">
                {selectedDate ? (
                    <span
                        className="text-gray-600 cursor-pointer"
                        onClick={toggleDatePicker}
                    >
                        {selectedDate.getDate()}/{selectedDate.getMonth() + 1}
                    </span>
                ) : (
                    <CiCalendar
                        onClick={toggleDatePicker}
                        className="cursor-pointer text-gray-600 hover:text-gray-800 w-6 h-6"
                    />
                )}
            </div>
            {isDatePickerOpen && (
                <div className="absolute z-10 top-10 right-0">
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => {
                            setSelectedDate(date);
                            setIsDatePickerOpen(false);
                        }}
                        minDate={new Date()}
                        onClickOutside={() => setIsDatePickerOpen(false)}
                        className="absolute z-10"
                        inline
                    />
                </div>
            )}
            <button
                type="submit"
                className="bg-indigo-600 hover:bg-indigo-500 text-white px-4 py-2 rounded-md shadow-sm focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                +
            </button>
        </form>
    );
};

export default Todo;
