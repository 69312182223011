// src/pages/Protected/Workspace/NoWorkspacePage.js
import React from 'react';
import { TeamSection } from '../Settings/Settings';
import { useWorkspace } from '../../../context/WorkspaceContext';

const NoWorkspace = () => {
    const { user } = useWorkspace();
    return (
        <div className='flex flex-col items-center justify-center h-screen !overflow-hidden w-full md:w-2/3 mx-auto'>
            <h1 className='text-2xl font-bold'>No Workspaces Found</h1>
            <p className='text-lg'>You need to create a workspace before proceeding.</p>
            {/* Possibly a form or link to create a new workspace */}
            <TeamSection userInfo={{ _id: user.uid }} />
        </div>
    );
}

export default NoWorkspace;
