import moment from 'moment';
import 'moment-timezone';


// returns 26/6 etc with timezone ....
export const formatCustomDate = (dateString, timeZone = moment.tz.guess()) => {
    const date = moment(dateString).tz(timeZone);
    //const now = moment().tz(timeZone);

    return date.format('DD/MM');
};


export const formatCustomDateTime = (dateInput, timeZone = moment.tz.guess()) => {
    let date;

    // Check if the input is a Firestore timestamp
    if (dateInput && typeof dateInput === 'object' && '_seconds' in dateInput && '_nanoseconds' in dateInput) {
        date = moment.unix(dateInput._seconds).tz(timeZone);
    } else {
        // Assume the input is a date string
        date = moment(dateInput, 'YYYY-MM-DDTHH:mm:ssZ', true).tz(timeZone);
    }

    // Check if the date is valid
    if (!date.isValid()) {
        console.error('Invalid date format:', dateInput);
        return 'Invalid Date';
    }

    // Format the date to "HH:mm DD/MM"
    return date.format('HH:mm DD/MM');
};